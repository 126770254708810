/**
 * AAA IDP Form - Photo Guidelines component
 *
 * @flow
 */
import * as React from 'react';
import Image from '../image/Image';
import { PHOTOS } from '../../data/Data';
import './PhotoGuidelines.css';

type Props = {};

class PhotoGuidelines extends React.Component<Props> {
  render() {
    return (
      <div className="photo-guidelines-container">
        <div className="photos-container">
          {PHOTOS.map((item, index) => {
            return <Image key={index} {...item} />;
          })}
        </div>
        <div className="photo-guidelines">
          <p>Photo requirements:</p>
          <ul>
            <li>
              A recent{' '}
              <a
                href="https://www.passports.gov.au/getting-passport-how-it-works/photo-requirements"
                target="_blank"
                rel="noopener noreferrer"
              >
                passport style photo
              </a>
            </li>
            <li>Hair cannot cover face</li>
            <li>Glasses are not allowed</li>
            <li>Plain white background</li>
            <li>Clear, good quality image, no watermarks</li>
          </ul>
        </div>
      </div>
    );
  }
}

export default PhotoGuidelines;
