import moment from 'moment';
import { TERMS_URL } from '../../data/Data';

export const FOOTER = {
  copyright: {
    default: `© International Driving Permit ${moment().format('YYYY')}`,
    confirmation: `© ${moment().format('YYYY')} Australian Automobile Association`
  },
  links: [
    {
      link: TERMS_URL,
      text: 'Terms of use'
    },
    {
      link: TERMS_URL,
      text: 'Privacy'
    },
    {
      link: 'https://www.aaa.asn.au/international-services/international-driving-permit/#faqs',
      text: 'FAQ'
    }
  ]
};
