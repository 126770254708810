/**
 * AAA IDP Delivery Address
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import ErrorMessage from '../error-message/ErrorMessage';
import ToggleAddressFields from '../toggle-address-fields/ToggleAddressFields';
import Delivery from '../delivery/Delivery';
import AddressFinder from '../address-finder/AddressFinder';
import AddressComponents from '../address-components/AddressComponents';
import TextInput from '../text-input/TextInput';
import { addressDeliveryFieldsToggle, licenceDeliveryAddressFullName } from '../../actions';
import { FIELDS } from '../../data/Data';
import type { Addresses, Names } from '../../types/Types';
import type { Bugsnag } from '@bugsnag/js';
import './DeliveryAddress.css';

type Props = {
  addresses: Addresses,
  bugsnagClient: Bugsnag,
  licenceDeliveryAddressFullName: (value: string) => *,
  names: Names,
  toggle: (value: boolean) => *
};

type State = {
  focus: boolean
};

const LABEL = 'Address';
const NAME = 'licenceDeliveryAddress';
const SECTION = 'licence-details';

export class DeliveryAddress extends React.Component<Props, State> {
  static defaultProps = {
    addresses: { ...FIELDS.addresses },
    names: { ...FIELDS.names }
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      focus: false
    };
  }

  componentDidMount() {
    // $FlowFixMe
    document.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount() {
    // $FlowFixMe
    document.removeEventListener('keydown', this.handleKeydown);
  }

  /**
   * handleKeydown
   */
  handleKeydown = (e: SyntheticKeyboardEvent<>) => {
    // $FlowFixMe
    const { key } = e;
    const { focus } = this.state;

    if (focus && key === 'Enter') {
      this.toggle();
    }
  };

  /**
   * Toggle the address components
   */
  toggle = () => {
    const { addresses, toggle } = this.props;
    toggle(!addresses.addressDeliveryFieldsToggle);
  };

  /**
   * toggle focus
   */
  focus = () => {
    this.setState({
      focus: !this.state.focus
    });
  };

  render() {
    const { addresses, bugsnagClient, licenceDeliveryAddressFullName, names } = this.props;
    const { addressDeliveryFieldsToggle, licenceDeliveryAddress } = addresses;
    const { error } = licenceDeliveryAddress;
    const errorMessage = `<span>There is a problem with your delivery address. ${
      addressDeliveryFieldsToggle ? '' : '<span>Please <span >enter it manually</span></span>'
    }</span>`;

    return (
      <div className="DeliveryAddress">
        <TextInput
          autoComplete="off"
          handleChange={licenceDeliveryAddressFullName}
          onBlur={licenceDeliveryAddressFullName}
          label="Delivery name (if different to licence name)"
          name="licenceDeliveryAddressFullName"
          section={SECTION}
          values={names}
        />
        <Delivery />
        <AddressFinder bugsnagClient={bugsnagClient} label={LABEL} name={NAME} section={SECTION} />
        <AddressComponents label={LABEL} name={NAME} section={SECTION} />
        <ToggleAddressFields name={NAME} />
        <div
          className="DeliveryAddress-error"
          onClick={this.toggle}
          role="button"
          tabIndex="0"
          onFocus={this.focus}
          onBlur={this.focus}
        >
          <ErrorMessage message={errorMessage} error={error} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ addresses, names }) => {
  return { addresses, names };
};

const mapDispatchToProps = dispatch => {
  return {
    licenceDeliveryAddressFullName: (value: string) => {
      dispatch(licenceDeliveryAddressFullName(value));
    },
    toggle: (value: boolean) => {
      dispatch(addressDeliveryFieldsToggle(value));
    }
  };
};

const VisibleDeliveryAddress = connect(mapStateToProps, mapDispatchToProps)(DeliveryAddress);

export default VisibleDeliveryAddress;
