/**
 * AAA IDP Form - ContactNumber component
 * @flow
 */

import * as React from 'react';
import { IMAGE_PATH_PREFIX } from '../../data/Data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  correct: boolean | null,
  title: string,
  source: string
};

class Image extends React.Component<Props> {
  static defaultProps = {
    correct: true,
    title: 'An image',
    source: 'photo-guideline-correct.png'
  };

  render() {
    const source = `${IMAGE_PATH_PREFIX}${this.props.source}`;

    let icon = null;
    switch (true) {
      case this.props.correct === null:
        icon = null;
        break;

      case this.props.correct === false:
        icon = 'xmark';
        break;

      default:
        icon = 'check';
    }

    return (
      <div className="image-container">
        <img src={source} alt={this.props.title} />
        {icon ? (
          <div className={`icon-container ${icon}`}>
            <FontAwesomeIcon icon={icon} inverse={true} />
          </div>
        ) : null}

        <div className="description">{this.props.title}</div>
      </div>
    );
  }
}

export default Image;
