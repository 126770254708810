/**
 * AAA IDP cookie reducer
 * @flow
 */

import { ACCEPT_COOKIES, DELETE } from '../../actions/types';
import type {ActionType} from '../../types/Types';

const initialState = false

export default function(state: boolean = initialState, action: ActionType) {
  switch (action.type) {
    case ACCEPT_COOKIES:
      return action.value;

    case DELETE:
      return false;

    default:
      return state;
  }
}