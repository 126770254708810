/**
 * AAA IDP wear glasses reducer
 * @flow
 */
import {DELETE, WEAR_GLASSES} from '../../actions/types';
import type {ActionType} from '../../types/Types';

type State = {
  error: boolean,
  required: boolean,
  valid: boolean | null,
  value: string,
};

export const initialState = {
  error: false,
  required: true,
  valid:  true ,
  value: 'No',
};

export default function(state: State = initialState, action: ActionType) {
  switch (action.type) {
    case DELETE :
      return initialState;

    case WEAR_GLASSES : {
      const {value} = action;
      
      return {
        ...state,
        value
      };
    }

    default:
      return state;
  }
}
