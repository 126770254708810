/**
 * AAA IDP Footer component
 * @flow
 */
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ErrorApi.css';

type Props = {
  apiError: boolean,
  page: string
};

const ErrorApi = function(props: Props) {
  const { apiError, page } = props;
  const button = page === 'payment' ? page : 'application';

  if (!apiError) return null;

  return (
    <div className="pre-footer error">
      <FontAwesomeIcon icon="triangle-exclamation" inverse={true} className="left" />
      We're sorry, there was an error with your application.
      <br />
      Please click <strong>"CANCEL {button}"</strong> and start again.
    </div>
  );
};

ErrorApi.defaultProps = {
  apiError: false,
  page: 'details'
};

export default ErrorApi;
