/**
 * AAA IDP Form - Date component
 * @flow
 */
import * as React from 'react';
import ErrorMessage from '../error-message/ErrorMessage';
import Info from '../info/Info';
import { MAX_FUTURE, MIN_FUTURE } from '../../data/Data';
import type Moment from 'moment';
import './DateInput.css';
type Props = {
  label: string,
  handleChange: (value: string) => *,
  name: string,
  maxFuture: Moment,
  onBlur: (value: string) => *,
  range: string,
  required: boolean,
  section: string,
  values: {}
};

type State = {
  maxFuture: Moment,
  minFuture: Moment
};

class DateInput extends React.Component<Props, State> {
  static defaultProps = {
    label: 'select',
    name: 'dateOfBirth',
    maxFuture: MAX_FUTURE,
    range: 'past',
    required: false,
    section: 'personal-details',
    values: {
      dateOfBirth: {
        error: false,
        required: true,
        valid: null,
        value: ''
      }
    }
  };

  constructor(props: Props) {
    super(props);

    const maxFuture = props.maxFuture ? props.maxFuture : MAX_FUTURE;

    this.state = {
      maxFuture,
      minFuture: MIN_FUTURE
    };
  }

  /**
   * Handle date change
   * @param {string} value
   */
  dateChange = (date: string = '') => {
    const { handleChange } = this.props;
    const newDate = date;
    handleChange(newDate);
    return newDate;
  };

  render() {
    const { label, name, section, values } = this.props;
    let { error, errorMessage, required, valid } = values[name];
    const date = values[name].value;
    const classes = `form-element-container date ${name}${error ? ' error' : ''}${valid ? ' valid' : ''}`;
    errorMessage = !errorMessage ? `${label} is required` : errorMessage;

    return (
      <div className={classes}>
        <label htmlFor={`${section}`}>
          {label}
          {required ? <span className="required">*</span> : ''}
        </label>
        <input type="date" id={name} onChange={e => this.dateChange(e.target.value)} value={date} />
        <ErrorMessage message={errorMessage} error={error} />
        <Info info="DD/MM/YYYY eg. 15/08/1980" />
      </div>
    );
  }
}

export default DateInput;
