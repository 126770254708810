/**
 * AAAA IDP Payment page
 * @flow
 */
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import './Footer.css';

type Props = {
  apiSuccess: null | string,
  status: null | string
};

class Footer extends React.Component<Props> {
  static defaultProps = {
    apiSuccess: 'false',
    status: ''
  };

  /**
   * Print
   */
  print = (e: SyntheticEvent<>) => {
    e.preventDefault();
    window.print();
  };

  /**
   * restart app
   */
  restart = (e: SyntheticEvent<>) => {
    e.preventDefault();
    window.location.href = window.location.origin;
  };

  render() {
    const { apiSuccess, status } = this.props;

    if (status === null) return null;

    const printButton =
      window.innerWidth >= 768 ? (
        <a href="/confirmation#print" className="button reverse print" onClick={this.print} role="button" tabIndex="0">
          <FontAwesomeIcon icon="print" />
          Save / Print
        </a>
      ) : null;

    const applyButton =
      status === 'success' && apiSuccess === 'true' ? (
        <a href="/confirmation#restart" className="button restart" onClick={this.restart} tabIndex="0" role="button">
          <FontAwesomeIcon icon="id-card" />
          Apply for another permit
        </a>
      ) : null;

    const tryAgainButton =
      status === 'fail' && apiSuccess === 'true' ? (
        <Link className="button payment" to="/payment">
          <FontAwesomeIcon icon="redo" />
          Try payment again
        </Link>
      ) : null;

    return (
      <section className="footer">
        {printButton}
        {applyButton}
        {tryAgainButton}
      </section>
    );
  }
}

export default Footer;
