/**
 * AAA IDP licence number
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import { licenceNumber } from '../../actions/index';
import TextInput from '../text-input/TextInput';
import type { Field } from  '../../types/Types';
import './LicenceNumber.css';

type Props = {
  licenceNumber: Field,
  setLicenceNumber: (value: string) => *,
};

export class LicenceNumber extends React.Component<Props> {
  static defaultProps = {
    licenceNumber: {
      value: ''
    }
  };

  render() {
    const { licenceNumber, setLicenceNumber } = this.props;

    return (
      <TextInput
        handleChange={setLicenceNumber}
        onBlur={setLicenceNumber}
        label="Licence number"
        name="licenceNumber"
        section="licence-details"
        // $FlowFixMe
        values={{licenceNumber}}
      />
    );
  }
}

const mapStateToProps = ({licenceNumber}) => {
  return {licenceNumber};
}

const mapDispatchToProps = dispatch => {
  return {
    setLicenceNumber: (value: string) => {
      dispatch(licenceNumber(value))
    }
  }
}

const VisibleLicenceNumber = connect(mapStateToProps, mapDispatchToProps)(LicenceNumber);

export default VisibleLicenceNumber;
