/**
 * AAA IDP title
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import { dateOfTravel } from '../../actions/index';
import DateInput from '../date-input/DateInput';
import moment from 'moment';
import './DateOfTravel.css';

type Props = {
  dateOfTravel: {
    value: string
  },
  setDateOfTravel: (value: string) => *
};

export class DateOfTravel extends React.Component<Props> {
  static defaultProps = {
    dateOfTravel: {
      value: ''
    }
  };

  render() {
    const { dateOfTravel, setDateOfTravel } = this.props;

    return (
      <DateInput
        handleChange={setDateOfTravel}
        label="Intended travel date"
        maxFuture={moment().add(5, 'years')}
        name="dateOfTravel"
        onBlur={setDateOfTravel}
        range="future"
        section="personal-details"
        values={{ dateOfTravel }}
      />
    );
  }
}

const mapStateToProps = ({ dateOfTravel }) => {
  return { dateOfTravel };
};

const mapDispatchToProps = dispatch => {
  return {
    setDateOfTravel: (value: string) => {
      dispatch(dateOfTravel(value));
    }
  };
};

const VisibleDateOfTravel = connect(mapStateToProps, mapDispatchToProps)(DateOfTravel);

export default VisibleDateOfTravel;
