/**
 * AAA IDP recent photo reducer
 * @flow
 */
import { DELETE, IMAGE, IMAGE_ERROR, IMAGE_ERROR_CLEAR, IMAGE_UPLOADED, VALIDATE } from '../../actions/types';
import type { Field, Images } from '../../types/Types';

type Action = {
  type: string,
  name?: string,
  value?: string,
  error?: boolean,
  message?: string,
  uploaded?: Boolean,
};

const FIELDS = [
  'recentPhoto',
  'licenceBack',
  'licenceFront'
];

export const initImages = {
  recentPhoto: {
    error: false,
    message: '',
    required: true,
    valid: null,
    value: '',
    uploaded: false,
  },
  licenceBack: {
    error: false,
    message: '',
    required: true,
    valid: null,
    value: '',
    uploaded: false,
  },
  licenceFront: {
    error: false,
    message: '',
    required: true,
    valid: null,
    value: '',
    uploaded: false,
  },
};

export default function(state: Images = initImages, action: Action) {
  switch (action.type) {
    case DELETE : {
      const deletedState = {
        recentPhoto: {
          error: false,
          message: '',
          required: true,
          valid: null,
          value: '',
          uploaded: false,
        },
        licenceBack: {
          error: false,
          message: '',
          required: true,
          valid: null,
          value: '',
          uploaded: false,
        },
        licenceFront: {
          error: false,
          message: '',
          required: true,
          valid: null,
          value: '',
          uploaded: false,
        },
      }
      return deletedState;
    }

    case IMAGE : {
      const {name, value} = action.name ? action : {name: '', value: ''};

      if (!name || FIELDS.indexOf(name) === -1) {
        return state;
      }

      const valid = state[name].required && value !== '' ? true : false;

      return {
        ...state,
        [name] :{
          ...state[name],
          valid,
          value
        }
      };
    }

    case IMAGE_ERROR : {
      const {name, error, message} = action.name ? action : {name: '', error: false, message: ''};

      if ( !name || FIELDS.indexOf(name) === -1) {
        return state;
      }

      return {
        ...state,
        [name] :{
          ...state[name],
          error,
          message,
          valid: !error,
        }
      };
    }

    case IMAGE_ERROR_CLEAR : {
      const { name } = action;

      if ( !name || FIELDS.indexOf(name) === -1) {
        return state;
      }
      
      return {
        ...state,
        [name] :{
          ...state[name],
          error: false,
          message: '',
          valid: null,
        }
      };
    }

    case IMAGE_UPLOADED: {
      const {name, uploaded} = action.name ? action : {name: '', uploaded: false};

      if ( !name || FIELDS.indexOf(name) === -1) {
        return state;
      }

      return {
        ...state,
        [name] :{
          ...state[name],
          error: !uploaded,
          uploaded
        }
      };
    }
  
    case VALIDATE: {
      const validState = { ...state };

      for (let image in state) {
        validState[image] = imageIsValid(validState[image]);
      }
      return validState;
    }

    default:
      return state;
  }
}

/**
 * function to validate images
 */
const imageIsValid = (image: Field) => {
  const { uploaded, value, required } = image;

  switch (true) {
    case(!uploaded):
      return {
        ...image,
        error: true,
        message: 'Error uploading image',
        valid: false
      };

    case(value === '' && required):
      return {
        ...image,
        error: true,
        valid: false
      };

    default:
      return {
        ...image,
        error: false,
        valid: true
      };
  }
}
