/**
 * AddressFinderSuggestion
 *
 * @flow
 */
import React from 'react';

type Props = {
  address: string,
  id: string,
  close: () => *,
  completions: Array<{
    focus: boolean,
    id: string,
    full_address: string,
    canonical_address_id: string,
  }>,
  onSelectAddress: (address: string) => *,
};

type State = {
  isLast: boolean,
};

class AddressFinderSuggestion extends React.Component<Props, State> {
  static defaultProps = {
    address: '',
    id: '',
    completions: [],
  };

  constructor(props: Props) {
    super(props);

    const {address, completions} = props;
    const isLast = completions[completions.length - 1].full_address === address ? true : false

    this.state = {
      isLast,
    };
  }

  componentDidMount() {
    // $FlowFixMe
    document.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount() {
    // $FlowFixMe
    document.removeEventListener('keydown', this.handleKeydown);
  }

  /**
   * onBlur of element
   */
  onBlur = () => {
    // close completions
    if (this.state.isLast) {
      this.props.close();
    }
  }

  /**
   * handleKeydown
   */
  handleKeydown = (e: SyntheticEvent<>) => {
    // $FlowFixMe
    const {key} = e;
    const {id, onSelectAddress} = this.props;
    const focus = this.hasFocus();

    if (key === 'Enter' && focus) {
      onSelectAddress(id);
      return true;
    }
    return false;
  }

  /**
   * hasFocus
   */
  hasFocus = () => {
    const {address, completions} = this.props;

    for (let i=0; i< completions.length; i++) {
      const comp = completions[i];
      if (comp.full_address === address && comp.focus) {
        return true;
      }
    }
    return false;
  }

  render() {
    const {address, id, onSelectAddress} = this.props;
    const focus = this.hasFocus();
    
    return (
      <div
        className={`address-suggestion${focus ? ' focus' : ''}`}
        onClick={() => onSelectAddress(id)}
        onBlur={this.onBlur}
      >
        {address}
      </div>
    );
  }
}

export default AddressFinderSuggestion;
