/**
 * Credit Card name
 * @flow
 */
import React, { Component } from 'react';
import TextInput from '../../../../components/text-input/TextInput';
import { cardHolder } from '../../../../actions/index';
import { connect } from 'react-redux';
import type { CreditCard } from '../../../../types/Types';

type Props = {
  cardHolder: (value: string) => *,
  creditCard: CreditCard,
  gateway: {
    name: string
  },
}
  

class CardHolder extends Component<Props> {
  static defaultProps = {
    cardHolder: () => null,
    creditCard: {
      cardHolder: {
        error: false,
        required: true,
        value: '',
        valid: null,
      },
    },
    gateway: {
      name: 'windcave'
    },
  };

  render() {
    const { cardHolder, creditCard, gateway } = this.props;
    const name: string = gateway.name === 'windcave' ? 'CardHolderName' : gateway.name === 'securepay' ? 'EPS_CARDHOLDER' : 'card_holder';
    const values = {
      [name]: {
        ...creditCard.card_holder
      }
    };

    return (
      <TextInput
        label="Name on credit card"
        name={name}
        handleChange={cardHolder}
        onBlur={cardHolder}
        // $$FlowFixMe
        values={values}
        placeholder="Name on credit card"
        section=""
      />
    );
  }
}

const mapStateToProps = ({ creditCard }) => {
  return { creditCard };
};

const mapDispatchToProps = dispatch => {
  return {
    cardHolder: (value: string) => {
      dispatch(cardHolder(value))
    },
  }
};

const VisibleCardHolder= connect(mapStateToProps, mapDispatchToProps)(CardHolder);

export default VisibleCardHolder;
