/**
 * AAA IDP licence number
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import { acceptTerms } from '../../actions/index';
import ErrorMessage from '../error-message/ErrorMessage';

import { SITE_CONTENT } from '../../reducers/content/Site.content';
import './AcceptTerms.css';

type Props = {
  terms: {
    error: boolean,
    required: Boolean,
    valid: Boolean,
    value: boolean
  },
  acceptTerms: (value: boolean) => *,
  errorMessage: string,
  handleChange: (value: string) => void,
  setAcceptTerms: (value: boolean) => *,
  acceptTermsContent: {
    title: string,
    label: string,
    checked: boolean
  }
};

export class AcceptTerms extends React.Component<Props> {
  static defaultProps = {
    terms: false,
    acceptTermsContent: {
      ...SITE_CONTENT.acceptTermsContent
    }
  };

  accept = (checked: boolean) => {
    this.props.acceptTerms(checked);
  };

  render() {
    const { acceptTermsContent, terms } = this.props;
    let errorMessage = terms.error ? 'Terms of the Privacy Policy and Terms of Use need to be agreed to.' : '';

    return (
      <div className="acceptTerms">
        <div className="checkbox-container">
          <input
            name="terms"
            type="checkbox"
            checked={terms.value}
            value="1"
            onChange={e => this.accept(e.target.checked)}
          />

          <label dangerouslySetInnerHTML={{ __html: acceptTermsContent.label }} />
        </div>
        <ErrorMessage message={errorMessage} error={terms.error} />
      </div>
    );
  }
}

const mapStateToProps = ({ terms }) => {
  return { terms };
};

const mapDispatchToProps = dispatch => {
  return {
    acceptTerms: (value: boolean) => {
      dispatch(acceptTerms(value));
    }
  };
};

const VisibleAcceptTerms = connect(mapStateToProps, mapDispatchToProps)(AcceptTerms);

export default VisibleAcceptTerms;
