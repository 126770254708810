/**
 * AAA IDP CTA component for home page
 * @flow
 */
import React, { type Node } from 'react';
import './Cta.css';

type Props = {
  btnTitle: string,
  content: string,
  startApp: (e: SyntheticMouseEvent<HTMLAnchorElement>,  origin: 'banner' | 'cta') => *,
  title: string
};

const Cta = (props: Props): Node => {
  const { btnTitle, content, startApp, title } = props;

  return (
    <div className="Cta">
      <div className="content">
        <h3 className="title">{title}</h3>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      <a href="#aaa-idp-start" className="button" onClick={(e) => startApp(e, 'cta')}>
        {btnTitle}
      </a>
    </div>
  );
}

Cta.defaultProps = {
  title: 'Succinct title',
  content: '<p>This is some HTML content</p>'
};

export default Cta;
