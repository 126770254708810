/**
 * AAA IDP CTA component for home page
 * @flow
 */
import * as React from 'react';
import './Contact.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
type Props = {
  title: string,
  address1: string,
  address2: string,
  phone: string,
  email: string
};
type State = {};
class Contact extends React.Component<Props, State> {
  static defaultProps = {
    title: 'Succinct title',
    address1: '<p>This is some HTML content</p>',
    address2: '<p>This is some HTML content</p>',
    phone: '<p>This is some HTML content</p>',
    email: '<p>This is some HTML content</p>'
  };
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    const { title, address1, address2, phone, email } = this.props;

    return (
      <div className="Contact">
        <div className="inner">
          <div className="content">
            <div className="title">{title}</div>
            <div className="">{address1}</div>
            <div className="">{address2}</div>
          </div>
          <div className="content">
            <div className="">
              <FontAwesomeIcon icon={faPhone} className="left" />
              <a href={'tel:' + phone}>{phone}</a>
            </div>
            <div className="">
              <FontAwesomeIcon icon={faEnvelope} className="left" />
              <a href={'mailto:' + email}>{email}</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
