/**
 * IDP Credit Card From
 * @flow
 */
import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { COSTS } from '../../../data/Data';
import CardHolder from './fields/CardHolder';
import CreditCard from './fields/CreditCard';
import CVV from './fields/CVV';
import Expiry from './fields/Expiry';
//$FlowFixMe
import type { Application, CreditCard as CC, Transaction } from '../../../types/Types';
import type { Bugsnag } from '@bugsnag/js';
import './CreditCardForm.css';

type Props = {
  bugsnagClient: Bugsnag,
  application: Application,
  creditCard: CC,
  // $FlowFixMe
  formRef: React.RefObject,
  gateway: {
    name: string,
    method: string,
    redirectUrl: string
  },
  location: {
    search: string
  },
  postage: {
    description: string,
    cost: number,
    value: string
  },
  transaction: Transaction,
  jest: boolean
};

type State = {
  apiSuccess: boolean,
  paymentError: boolean,
  spinner: boolean,
  submit: boolean
};

export class CreditCardForm extends Component<Props, State> {
  static defaultProps = {
    application: {
      club: {
        abbreviation: 'RAA'
      }
    },
    gateway: {
      name: 'windcave'
    },
    postage: {
      description: 'Express Post within Australia',
      cost: 11.4,
      value: 'express_post'
    },
    transaction: {
      merchant_id: '',
      timestamp: '',
      token: ''
    }
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      apiSuccess: false,
      fatZebraVars: {
        amount: '0.00',
        reference: '',
        currency: 'AUD'
      },
      fatZebraResult: {},
      fatZebraErrorMessage: '',
      paymentError: false,
      spinner: false,
      submit: false
    };
  }

  /**
   * Check if our user fields are valid
   */
  isValid = () => {
    const { creditCard, transaction } = this.props;
    const { gateway_url, token } = transaction;
    let fieldsValid = true;
    let gatewayValid = gateway_url && token ? true : false;

    for (let field in creditCard) {
      if (!creditCard[field].valid) {
        fieldsValid = false;
        break;
      }
    }

    return fieldsValid && gatewayValid;
  };

  /**
   * Hidden fields are gateway dependent
   */
  renderHiddenFields() {
    const { application, gateway, transaction, postage } = this.props;
    const { id, created_at } = application;
    const { name, redirectUrl } = gateway;
    const { token, merchant_id, timestamp } = transaction;
    const price = postage.cost + COSTS.permit;
    const currency = 'AUD';
    const reference = `${id}-${created_at}`;

    switch (name) {
      // https://www.windcave.com/developer-ecommerce-pxpost-pxfusion
      case 'windcave':
        return (
          <Fragment>
            <input name="amount" type="hidden" value={price} />
            <input name="currency" type="hidden" value={currency} />
            <input name="returnUrl" type="hidden" value={redirectUrl} />
            <input name="txnRef" type="hidden" value={reference} />
            <input name="txnType" type="hidden" value="Purchase" />
            <input name="SessionId" type="hidden" value={token ? token : ''} />
          </Fragment>
        );

      case 'nab-securepay':
        // https://auspost.com.au/payments/docs/securepay/resources/Direct_Post_Integration_Guide_1.7.pdf
        return (
          <Fragment>
            <input name="EPS_AMOUNT" type="hidden" value={parseFloat(price).toFixed(2)} />
            <input name="EPS_MERCHANT" type="hidden" value={merchant_id ? merchant_id : ''} />
            <input name="EPS_RESULTURL" type="hidden" value={redirectUrl} />
            <input name="EPS_REFERENCEID" type="hidden" value={reference} />
            <input name="EPS_TIMESTAMP" type="hidden" value={timestamp ? timestamp : ''} />
            <input name="EPS_TXNTYPE" type="hidden" value="0" />
            <input name="EPS_FINGERPRINT" type="hidden" value={token ? token : ''} />
            <input type="hidden" name="EPS_REDIRECT" value="TRUE" />
          </Fragment>
        );

      default:
        // FatZebra direct post doesn't need any hidden fields, as we construct the direct post URL
        return null;
    }
  }

  /**
   * submit the form
   */
  submit = (e: SyntheticMouseEvent<>) => {
    e.preventDefault();
    const { formRef } = this.props;
    const valid = this.isValid();
    const { submit } = this.state;

    if (!valid || submit || !formRef || !formRef.current) {
      return null;
    }

    this.setState(
      {
        submit: true,
        spinner: true
      },
      () => this.formAction()
    );
  };

  /**
   * Form Action
   */
  formAction = () => {
    this.props.formRef.current.submit();
  };

  /**
   * Error
   */
  error = (err: Error) => {
    const { bugsnagClient, jest } = this.props;

    if (jest) return null;

    console.warn(err);

    bugsnagClient.notify(err);
  };

  /**
   * reload the page
   */
  reset = () => {
    window.location.reload();
  };

  render() {
    const { formRef, gateway, transaction } = this.props;
    const { paymentError, spinner, submit } = this.state;
    const { gateway_url } = transaction;
    const valid = this.isValid();

    switch (true) {
      case paymentError: {
        return (
          <div className="CreditCardForm error">
            <p>
              <FontAwesomeIcon icon="triangle-exclamation" className="left" />
              There has been an error processing your payment.
            </p>
            <div className="button" role="button" tabIndex="0" onClick={this.reset}>
              Try payment again
            </div>
          </div>
        );
      }

      default:
        return (
          <form ref={formRef} className="CreditCardForm" action={gateway_url} onSubmit={this.submit}>
            <CardHolder gateway={gateway} />
            <CreditCard gateway={gateway} submit={submit} />
            <Expiry gateway={gateway} />
            <CVV gateway={gateway} />
            {this.renderHiddenFields()}
            <div className="footer actions">
              <Link className="button cancel reverse" to="/summary">
                <FontAwesomeIcon icon="xmark" />
                Cancel Payment
              </Link>
              <button className={`button process${valid ? '' : ' disabled'}`} disabled={!valid}>
                <FontAwesomeIcon icon={spinner ? 'spinner' : 'check'} />
                Process Order
              </button>
            </div>
          </form>
        );
    }
  }
}
const mapStateToProps = ({ application, creditCard, transaction, postage }) => {
  return { application, creditCard, transaction, postage };
};

const VisibleCreditCardForm = connect(mapStateToProps)(CreditCardForm);

export default VisibleCreditCardForm;
