/**
 * Button
 * @flow
 */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  action: () => *,
  className: string,
  onFocus: (name: string) => *,
  onBlur: () => *,
  name: string,
  icon: string,
  show: boolean,
  text: string
};

const Button = (props: Props) => {
  const { action, className, icon, name, onBlur, onFocus, show, text } = props;

  if (!show) return null;

  return (
    <div
      className={`button secondary ${className}`}
      onClick={action}
      tabIndex="0"
      role="button"
      onBlur={onBlur}
      onFocus={() => onFocus(name)}
    >
      <span className="icon">
        <FontAwesomeIcon icon={icon} />
      </span>
      <span className="text">{text}</span>
    </div>
  );
};

Button.defaultProps = {
  action: () => null,
  className: 'button',
  icon: 'crop-alt',
  onBlur: () => null,
  onFocus: (): null => null,
  name: 'edit',
  show: false,
  text: 'Crop'
};

export default Button;
