/**
 * Payment confirmation table
 * @flow
 */
import React, { Fragment } from 'react';
import moment from 'moment';
import './ConfirmationTable.css';

type Props = {
  createdDateTime: null | string,
  id: null | string,
  paymentAmount: null | string,
  postageDescription: null | string
};

const ConfirmationTable = (props: Props) => {
  const { createdDateTime, id, paymentAmount, postageDescription } = props;
  const date = createdDateTime ? moment(createdDateTime) : moment(new Date());

  return (
    <Fragment>
      <div className="ConfirmationTable">
        <div className="Text">
          <div className="label">Successfully paid</div>
          <div className="value">${paymentAmount}</div>
        </div>
        <div className="Text">
          <div className="label">Date</div>
          <div className="value">{date.format('D MMMM YYYY')}</div>
        </div>
        <div className="Text">
          <div className="label">Product</div>
          <div className="value">International Driving Permit</div>
        </div>
        <div className="Text">
          <div className="label">Order number</div>
          <div className="value">{id}</div>
        </div>
        <div className="Text">
          <div className="label">Shipping method</div>
          <div className="value">{postageDescription}</div>
        </div>
      </div>
    </Fragment>
  );
};

export default ConfirmationTable;
