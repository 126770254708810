/**
 * ErrorBoundary
 * @flow
 */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ErrorBoundary.css';

type Props = {
  children: *
};

type State = {
  hasError: boolean
};

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="ErrorBoundary">
          <FontAwesomeIcon icon="triangle-exclamation" />
          <div className="apologies">
            <h2>Whoops! Something went wrong.</h2>
            <p>We're terribly sorry, but an error has occurred.</p>
            <p>Try reloading the page.</p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
