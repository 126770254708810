/**
 * AAA IDP licence number
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import { autoClub, membershipNumber } from '../../actions/index';
import Select from '../select/Select';
import TextInput from '../text-input/TextInput';
import { AUTO_CLUB } from '../../data/Data';
import type { AutoClub as Club } from '../../types/Types';
import './AutoClub.css';

type Props = {
  club: Club,
  setAutoClub: (value: string) => *,
  setMembershipNumber: (value: string) => *
};

export class AutoClub extends React.Component<Props> {
  static defaultProps = {
    autoClub: {
      value: ''
    },
    membershipNumber: {
      value: ''
    }
  };

  render() {
    const { club, setAutoClub, setMembershipNumber } = this.props;
    const showMembership = club.autoClub && ['', 'NA'].indexOf(club.autoClub.value) === -1 ? true : false;
    if (club.autoClub.value === '') club.autoClub.value = 'NA';
    return (
      <div className="form-element-container AutoClub">
        <Select
          label="Auto club membership"
          options={AUTO_CLUB}
          name="autoClub"
          section="personal-details"
          handleChange={setAutoClub}
          onBlur={setAutoClub}
          // $FlowFixMe
          values={club}
        />
        {showMembership ? (
          <TextInput
            handleChange={setMembershipNumber}
            onBlur={setMembershipNumber}
            label="Membership number"
            name="membershipNumber"
            section="personal-details"
            // $FlowFixMe
            values={club}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({ club }) => {
  return { club };
};

const mapDispatchToProps = dispatch => {
  return {
    setAutoClub: (value: string) => {
      dispatch(autoClub(value));
    },
    setMembershipNumber: (value: string) => {
      dispatch(membershipNumber(value));
    }
  };
};

const VisibleAutoClub = connect(mapStateToProps, mapDispatchToProps)(AutoClub);

export default VisibleAutoClub;
