/**
 * AAA IDP title
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import { title, firstName, middleName, lastName } from '../../actions/index';
import Info from '../info/Info';
import TextInput from '../text-input/TextInput';
import type { AppState } from '../../types/Types';
import './Name.css';

type Props = {
  names: AppState,
  firstName: (value: string) => *,
  lastName: (value: string) => *,
  middleName: (value: string) => *,
  title: (value: string) => *
};

export class Name extends React.Component<Props> {
  static defaultProps = {
    names: {
      firstName: {
        value: ''
      },
      lastName: {
        value: ''
      },
      middleName: {
        value: ''
      },
      title: {
        value: ''
      }
    }
  };

  render() {
    const { names, firstName, lastName, middleName } = this.props;

    return (
      <div className="Name">
        <div className="field-set">
          <TextInput
            autoComplete="given-name"
            handleChange={firstName}
            label="First name"
            name="firstName"
            onBlur={firstName}
            section="personal-details"
            values={names}
          />
          <TextInput
            autoComplete="additional-name"
            handleChange={middleName}
            label="Middle name(s)"
            name="middleName"
            onBlur={middleName}
            section="personal-details"
            values={names}
          />
          <TextInput
            autoComplete="family-name"
            handleChange={lastName}
            label="Last name"
            name="lastName"
            onBlur={lastName}
            section="personal-details"
            values={names}
          />
        </div>
        <Info info="Please enter your name exactly as it appears on your licence." />
      </div>
    );
  }
}

const mapStateToProps = ({ names }) => {
  return { names };
};

const mapDispatchToProps = dispatch => {
  return {
    title: (value: string) => {
      dispatch(title(value));
    },
    firstName: (value: string) => {
      dispatch(firstName(value));
    },
    lastName: (value: string) => {
      dispatch(lastName(value));
    },
    middleName: (value: string) => {
      dispatch(middleName(value));
    }
  };
};

const VisibleName = connect(mapStateToProps, mapDispatchToProps)(Name);

export default VisibleName;
