/**
 * AAA IDP Home Form
 * @flow
 */
import * as React from 'react';
import ErrorMessage from '../../../components/error-message/ErrorMessage';

import Select from '../../../components/select/Select';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { application, deleteAll, locality } from '../../../actions';
import { connect } from 'react-redux';
import { API, OPTIONS, STATES, appDomain, stateForDomain } from '../../../data/Data';
import type { Application, ApiResult } from '../../../types/Types';
import type { Bugsnag } from '@bugsnag/js';
import './HomeForm.css';

type Props = {
  application: Application,
  bugsnagClient: Bugsnag,
  button: string,
  content: string,
  cookies: boolean,
  deleteAll: () => *,
  formRef: *,
  jest: boolean,
  locality: {
    club: string,
    error: boolean,
    required: boolean,
    valid: null | boolean,
    value: string
  },
  setApplication: (value: Application) => *,
  setLocality: (locality: string) => *,
  title: string
};

type State = {
  buttonFocus: boolean,
  domain: string,
  error: boolean,
  submitting: boolean,
  submitted: boolean
};

export class HomeForm extends React.Component<Props, State> {
  static defaultProps = {
    application: {
      club: {
        id: 0,
        name: '',
        abbreviation: ''
      },
      id: 0,
      submission_token: ''
    },
    button: 'Start your IDP Application',
    content: 'Please select your state to process your IDP. By selecting your state you consent to providing your personal information to the AAA and their sales agent in order to process your IDP.',

    cookies: false,
    deleteAll: () => null,
    jest: false,

    locality: {
      error: false,
      required: true,
      valid: null,
      value: ''
    },

    setApplication: () => null,
    setLocality: () => null,
    title: 'Start your application'
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      buttonFocus: false,
      domain: '',
      error: false,
      submitted: false,
      submitting: false
    };
  }

  componentDidMount() {
    const { deleteAll, setLocality } = this.props;
    const state = stateForDomain(window.location.host);

    // Clear application state
    deleteAll();

    if (state) {
      setLocality(state);
    }

    // $FlowFixMe
    document.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount() {
    // $FlowFixMe
    document.removeEventListener('keydown', this.handleKeydown);
  }

  /**
   * handleKeydown
   */
  handleKeydown = (e: SyntheticKeyboardEvent<>) => {
    // $FlowFixMe
    const { key } = e;
    const { buttonFocus } = this.state;

    if (buttonFocus && (key === ' ' || key === 'Enter')) {
      e.preventDefault();
      this.submit(e);
    }
  };

  /**
   * submit form
   */
  submit = (e: SyntheticEvent<>) => {
    e.preventDefault();
    const { locality, setLocality } = this.props;

    if (locality.valid) {
      this.setState({
        submitting: true
      });
      this.post();
      return;
    }

    // validate
    setLocality(locality.value);
  };

  /**
   * Post to API
   */
  post = () => {
    const { cookies, locality } = this.props;
    const data = {
      club_abbreviation: locality.club,
      state: locality.value,
      cookies_agreed: cookies
    };
    const options = {
      ...OPTIONS,
      body: JSON.stringify(data)
    };

    fetch(API, options)
      .then(response => {
        if (response.status >= 400) {
          const message = `HTTP status code: ${response.status}`;
          const err = new Error(message);
          response.json().then(result => console.warn(result));
          throw err;
        } else {
          return response.json();
        }
      })
      .then(results => this.success(results))
      .catch(error => this.error(error));
  };

  /**
   * Success
   */
  success = (result: ApiResult) => {
    const { formRef, setApplication } = this.props;

    if ((result.errors && result.message) || !result.data) {
      const message = result.message ? result.message : 'No data returned from API';
      this.error(new Error(message));
      return;
    }

    const { club, created_at, id, submission_token } = result.data;
    const app = {
      club,
      created_at,
      id,
      submission_token,
      referrer: window.location.href
    };

    setApplication(app);
    if (!formRef || !formRef.current || !result) {
      return null;
    }

    formRef.current.submit();
  };

  /**
   * Post form content to club URL
   */
  redirect = (result: ApiResult) => {
    const { formRef } = this.props;

    if (!formRef || !formRef.current || !result) {
      return null;
    }

    formRef.current.submit();
  };

  /**
   * Error
   */
  error = (error: Error) => {
    const { bugsnagClient, jest } = this.props;

    if (jest) return null;

    console.warn(error);

    this.setState({
      error: true,
      submitted: false,
      submitting: false
    });

    bugsnagClient.notify(error);
  };

  /**
   * buttonFocus
   */
  buttonFocus = () => {
    const { buttonFocus } = this.state;
    this.setState({
      buttonFocus: !buttonFocus
    });
  };

  render() {
    const {
      application,
      button,
      content,
      cookies,
      formRef,
      locality,
      setLocality,
      title
    } = this.props;
    const { error, submitted, submitting } = this.state;
    const { club, created_at, id, submission_token } = application;

    // If staying on same domain and submitted then redirect
    if (submitted) {
      return <Redirect to="/details" />;
    }

    let { host, port, protocol } = window.location;
    const domain = appDomain(host, locality.value);
    port = port ? `:${port}` : '';
    const action = domain ? `${protocol}//${domain}${port}/details` : null;

    // Set the default drop down option
    let select = [...STATES];
    select[0].title = 'Select your state';

    const values = {
      locality
    };

    return (
      <form className="HomeForm" ref={formRef} action={action}>
        <span id="aaa-idp-start"></span>
        <div className="HomeForm-container">
          <h3>{title}</h3>
          <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
          <div className="form-elements">
            <Select
              errorMessage="To start your application, please choose a state."
              options={select}
              section="home-form"
              name="locality"
              label="Select your state"
              // $FlowFixMe
              values={values}
              handleChange={setLocality}
              onBlur={setLocality}
            />
            <button id="what-you-need" onClick={this.submit} onFocus={this.buttonFocus} onBlur={this.buttonFocus}>
              {submitting ? <FontAwesomeIcon icon="spinner" spin={true} /> : button}
            </button>
          </div>

          <input type="hidden" name="cookies" value={cookies} />
          <input type="hidden" name="id" value={id} />
          <input type="hidden" name="token" value={submission_token} />
          <input type="hidden" name="club_id" value={club.id} />
          <input type="hidden" name="club_name" value={club.name} />
          <input type="hidden" name="club_abbreviation" value={club.abbreviation} />
          <input type="hidden" name="created_at" value={created_at} />
          <input type="hidden" name="referrer" value={window.location.href} />

          <ErrorMessage message="There has been an error starting your application" error={error} />
        </div>
      </form>
    );
  }
}

const mapStateToProps = ({ application, cookies, locality }) => {
  return { application, cookies, locality };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteAll: () => {
      dispatch(deleteAll());
    },
    setApplication: (value: Application) => {
      dispatch(application(value));
    },

    setLocality: (value: string) => {
      dispatch(locality(value));
    }
  };
};

const VisibleHomeForm = connect(mapStateToProps, mapDispatchToProps)(HomeForm);

export default VisibleHomeForm;
