export const ACCEPT_COOKIES = 'ACCEPT_COOKIES';
export const ACCEPT_TERMS = 'ACCEPT_TERMS';
export const ACCEPT_DL_TERMS = 'ACCEPT_DL_TERMS';
export const ADDRESS_FIELDS_TOGGLE = 'ADDRESS_FIELDS_TOGGLE';
export const ADDRESS_USE_SAME_TOGGLE = 'ADDRESS_USE_SAME_TOGGLE';
export const ADDRESS_FINDER = 'ADDRESS_FINDER';
export const APPLICATION_START = 'APPLICATION_START';
export const AUTO_CLUB = 'AUTO_CLUB';
export const CLEAR_DELIVERY_ADDRESS = 'CLEAR_DELIVERY_ADDRESS';
export const CLEAR_LICENCE_ADDRESS = 'CLEAR_LICENCE_ADDRESS';
export const CLONE_LICENCE_ADDRESS = 'CLONE_LICENCE_ADDRESS';
export const CARD_HOLDER = 'CARD_HOLDER';
export const CONTENT = 'CONTENT';
export const PHONE_COUNTRY_CODE = 'COUNTRY_CODE';
export const CREDIT_CARD = 'CREDIT_CARD';
export const CVV = 'CVV';
export const DATE_OF_BIRTH = 'DATE_OF_BIRTH';
export const DATE_OF_TRAVEL = 'DATE_OF_TRAVEL';
export const DELETE = 'DELETE';
export const DELIVERY_ADDRESS_COUNTRY = 'DELIVERY_ADDRESS_COUNTRY';
export const DELIVERY_ADDRESS_COUNTRY_ERROR = 'DELIVERY_ADDRESS_COUNTRY_ERROR';
export const DELIVERY_ADDRESS_FIELDS_TOGGLE = 'DELIVERY_ADDRESS_FIELDS_TOGGLE';
export const DELIVERY_ADDRESS_LOCALITY = 'DELIVERY_ADDRESS_LOCALITY';
export const DELIVERY_ADDRESS_POSTCODE = 'DELIVERY_ADDRESS_POSTCODE';
export const DELIVERY_ADDRESS_STREET = 'DELIVERY_ADDRESS_STREET';
export const DELIVERY_ADDRESS_STATE = 'DELIVERY_ADDRESS_STATE';
export const DELIVERY_INT = 'DELIVERY_INT';
export const EMAIL = 'EMAIL';
export const EXPIRY_MONTH = 'EXPIRY_MONTH';
export const EXPIRY_YEAR = 'EXPIRY_YEAR';
export const FIRST_NAME = 'FIRST_NAME';
export const GATEWAY = 'GATEWAY';
export const IMAGE = 'IMAGE';
export const IMAGE_ERROR = 'IMAGE_ERROR';
export const IMAGE_ERROR_CLEAR = 'IMAGE_ERROR_CLEAR';
export const IMAGE_UPLOADED = 'IMAGE_UPLOADED';
export const LICENCE_ADDRESS_STREET = 'LICENCE_ADDRESS_STREET';
export const LICENCE_ADDRESS_LOCALITY = 'LICENCE_ADDRESS_LOCALITY';
export const LICENCE_ADDRESS_POSTCODE = 'LICENCE_ADDRESS_POSTCODE';
export const LICENCE_ADDRESS_STATE = 'LICENCE_ADDRESS_STATE';
export const LAST_NAME = 'LAST_NAME';
export const PHONE_NUMBER = 'PHONE_NUMBER';
export const LICENCE_ADDRESS = 'LICENCE_ADDRESS';
export const LICENCE_CLASS = 'LICENCE_CLASS';
export const DELIVERY_ADDRESS = 'DELIVERY_ADDRESS';
export const DELIVERY_ADDRESS_ADDRESS_FULL_NAME = 'DELIVERY_ADDRESS_ADDRESS_FULL_NAME';
export const LICENCE_EXPIRY = 'LICENCE_EXPIRY';
export const LICENCE_NUMBER = 'LICENCE_NUMBER';
export const LOCALITY = 'LOCALITY';
export const MIDDLE_NAME = 'MIDDLE_NAME';
export const MEMBERSHIP_NUMBER = 'MEMBERSHIP_NUMBER';
export const PLACE_OF_BIRTH = 'PLACE_OF_BIRTH';
export const POSTAGE = 'POSTAGE';
export const SET_I18N = 'SET_I18N';
export const STATE_OF_ISSUE = 'STATE_OF_ISSUE';
export const TITLE = 'TITLE';
export const TRANSACTION = 'TRANSACTION';
export const VALIDATE = 'VALIDATE';
export const VALIDATE_ADDRESS = 'VALIDATE_ADDRESS';
export const WEAR_GLASSES = 'WEAR_GLASSES';
