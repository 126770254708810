/**
 * Postage reducer
 * @flow
 */
import {DELETE, DELIVERY_ADDRESS, POSTAGE} from '../../actions/types';
import {POSTAGE as RATES} from '../../data/Data';
import type {Shipping} from '../../types/Types';

type Action = {
  type: string,
  value: Shipping
};

type State = {
  description: string,
  cost: number,
  value: string,
};

export const initialState = RATES[0];

export default function(state: State = initialState, action: Action) {
  switch (action.type) {
    case DELETE:
      return initialState;

    case DELIVERY_ADDRESS: {
      const { value } = action;
      if (value === 'International') {

        return {
          ...RATES[1],
        };
      }

      return {
        ...RATES[0],
      };
    }

    case POSTAGE: {
      return {...action.value};
    }

    default:
      return state;
  }
}
