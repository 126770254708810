/**
 * AAA -IDP Cookies compliance
 * @flow
 */
import * as React from 'react';
import { TERMS_URL } from '../../data/Data';
import { acceptCookies } from '../../actions';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Cookies.css';

type Props = {
  cookies: boolean,
  acceptCookies: (value: boolean) => *
};

type State = {
  focus: boolean
};

export class Cookies extends React.Component<Props, State> {
  static defaultProps = {
    cookies: false
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      focus: false
    };
  }

  componentDidMount() {
    // $FlowFixMe
    document.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount() {
    // $FlowFixMe
    document.removeEventListener('keydown', this.handleKeydown);
  }

  /**
   * handleKeydown
   */
  handleKeydown = (e: SyntheticKeyboardEvent<>) => {
    // $FlowFixMe
    const { key } = e;
    const { focus } = this.state;

    if (focus && key === 'Enter') {
      e.preventDefault();
      this.done();
    }
  };

  /**
   * focus
   */
  focus = () => {
    let { focus } = this.state;
    this.setState({
      focus: !focus
    });
  };

  /**
   * Done
   */
  done = () => {
    this.props.acceptCookies(true);
  };

  render() {
    const { cookies } = this.props;

    if (cookies === true) {
      return null;
    }

    return (
      <div className="AAA-cookies">
        <div className="inner">
          <p>
            We use cookies on this site. The cookies used may retain personal information or personal identifying
            information without an IDP application being submitted. Please read{' '}
            <a href={TERMS_URL} target="_blank" rel="noopener noreferrer">
              Privacy Policy and Terms and Conditions
            </a>{' '}
            documents.
          </p>
          <div className="icon" onClick={this.done} tabIndex="0" role="button" onFocus={this.focus} onBlur={this.focus}>
            <FontAwesomeIcon icon="xmark" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ cookies }) => {
  return { cookies };
};

const mapDispatchToProps = dispatch => {
  return {
    acceptCookies: (value: boolean) => {
      dispatch(acceptCookies(value));
    }
  };
};

const VisibleCookies = connect(mapStateToProps, mapDispatchToProps)(Cookies);

export default VisibleCookies;
