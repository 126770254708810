/**
 * Cost table 
 * @flow
 */
import React from 'react';
import {COSTS} from '../../../data/Data';
import {connect} from 'react-redux';
import './CostTable.css';

type Props = {
  postage: {
    name: string,
    description: string,
    cost: number,
  },
};

export class CostTable extends React.Component<Props> {
  static defaultProps = {
    postage: {
      name: 'express_au',
      description: 'Express Post within Australia',
      cost: 11.40
    },
  };

  render() {
    const {postage} = this.props;

    return (
      <div className="cost-table">
        <div className="title">
          Total Cost
        </div>
        <div className="row">
          <span className="description">
            International Driving Permit
          </span>
          <span className="cost">
            <span className="currency">AUD</span>
            <span className="value">${COSTS.permit}.00</span>
          </span>
        </div>
        <div className="row">
          <span className="description">
            {postage.description}
          </span>
          <span className="cost">
            <span className="currency">AUD</span>
            <span className="value">${postage.cost.toFixed(2)}</span>
          </span>
        </div>
        <div className="row total">
          <span className="description">
            Total
          </span>
          <span className="cost">
            <span className="currency">AUD</span>
            <span className="value">${(COSTS.permit + postage.cost).toFixed(2)}</span>
          </span>
        </div>
        <p>Note: International Driving Permits are GST free</p>
      </div>
    );
  }
}

const mapStateToProps = ({postage}) => {
  return {postage};
}

const VisibleCostTable = connect(mapStateToProps)(CostTable);

export default VisibleCostTable;
