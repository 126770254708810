/**
 * AAA IDP Form - License Details
 * @flow
 */
import * as React from 'react';
import DeliveryAddress from '../../../components/delivery-address/DeliveryAddress';
import LicenceAddress from '../../../components/licence-address/LicenceAddress';
import LicenceClass from '../../../components/licence-class/LicenceClass';
import LicenceExpiry from '../../../components/licence-expiry/LicenceExpiry';
import LicenceNumber from '../../../components/licence-number/LicenceNumber';
import LicenceScans from '../../../components/licence-scans/LicenceScans';
import StateOfIssue from '../../../components/state-of-issue/StateOfIssue';
import PhotoUpload from '../../../components/photo-upload/PhotoUpload';
import WearGlasses from '../../../components/wear-glasses/WearGlasses';
import AcceptTerms from '../../../components/accept-terms/AcceptTerms';
import AcceptDigitalLicence from '../../../components/accept-digital-licence/AcceptDigitalLicence';
import Info from '../../../components/info/Info';
import { withRouter } from 'react-router-dom';
import type { Bugsnag } from '@bugsnag/js';
import './LicenceDetails.css';

const SECTION = 'licence-details';

type Props = {
  bugsnagClient: Bugsnag,
  location: {
    pathname: string,
    search: string,
    hash: string
  },
  // $FlowFixMe
  refProp: React.refObject
};

export class LicenceDetails extends React.Component<Props> {
  // $FlowFixMe
  ref: React.RefObject;

  static defaultProps = {
    location: {
      pathname: '',
      search: '',
      hash: ''
    }
  };

  constructor(props: Props) {
    super(props);

    this.ref = React.createRef();
  }

  componentDidMount() {
    const { location } = this.props;

    if (location.hash === '#licence-details' && this.ref && this.ref.current) {
      setTimeout(() => {
        window.scrollTo({
          top: this.ref.current.offsetTop - 100,
          behavior: 'smooth'
        });
      }, 500);
    }
  }

  render() {
    const { bugsnagClient } = this.props;

    return (
      <section id={SECTION} ref={this.ref}>
        <h2>Licence details</h2>
        <div className="preamble">
          <LicenceScans />
        </div>
        <div className="photo-uploads">
          <PhotoUpload
            bugsnagClient={bugsnagClient}
            name={'licenceFront'}
            chooserTextDefault={'Upload front of licence'}
            disableAspectRatio={true}
          />
          <PhotoUpload
            bugsnagClient={bugsnagClient}
            name={'licenceBack'}
            chooserTextDefault={'Upload back of licence'}
            disableAspectRatio={true}
          />
          <div className="spacer"></div>
          <Info
            info=" Check that your photo clearly shows all details, including the
            number on the back and front of licence."
            icon="id-card"
          />
        </div>
        <LicenceNumber />
        <LicenceClass />
        <WearGlasses />
        <LicenceExpiry />
        <StateOfIssue />
        <LicenceAddress bugsnagClient={bugsnagClient} />
        <h2>Delivery and postage</h2>
        <Info info="Once your application is reviewed and approved, your permit will be dispatched within 3 business days in metropolitan areas. It may take longer for public holidays and regional delivery subject to Australia Post delivery times. You will receive a confirmation email with tracking details." />
        <DeliveryAddress bugsnagClient={bugsnagClient} />
        <AcceptTerms />
        <AcceptDigitalLicence />
      </section>
    );
  }
}

export default withRouter(LicenceDetails);
