/**
 * AAA IDP state of issue
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import { stateOfIssue } from '../../actions/index';
import Select from '../select/Select';
import {STATES} from '../../data/Data';
import './StateOfIssue.css';

type Props = {
  setStateOfIssue: (value: string) => *,
  stateOfIssue: {
    value: string
  },
};

export class StateOfIssue extends React.Component<Props> {
  static defaultProps = {
    stateOfIssue: {
      value: ''
    },
  };

  render() {
    const {stateOfIssue, setStateOfIssue} = this.props;

    return (
      <Select
        handleChange={setStateOfIssue}
        label="State of issue"
        options={STATES}
        onBlur={setStateOfIssue}
        name="stateOfIssue"
        section="licence-details"
        // $FlowFixMe
        values={{stateOfIssue}}
      />
    );
  }
}

const mapStateToProps = ({stateOfIssue}) => {
  return {stateOfIssue};
}

const mapDispatchToProps = dispatch => {
  return {
    setStateOfIssue: (value: string) => {
      dispatch(stateOfIssue(value))
    },
  }
}

const VisibleStateOfIssue = connect(mapStateToProps, mapDispatchToProps)(StateOfIssue);

export default VisibleStateOfIssue;
