/**
 * AAA IDP Banner
 * @flow
 */
import * as React from 'react';
import './Banner.css';

type Props = {
  content: string,
  startApp: (e: SyntheticMouseEvent<HTMLAnchorElement>,  origin?: 'banner' | 'cta') => *,
  title: string
};

const Banner = (props: Props) => {
  const { content, startApp, title } = props;

  return (
    <div className="Banner">
      <div className="content">
        <div className="content-inner">
          <h1>{title}</h1>
          <p>{content}</p>
          <div className="banner-cta">
            <a className="button" role="button" href="#aaa-idp-start" onClick={startApp}>
              Start application
            </a>
            <a
              className="button reverse"
              role="button"
              href="https://www.aaa.asn.au/international-motoring/international-driving-permits/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Find out more
            </a>
          </div>
        </div>
      </div>
      <div className="image"></div>
    </div>
  );
};

Banner.defaultProps = {
  title: 'International Driving Permit',
  content: 'Your passport to driving overseas'
};

export default Banner;
