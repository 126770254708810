/**
 * AAA IDP state of issue reducer
 * @flow
 */
import {DELETE, STATE_OF_ISSUE, VALIDATE} from '../../actions/types';
import type {ActionType} from '../../types/Types';

type State = {
  error: boolean,
  required: boolean,
  valid: boolean | null,
  value: string,
};

export const initialState = {
  error: false,
  required: true,
  valid:  null,
  value: '',
};

export default function(state: State = initialState, action: ActionType) {
  switch (action.type) {
    case DELETE :
      return initialState;
      
    case STATE_OF_ISSUE : {
      const {required} = state;
      const {value} = action;
      const valid = value === '' && required ? false : true;
      
      return {
        ...state,
        error: !valid,
        valid,
        value
      };
    }

    case VALIDATE : {
      const { required, value } = state;
      const valid = value === '' && required ? false : true;
      
      return {
        ...state,
        error: !valid,
        valid,
      };
    }

    default:
      return state;
  }
}