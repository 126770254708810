/**
 * AAA IDP Boc component for home page
 * @flow
 */
import * as React from 'react';
import './Need.css';
import { PHOTOS } from '../../data/Data';
import Image from '../image/Image';
type Props = {
  content: Array<Object>,
  passportPhoto: Object,
  licencePhoto: Object
};

class Need extends React.Component<Props> {
  static defaultProps = { content: [] };

  render() {
    const { content, passportPhoto, licencePhoto } = this.props;

    return (
      <div className="Need">
        <div className="part1">
          {content.map((item, index) => {
            return (
              <div key={index} className="box">
                <div className="image">
                  <img src={`/images/${item.icon}`} alt="" />
                </div>
                <h4 className="title">{item.title}</h4>
                <div className="content" dangerouslySetInnerHTML={{ __html: item.content }} />
              </div>
            );
          })}
        </div>
        <div className="part2">
          <div className="image">
            {PHOTOS.map((item, index) => {
              return <Image key={index} {...item} />;
            })}
          </div>
          <div className="content">
            <h3 className="title">{passportPhoto.title}</h3>
            <div className="copy" dangerouslySetInnerHTML={{ __html: passportPhoto.content }}></div>
          </div>
        </div>
        <div className="part3">
          <div className="image">
            <img src={`/images/${licencePhoto.icon}`} alt="" />
          </div>
          <div className="content">
            <h3 className="title">{licencePhoto.title}</h3>
            <div className="copy" dangerouslySetInnerHTML={{ __html: licencePhoto.content }}></div>
          </div>
        </div>
      </div>
    );
  }
}

export default Need;
