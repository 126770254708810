/**
 * AAA IDP FAQ component for home page
 * @flow
 */
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import './Faq.css';

type Props = {
  title: string,
  content: string
};
type State = {
  show: boolean
};
class Faq extends React.Component<Props, State> {
  static defaultProps = {
    number: null,
    title: 'Succinct title',
    content: '<p>This is some HTML content</p>'
  };
  constructor(props: Props) {
    super(props);

    this.state = {
      show: false
    };
  }
  toggle = () => {
    this.setState({
      show: !this.state.show
    });
  };

  render() {
    const { title, content } = this.props;

    return (
      <div className="Faq">
        <div className="toggle" onClick={this.toggle}>
          {!this.state.show && <FontAwesomeIcon icon={faChevronDown} />}
          {this.state.show && <FontAwesomeIcon icon={faChevronUp} />}
        </div>
        <div className="content">
          <div className="line question" onClick={this.toggle}>
            <div className="prefix">Q</div>
            <div className="title md">{title}</div>
          </div>
          {this.state.show && (
            <div className="line answer">
              <div className="prefix">A</div>
              <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Faq;
