/**
 * AAA IDP licence number
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import { email } from '../../actions/index';
import TextInput from '../text-input/TextInput';
import { FIELDS } from '../../data/Data';
import type { Field } from '../../types/Types';
import './Email.css';

type Props = {
  email: Field,
  setEmail: (value: string) => *,
};

export class Email extends React.Component<Props> {
  static defaultProps = {
      email: {
        ...FIELDS.email
      }
    };

  render() {
    const { email, setEmail } = this.props;

    return (
      <TextInput
        autoComplete="email"
        handleChange={setEmail}
        onBlur={setEmail}
        label="Email"
        name="email"
        section="personal-details"
        // $FlowFixMe
        values={{email}}
        type="email"
        inputMode="email"
      />
    );
  }
}

const mapStateToProps = ({ email }) => {
  return { email };
};

const mapDispatchToProps = dispatch => {
  return {
    setEmail: (value: string) => {
      dispatch(email(value))
    }
  }
};

const VisibleEmail = connect(mapStateToProps, mapDispatchToProps)(Email);

export default VisibleEmail;
