/**
 * AAA IDP Header component
 *
 * Also manages GTM and <head> per page
 * @flow
 */
import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clubDomain, CLUBS, IS_LOCAL, IS_DEV, IS_STAGE, IS_PROD } from '../../data/Data';
import packageJson from '../../../package.json';
import type { Application, Transaction } from '../../types/Types';
import './Header.css';

type Props = {
  addresses: {
    licenceAddressPostcode: {
      value: string
    }
  },
  application: Application,
  club: {
    autoClub: {
      value: string
    },
    membershipNumber: {
      value: string
    }
  },
  history: {
    listen: () => *
  },
  jest: boolean,
  location: {
    pathname: string
  },
  transaction: Transaction
};
type State = {
  reloads: number
};

export class Header extends React.Component<Props, State> {
  timeout: *;

  static defaultProps = {
    addresses: {
      licenceAddressPostcode: ''
    },
    application: {
      id: ''
    },
    club: {
      autoClub: {
        value: ''
      },
      membershipNumber: {
        value: ''
      }
    },
    jest: false,
    location: {
      pathname: '/'
    }
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      reloads: 0
    };

    // Set up digitalData for RACV & RACQ
    this.digitalData(props);
  }

  componentDidMount() {
    // Delay the firing of the analytics event so that window.digitalData is loaded
    this.timeout = setTimeout(() => this.shouldPushAnalytics(this.props), 1000);
  }

  componentDidUpdate(prevProps: Props) {
    this.shouldPushAnalytics(prevProps);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  /**
   * digitalData for Adobe analytics
   */
  digitalData = (props: Props) => {
    const { addresses, application, club, location } = props;
    const { licenceAddressPostcode } = addresses;
    const { id } = application;
    const { autoClub, membershipNumber } = club;
    const { hostname } = window.location;
    const clubName = clubDomain(hostname);
    const isRacv = clubName === CLUBS.VIC ? true : false;
   
    if (!isRacv) {
      return null;
    }
  
    const number = autoClub.value === CLUBS.VIC ? membershipNumber.value.replace(/^0+/, '') : ''; // remove leading zeros
    const { pathname } = location;
    const path = pathname.substr(1);
    const pageName = !path ? 'Home' : `${path.substr(0, 1).toUpperCase()}${path.substr(1)}`;
    let environment = '';
    let formInfo = {
      formName: 'IDP Application Form',
      formType: 'application',
      referenceID: id
    };

    switch (pathname) {
      case '/details':
        formInfo = {
          ...formInfo,
          stage: 'start',
          stepName: pageName,
          stepNumber: 1
        };
        break;

      case '/summary':
        formInfo = {
          ...formInfo,
          stage: 'in-progress',
          stepName: pageName,
          stepNumber: 2
        };
        break;

      case '/payment':
        formInfo = {
          ...formInfo,
          stage: 'in-progress',
          stepName: pageName,
          stepNumber: 3
        };
        break;

      case '/confirmation':
        formInfo = {
          ...formInfo,
          stage: 'complete',
          stepName: pageName,
          stepNumber: 4
        };
        break;

      default:
      // no op
    }

    const page = {
      pageInfo: {
        abort: false,
        environment,
        pageName: '',
        pageType: 'HTML',
        platform: 'React',
        version: packageJson.version
      }
    };
    const product = {
      productInfo: {
        productName: 'International Driving Licence Permit'
      }
    };
    const user = {
      userInfo: {
        authState: 'logged out',
        salesforceID: number,
        userID: number,
        userType: '',
        postcode: licenceAddressPostcode.value
      }
    };

    let event = window.digitalData && window.digitalData.event
      ? window.digitalData.event
      : [];
    
    window.digitalData = {
      ...window.digitalData,
      event,
      page,
      form: { formInfo },
      product,
      user
    };
  }

  /**
   * Determine if we should push a form-navigate event into RACV analytics
   */
  shouldPushAnalytics = (prevProps: Props) => {
    const { jest, location } = prevProps;
    const { hostname } = window.location;
    const { pathname } = location;
    const club: string = clubDomain(hostname);
    const isRacv: boolean = club === CLUBS.VIC ? true : false;
    const props: Array<string> = ['addresses', 'club', 'transaction'];

    if (!isRacv || jest || pathname === '/' || pathname === '/completed') {
      return;
    }

    for (let name of props) {
      for (let key in prevProps[name]) {
        if (prevProps[name][key] !== this.props[name][key]) {
          return;
        }
      }
    }

    this.digitalData(this.props);
    this.analytics();
  }

  /**
   * trigger analytics
   */
  analytics = () => {
    window.digitalData.event.push({ eventAction: 'form-navigate' });
  }

  render() {
    const { location } = this.props;
    const { hostname } = window.location;
    const { pathname } = location;
    const path = pathname.substr(1);
    const isPaymentPage = ['payment', 'completed', 'confirmation'].indexOf(path) > -1 ? true : false;
    const clubName = clubDomain(hostname);
    const logo = isPaymentPage ? clubName.toLowerCase() : 'aaa';
    const isRacq = clubName === CLUBS.QLD ? true : false;
    const isRacv = clubName === CLUBS.VIC ? true : false;
    const image = `logo-${logo}.${clubName === 'AANT' && isPaymentPage ? 'svg' : 'png'}`;
    let scriptSrc = '';

    switch (true) {
      case IS_PROD && isRacv:
        scriptSrc = '//assets.adobedtm.com/f697cdaabd9a/419267f88596/launch-920d6f825068.min.js';
        break;

      case IS_STAGE.test(hostname) && isRacv:
        scriptSrc = '//assets.adobedtm.com/f697cdaabd9a/419267f88596/launch-f8b85ec83f47-staging.min.js';
        break;
      
      case IS_PROD && isRacq:
        scriptSrc = '//assets.adobedtm.com/87195b87a899/58ad081c5f16/launch-0ae10b2abe10.min.js';
        break;
  
      case IS_STAGE.test(hostname) && isRacq:
        scriptSrc = '//assets.adobedtm.com/87195b87a899/58ad081c5f16/launch-ad827dabb844-development.min.js';
        break;

      case IS_DEV.test(hostname) && isRacv:
      case IS_LOCAL.test(hostname) && isRacv:
        scriptSrc = '//assets.adobedtm.com/f697cdaabd9a/419267f88596/launch-34b0064c9714-development.min.js';
        break;

      default:
      // no op
    }

    return (
      <header className={`header ${clubName.toLowerCase()} ${path}`}>
        <Helmet>
          <title>International Driving Permit (IDP)</title>
          <meta name="description" content="An International Driving Permit (IDP) is a translation of your domestic Australian drivers licence photo card in nine (9) languages that assists a user to drive in a foreign country. The AAA is the only authorised distributor of IDPs in Australia for Australian drivers licence holders. Permits are recognised in 98% of countries around the world and driving abroad without a permit could be illegal." />
          {scriptSrc ? <script src={scriptSrc} /> : null}
        </Helmet>
        <img src={`/images/${image}`} alt={`${clubName} logo`} />
      </header>
    );
  }
}

const mapStateToProps = ({ addresses, application, club, transaction }) => {
  return { addresses, application, club, transaction };
};

const VisibleHeader = connect(mapStateToProps)(Header);

export default withRouter(VisibleHeader);
