/**
 * AAA IDP email reducer
 * @flow
 */
/* eslint no-control-regex: 0 */
import { DELETE, EMAIL, VALIDATE } from '../../actions/types';
import { FIELD_LENGTH } from '../../data/Data';
import type {ActionType} from '../../types/Types';
const EMAIL_REGEX = new RegExp(/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i);

type State = {
  error: boolean,
  required: boolean,
  valid: boolean | null,
  value: string,
};

const initialState = {
  error: false,
  required: true,
  valid:  null ,
  value: '',
};

export default function(state: State = initialState, action: ActionType) {
  switch (action.type) {
    case DELETE:
      return initialState;

    case EMAIL: {
      let {required} = state; 
      const { value } = action;
      const valid = EMAIL_REGEX.test(value) && value.length <= FIELD_LENGTH ? true : false;

      return {
        error: !valid,
        required,
        valid,
        value
      };
    }

    case VALIDATE: {
      const valid = EMAIL_REGEX.test(state.value) ? true : false;
      return {
        ...state,
        error: !valid,
        valid,
      };
    }

    default:
      return state;
  }
}
