/**
 * AAA IDP redux actions
 * @flow
 */
import {
  ACCEPT_COOKIES,
  ACCEPT_TERMS,
  ACCEPT_DL_TERMS,
  APPLICATION_START,
  ADDRESS_FIELDS_TOGGLE,
  ADDRESS_USE_SAME_TOGGLE,
  CARD_HOLDER,
  CONTENT,
  CREDIT_CARD,
  CVV,
  DELIVERY_ADDRESS,
  DELIVERY_ADDRESS_ADDRESS_FULL_NAME,
  DELIVERY_ADDRESS_FIELDS_TOGGLE,
  DELIVERY_ADDRESS_COUNTRY,
  DELIVERY_ADDRESS_COUNTRY_ERROR,
  DELIVERY_ADDRESS_LOCALITY,
  DELIVERY_ADDRESS_POSTCODE,
  DELIVERY_ADDRESS_STATE,
  DELIVERY_ADDRESS_STREET,
  DELIVERY_INT,
  EXPIRY_MONTH,
  EXPIRY_YEAR,
  AUTO_CLUB,
  CLEAR_DELIVERY_ADDRESS,
  CLEAR_LICENCE_ADDRESS,
  CLONE_LICENCE_ADDRESS,
  PHONE_COUNTRY_CODE,
  DELETE,
  DATE_OF_BIRTH,
  DATE_OF_TRAVEL,
  EMAIL,
  FIRST_NAME,
  GATEWAY,
  ADDRESS_FINDER,
  IMAGE,
  IMAGE_ERROR,
  IMAGE_ERROR_CLEAR,
  IMAGE_UPLOADED,
  PHONE_NUMBER,
  LAST_NAME,
  LICENCE_ADDRESS,
  LICENCE_ADDRESS_LOCALITY,
  LICENCE_ADDRESS_POSTCODE,
  LICENCE_ADDRESS_STATE,
  LICENCE_ADDRESS_STREET,
  LICENCE_CLASS,
  LICENCE_EXPIRY,
  LICENCE_NUMBER,
  LOCALITY,
  MEMBERSHIP_NUMBER,
  MIDDLE_NAME,
  PLACE_OF_BIRTH,
  POSTAGE,
  SET_I18N,
  STATE_OF_ISSUE,
  TITLE,
  TRANSACTION,
  VALIDATE,
  VALIDATE_ADDRESS,
  WEAR_GLASSES
} from '../actions/types';
import type { Application, AddressData, AddressFinderCanonical, Content, Shipping, Transaction } from '../types/Types';

export function acceptCookies(value: boolean) {
  return {
    type: ACCEPT_COOKIES,
    value
  };
}

export function acceptTerms(value: boolean) {
  return {
    type: ACCEPT_TERMS,
    value
  };
}

export function acceptDigitalLicence(value: boolean) {
  return {
    type: ACCEPT_DL_TERMS,
    value
  };
}

export function addressFinder(results: AddressFinderCanonical, name: string) {
  return {
    type: ADDRESS_FINDER,
    name,
    results
  };
}

export function phoneCountryCode(value: string) {
  return {
    type: PHONE_COUNTRY_CODE,
    value
  };
}

export function addressFieldsToggle(value: boolean) {
  return {
    type: ADDRESS_FIELDS_TOGGLE,
    value
  };
}

export function addressDeliveryFieldsToggle(value: boolean) {
  return {
    type: DELIVERY_ADDRESS_FIELDS_TOGGLE,
    value
  };
}
export function addressUseSameToggle(value: boolean) {
  return {
    type: ADDRESS_USE_SAME_TOGGLE,
    value
  };
}

export function application(value: Application) {
  return {
    type: APPLICATION_START,
    value
  };
}

export function autoClub(value: string) {
  return {
    type: AUTO_CLUB,
    value
  };
}

export function cardHolder(value: string) {
  return {
    type: CARD_HOLDER,
    value
  };
}

export function setContent(value: Content) {
  return {
    type: CONTENT,
    value
  };
}

export function creditCard(value: string) {
  return {
    type: CREDIT_CARD,
    value
  };
}

export function cvv(value: string) {
  return {
    type: CVV,
    value
  };
}

export function clearDeliveryAddress() {
  return {
    type: CLEAR_DELIVERY_ADDRESS
  };
}

export function clearLicenceAddress() {
  return {
    type: CLEAR_LICENCE_ADDRESS
  };
}
export function cloneLicenceAddress() {
  return {
    type: CLONE_LICENCE_ADDRESS
  };
}

export function dateOfBirth(value: string) {
  return {
    type: DATE_OF_BIRTH,
    value
  };
}

export function dateOfTravel(value: string) {
  return {
    type: DATE_OF_TRAVEL,
    value
  };
}

export function deleteAll() {
  return {
    type: DELETE,
    value: ''
  };
}

export function expiryMonth(value: string) {
  return {
    type: EXPIRY_MONTH,
    value
  };
}

export function expiryYear(value: string) {
  return {
    type: EXPIRY_YEAR,
    value
  };
}

export function email(value: string) {
  return {
    type: EMAIL,
    value
  };
}

export function firstName(value: string) {
  return {
    type: FIRST_NAME,
    value
  };
}

export function gateway(value: Transaction) {
  return {
    type: GATEWAY,
    value
  };
}

export function image(name: string, value: string) {
  return {
    name,
    type: IMAGE,
    value
  };
}

export function imageError(name: string, error: boolean, message: string) {
  return {
    name,
    error,
    message,
    type: IMAGE_ERROR
  };
}

export function imageErrorClear(name: string) {
  return {
    type: IMAGE_ERROR_CLEAR,
    name
  };
}

export function imageUploaded(name: string, uploaded: boolean) {
  return {
    name,
    type: IMAGE_UPLOADED,
    uploaded
  };
}

export function phoneNumber(value: string) {
  return {
    type: PHONE_NUMBER,
    value
  };
}

export function lastName(value: string) {
  return {
    type: LAST_NAME,
    value
  };
}

export function licenceAddress(value: string) {
  return {
    type: LICENCE_ADDRESS,
    value
  };
}

export function licenceClass(value: string) {
  return {
    type: LICENCE_CLASS,
    value
  };
}

export function deliveryAddress(value: string) {
  return {
    type: DELIVERY_ADDRESS,
    value
  };
}

export function deliveryInt(value: string) {
  return {
    type: DELIVERY_INT,
    value
  };
}

export function licenceDeliveryAddressFullName(value: string) {
  return {
    type: DELIVERY_ADDRESS_ADDRESS_FULL_NAME,
    value
  };
}

export function licenceDeliveryAddressLocality(value: string) {
  return {
    type: DELIVERY_ADDRESS_LOCALITY,
    value
  };
}

export function licenceDeliveryAddressPostcode(value: string) {
  return {
    type: DELIVERY_ADDRESS_POSTCODE,
    value
  };
}

export function licenceDeliveryAddressState(value: string) {
  return {
    type: DELIVERY_ADDRESS_STATE,
    value
  };
}

export function licenceDeliveryAddressStreet(value: string) {
  return {
    type: DELIVERY_ADDRESS_STREET,
    value
  };
}

export function licenceAddressLocality(value: string) {
  return {
    type: LICENCE_ADDRESS_LOCALITY,
    value
  };
}

export function licenceAddressPostcode(value: string) {
  return {
    type: LICENCE_ADDRESS_POSTCODE,
    value
  };
}

export function licenceAddressState(value: string) {
  return {
    type: LICENCE_ADDRESS_STATE,
    value
  };
}

export function licenceAddressStreet(value: string) {
  return {
    type: LICENCE_ADDRESS_STREET,
    value
  };
}

export function licenceDeliveryAddressCountry(value: string) {
  return {
    type: DELIVERY_ADDRESS_COUNTRY,
    value
  };
}

export function licenceDeliveryAddressCountryReset() {
  return {
    type: DELIVERY_ADDRESS_COUNTRY_ERROR,
  };
}

export function licenceExpiry(value: string) {
  return {
    type: LICENCE_EXPIRY,
    value
  };
}

export function licenceNumber(value: string) {
  return {
    type: LICENCE_NUMBER,
    value
  };
}

export function locality(value: string) {
  return {
    type: LOCALITY,
    value
  };
}

export function membershipNumber(value: string) {
  return {
    type: MEMBERSHIP_NUMBER,
    value
  };
}

export function middleName(value: string) {
  return {
    type: MIDDLE_NAME,
    value
  };
}

export function placeOfBirth(value: string) {
  return {
    type: PLACE_OF_BIRTH,
    value
  };
}

export function postage(value: Shipping) {
  return {
    type: POSTAGE,
    value
  };
}

export function setI18n(data: AddressData) {
  return {
    type: SET_I18N,
    data
  };
}

export function stateOfIssue(value: string) {
  return {
    type: STATE_OF_ISSUE,
    value
  };
}

export function title(value: string) {
  return {
    type: TITLE,
    value
  };
}

export function transaction(value: Transaction) {
  return {
    type: TRANSACTION,
    value
  };
}

export function validateAddress(value: string) {
  return {
    type: VALIDATE_ADDRESS,
    value
  };
}

export function validate() {
  return {
    type: VALIDATE,
    value: ''
  };
}

export function wearGlasses(value: string) {
  return {
    type: WEAR_GLASSES,
    value
  };
}
