/**
 * AAA IDP Form - Summary: Personal details component
 * @flow
 */

import * as React from 'react';
import Text from '../text/Text';

import { connect } from 'react-redux';

import { Fn } from '../../../functions/Functions';
import type { AutoClub, Contacts, Field, Images, Names } from '../../../types/Types';

type Props = {
  club: AutoClub,
  contacts: Contacts,
  dateOfBirth: Field,
  dateOfTravel: Field,
  email: Field,
  images: Images,
  names: Names,
  placeOfBirth: Field
};

export class PersonalDetails extends React.Component<Props> {
  static defaultProps = {
    club: {
      autoClub: {
        value: ''
      }
    },
    contacts: {
      contactNumber: {
        error: false,
        value: ''
      }
    },
    dateOfBirth: {
      error: false,
      value: ''
    },
    dateOfTravel: {
      error: false,
      value: ''
    },
    email: {
      error: false,
      value: ''
    },
    images: {
      recentPhoto: {
        value: ''
      }
    },
    names: {
      fullName: {
        error: false,
        value: ''
      }
    },
    placeOfBirth: {
      error: false,
      value: ''
    }
  };

  render() {
    const { club, contacts, dateOfBirth, dateOfTravel, email, images, names, placeOfBirth } = this.props;

    return (
      <div id="personal-details">
        <div className="details">
          <div className="text-list">
            <Text label="Intended travel date" name="travelDate" value={Fn.invertDate(dateOfTravel.value)} />

            <Text label="Name" name="fullName" value={names.fullName.value} />
            <Text label="Date of birth" name="dateOfBirth" value={Fn.invertDate(dateOfBirth.value)} />
            <Text label="Place of birth" name="placeOfBirth" value={placeOfBirth.value} />
            <Text label="Email" name="email" value={email.value} />
            {contacts.phoneCountryCode?.value && contacts.phoneNumber?.value ? (
              <Text
                label="Contact number"
                name="phoneNumber"
                value={`${contacts.phoneCountryCode?.value} ${contacts.phoneNumber?.value}`}
              />
            ) : null}
            <Text label="Auto club membership" name="autoClub" value={club.autoClub?.value} />
          </div>
          <div className="photo">
            <img src={images.recentPhoto.value} alt="You" />
            <p>Recent photo of yourself</p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ club, contacts, dateOfBirth, dateOfTravel, email, images, names, placeOfBirth }) => {
  return {
    club,
    contacts,
    dateOfBirth,
    dateOfTravel,
    email,
    images,
    names,
    placeOfBirth
  };
};

const VisiblePersonalDetails = connect(mapStateToProps)(PersonalDetails);

export default VisiblePersonalDetails;
