import { TERMS_URL } from '../../data/Data';

export const SITE_CONTENT = {
  alert: {
    content: '<p>Due to the public holidays over the Christmas period the processing and delivery of your permit could be delayed. Please check the <a href="https://auspost.com.au/about-us/news-media/important-updates/coronavirus/coronavirus-international-updates" target="_blank" rel="noopener noreferrer">Australia Post website</a> for updated information on delays to domestic and international destinations.</p>',
    show: false
  },
  banner: {
    title: 'International Driving Permit',
    content: 'The only authorised permit for Australians driving overseas'
  },
  what: {
    title: 'What is an International Driving Permit?',
    content:
      '<p>An International Driving Permit (IDP) is a translation of your domestic Australian drivers licence photo card in nine (9) languages that assists a user to drive in a foreign country. Permits are recognised in 98% of countries around the world – driving abroad without one could be illegal and they are valid for 12 months from date of issue.</p><p>The <a href="https://www.aaa.asn.au/international-motoring/international-driving-permits/" target="_blank" rel="noopener noreferrer">Australian Automobile Association (AAA)</a> is the only authorised distributor of IDPs for Australian driver licence holders. The motoring club in your state/territory will process your IDP on behalf of the AAA as their sales agent.</p>',
    ctaTitle: 'Do I need an IDP?',
    ctaContent: 'Check if the destination you’re travelling to requires an International Driving Permit',
    ctaLinkTitle: 'Check countries',
    ctaLinkUrl: 'https://www.aaa.asn.au/international-motoring/international-driving-permits/#where-do-i-need-a-permit'
  },
  faq: {
    mainTitle: 'Frequently asked questions',
    items: [
      {
        title: 'Why do I need an International Driver\'s Permit or licence?',
        content:
          'If you\'re planning on travelling overseas, a valid International Drivers Permit (IDP) is an official United Nations and Government document that shows local authorities that you’re licenced to drive in Australia. Local authorities and car companies may require you hold an IDP to drive or rent a vehicle.'
      },
      {
        title: 'How long is an IDP valid for?',
        content:
          'A permit is valid for 12 months from the date of issue or the expiry date of your Australian driver licence. If your licence expires during the permit period, simply renew your licence and the permit will stay valid.'
      },
      {
        title: 'Can I apply for an IDP while I\'m travelling overseas?',
        content:
          'Yes. You can apply online and use international express post or direct courier to deliver your IDP to an overseas address.'
      },
      {
        title: 'How long does it take to process or receive my IDP?',
        content:
          'If you apply online, your hardcopy IDP booklet will be dispatched within 3 business days once approved. To estimate postage timeframes based on your location you can check <a href="https://auspost.com.au/service-updates/domestic-delivery-times" target="_blank">Australia Post domestic delivery timeframes</a> – you\'ll need to allow more time for public holidays and regional or international delivery. If you apply in person at a branch, your IDP can be issued on the spot if you have the required documentation. '
      }
    ],
    postCopy:
      'If you still have questions, you can learn more about International Driving Permits on the <a href="https://www.aaa.asn.au/international-motoring/international-driving-permits/" target="_blank">Australian Automobile Association’s website</a>.'
  },
  cta: {
    title: 'The only authorised permit for Australians driving overseas',
    content:
      'The Australian Automobile Association (AAA) is the only authorised distributor for International Driving Permits in Australia.',
    btnTitle: 'Apply Now'
  },
  lead: 'Are you planning to drive on your next overseas holiday or work trip? Whether you are hiring a car, motorbike, mini bus or go-kart, chances are you will need an International Driving Permit (IDP).',
  need: {
    title: 'What you\'ll need to apply',
    content: [
      {
        icon: 'passport-photo.png',
        title: 'Recent photo',
        content: '<p>A recent photo of yourself with a plain white background.</p>'
      },
      {
        icon: 'licence-scan.png',
        title: 'Clear scan of driver licence',
        content: '<p>A clear and readable scan of the front and back of your photo card licence. We can’t accept temporary or digital licences. You’ll need to wait until you get your photo card licence before you can apply.</p>'
      },
      {
        icon: 'payment.png',
        title: 'Credit card payment',
        content: '<p>IDPs are AUD $51 (plus postage). You can pay by Visa or Mastercard.</p>'
      }
    ],
    passportPhoto: {
      icon: 'passport-requirements.png',
      title: 'Photo requirements',
      content:
        '<ul><li>Recent passport size photo</li><li>Hair cannot cover face</li><li>Glasses are not allowed</li><li>Plain white background</li><li>Clear, good quality image. No watermarks.</li></ul><p><a href="https://www.passports.gov.au/getting-passport-how-it-works/photo-requirements" target="_blank" rel="noopener noreferrer">Check the Australian Passport Office requirements</a>.</p>'
    },
    licencePhoto: {
      icon: 'licence-requirements.png',
      title: 'Licence photo requirements',
      content:
        '<p>To complete your application, you’ll need to provide a scan or photo of the both sides of your Australian photo card driver licence. Each image must be a clear and readable scan or photo of the front and back of the licence.</p>'
    }
  },
  howTo: {
    title: 'How to apply',
    items: [
      {
        title: 'Prepare documents',
        content: '<p>Prepare supporting documents to complete your application. Make sure all of your photos meet the quality requirements explained in <a href="#what-you-need">what you’ll need to apply</a>.</p>'
      },
      {
        title: 'Complete online form',
        content: '<p>Complete and submit the online application form.</p><p>If you need an IDP within the next 5 business days, you can <a href="https://www.aaa.asn.au/pages/members/" target="_blank" noreferrer nofollow>visit a retail branch</a> where it can be issued immediately.</p>'
      },
      {
        title: 'Processing and posting',
        content: '<p>Once your application is approved, your IDP will be dispatched within <b>3 business days</b>. Please allow longer for public holidays and regional or international delivery.</p>'
      }
    ]
  },
  more: {
    title: 'More about the IDP application process',
    content: `<p>The motoring club in your state/territory will process your IDP on behalf of the AAA as their sales agent.</p>
    <p>An IDP carries your identification details in nine languages (English, Spanish, Japanese, Greek, German, Arabic, Russian,
    Chinese and French).</p>
    <p>Your IDP is proof that you hold a current domestic drivers licence and it is only valid if you carry your domestic
    driver's licence with your IDP.</p>
    <p>Before purchasing an IDP it is highly recommended that you visit the AAA International Driving Permit Directory
    on the following <a href="http://www.aaa.asn.au/international-services/-international-driving-permit-directory/" target="_blank" rel="noopener noreferrer">link</a> and check your destinations for IDP requirements. This information is only
    a guide and seeking contact with local authorities is always encouraged as it will provide you with the latest
    requirements for that country.</p>
    <p>Protecting your personal information is important to the AAA and their sales agents. Please refer to the below link
    to our Privacy Policy. Should you not wish to provide your details online you can submit a postal application via the
    following <a href="https://www.aaa.asn.au/IDP-application" target="_blank" rel="noopener noreferrer">link</a>.</p>`
  },
  form: {
    title: 'Start your application',
    content: 'Tell us which state or territory your Australian driver licence was issued in.',
    button: 'Apply now'
  },
  acceptTermsContent: {
    title: 'Please accept the terms of use:',
    label: `I agree to the terms of the <a href="${TERMS_URL}" target="_blank" rel="noopener noreferrer">Privacy Policy and Terms of Use</a>. I consent to the use of my personal information and understand it will be used in accordance with the privacy policy.`,
    checked: false
  },
  acceptDigitalLicenceContent: {
    label: 'I understand my country/ies of intended travel may not accept my digital Australian Driver\'s Licence. I therefore agree to ensure I meet the requirements that the country/ies of intended travel may impose on an IDP, which may mean ensuring my IDP is accompanied by my current Australian Driver\'s Licence photo card (physical driver\'s licence).',
    checked: false
  },
  info: `<p>Australian Automobile Association (AAA) has been duly empowered and authorised by the Australian Government to issue International Driving Permit (IDP) in Australia on their behalf as the Australian Government is party to the 1949 Geneva Convention on Road Traffic.</p>
  <p>Protecting your personal information is important to the AAA and their sales agents. Please refer to the
    below link to our Privacy Policy.</p>`,
  contact: {
    title: 'Australian Automobile Association',
    address1: 'GPO Box 1555',
    address2: 'Canberra ACT 2601 Australia',
    phone: '02 6247 7311',
    email: 'international@aaa.asn.au'
  },
  nextSteps: {
    title: 'What happens next?',
    content: [
      {
        title: 'Application review',
        content: '<p>We will review your application to make sure all the information provided meets our requirements.</p>'
      },
      {
        title: 'Application approval',
        content: `<p>If the information provided does not meet requirements, we will make multiple attempts to contact you and advise what is required to correct the application.
If we are unable to  contact you, your order will be cancelled, and a refund will be processed.
</p>`
      },
      {
        title: 'Printing and dispatch',
        content: `<p>Once approved your permit will be printed and dispatched within 3 business days.
You will receive a confirmation email with tracking details.
</p>`
      }
    ]
  }
};
