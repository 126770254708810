/**
 * AAA IDP Form - Text summary component
 * @flow
 */

import * as React from 'react';
import { COUNTRIES } from '../../../data/Data';
import './Text.css';

type Props = {
  label: string,
  name: string,
  value: string | boolean,
}

class Text extends React.Component<Props> {
  static defaultProps = {
    label: 'Name',
    name: 'fullName',
    value: 'Harvey Milk'
  }

  render() {
    let {label, name, value} = this.props;
    let classes = `Text ${name}`;

    if ('placeOfBirth' === this.props.name) {
      for (let i=0; i< COUNTRIES.length; i++) {
        if (COUNTRIES[i].value === value) {
          value = COUNTRIES[i].title;
        }
      }
    }

    return (
      <div className={classes}>
        <div className="label">{label}</div>
        <div className="value">{value}</div>
      </div>
    );
  }
}

export default Text;