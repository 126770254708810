/**
 * Credit Card CVV
 * @flow
 */
import React, { Component } from 'react';
import TextInput from '../../../../components/text-input/TextInput';
import { cvv } from '../../../../actions/index';
import { connect } from 'react-redux';
import type { CreditCard } from '../../../../types/Types';

type Props = {
  setCvv: (value: string) => *,
  creditCard: CreditCard,
  gateway: {
    name: string,
  },
}

class CVV extends Component<Props> {
  static defaultProps = {
    setCvv: () => null,
    creditCard: {
      cvv: {
        error: false,
        required: true,
        value: '',
        valid: null,
      },
    },
    gateway: {
      name: 'windcave',
    },
  };

  render() {
    const { setCvv, creditCard, gateway } = this.props;
    const name: string = gateway.name === 'windcave' ? 'Cvc2' : gateway.name === 'securepay' ? 'EPS_CCV' : 'cvv';
    const values = {
      [name]: {
        ...creditCard.cvv
      }
    };
    return (
      <TextInput
        label="CCV"
        name={name}
        handleChange={setCvv}
        onBlur={setCvv}
        // $FlowFixMe
        values={values}
        placeholder="123"
        section=""
        type="tel"
        inputMode="numeric"
      />
    );
  }
}

const mapStateToProps = ({ creditCard }) => {
  return { creditCard };
};

const mapDispatchToProps = dispatch => {
  return {
    setCvv: (value: string) => {
      dispatch(cvv(value))
    },
  }
};

const VisibleCVV= connect(mapStateToProps, mapDispatchToProps)(CVV);

export default VisibleCVV;
