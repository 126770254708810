/**
 * AAA IDP delivery
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import Info from '../info/Info';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deliveryInt, postage } from '../../actions/index';
import { DELIVERY_MSG, IMAGE_PATH_PREFIX, POSTAGE } from '../../data/Data';
import type { Shipping } from '../../types/Types';
import './Delivery.css';

type Props = {
  addresses: {
    licenceDelivery: {
      value: string
    }
  },
  deliveryInt: (value: string) => *,
  setPostage: (postage: Shipping) => *
};

type State = {
  focus: string
};

export class Delivery extends React.Component<Props, State> {
  static defaultProps = {
    addresses: {
      licenceDelivery: {
        value: 'Australia'
      }
    }
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      focus: ''
    };
  }

  componentDidMount() {
    // $FlowFixMe
    document.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount() {
    // $FlowFixMe
    document.removeEventListener('keydown', this.handleKeydown);
  }

  /**
   * handleKeydown
   */
  handleKeydown = (e: SyntheticKeyboardEvent<>) => {
    // $FlowFixMe
    const { key } = e;
    const { deliveryInt } = this.props;
    const { focus } = this.state;

    if (focus && key === 'Enter') {
      deliveryInt(focus);
    }
  };

  /**
   * onFocus
   */
  onFocus = (focus: string) => {
    this.setState({
      focus
    });
  };

  /**
   * onBlur
   */
  onBlur = () => {
    this.setState({
      focus: ''
    });
  };

  /**
   * Select Australian / International
   */
  select = (value: string) => {
    const { setPostage, deliveryInt } = this.props;

    deliveryInt(value);

    if (value === 'Australian') {
      setPostage(POSTAGE[0]);
    } else {
      setPostage(POSTAGE[1]);
    }
  };

  render() {
    const { addresses } = this.props;
    const { licenceDelivery } = addresses;

    let buttons = [];
    const options = [
      {
        default: true,
        checked: licenceDelivery === 'Australian' ? true : false,
        title: 'Australian address',
        value: 'Australian',
        icon: <img src={`${IMAGE_PATH_PREFIX}/australia.png`} alt="Icon of Australia" />
      },
      {
        default: false,
        checked: licenceDelivery === 'International' ? true : false,
        title: 'International address',
        value: 'International',
        icon: <FontAwesomeIcon icon="globe" />
      }
    ];

    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      const highlight = option.checked ? ' highlight' : '';
      buttons.push(
        <div
          className="radiobutton-container"
          key={option.value}
          tabIndex="0"
          onFocus={() => this.onFocus(option.value)}
          onBlur={this.onBlur}
        >
          <input
            id={option.value}
            className="radio-button-hidden"
            type="radio"
            checked={option.checked}
            value={option.value}
            onChange={() => this.select(option.value)}
          />
          <label htmlFor={option.value}>
            <div className="radio-display">
              <div className={`inner${highlight}`} />
            </div>

            {option.title}
          </label>
        </div>
      );
    }

    if (buttons.length > 0) {
      return (
        <div className="form-element-container Delivery">
          <div className="delivery-options">
            <div className="label title">Deliver my IDP to an:</div>
            <div className="radio-buttons">{buttons}</div>
          </div>
          {licenceDelivery === 'International' ? (
            <Info icon="envelope" framed={true} info={DELIVERY_MSG.International} />
          ) : null}
          {licenceDelivery === 'Australian' ? (
            <Info icon="envelope" framed={true} info={DELIVERY_MSG.Australian} />
          ) : null}
        </div>
      );
    }

    return null;
  }
}

const mapStateToProps = ({ addresses, postage }) => {
  return { addresses, postage };
};

const mapDispatchToProps = dispatch => {
  return {
    deliveryInt: (value: string) => {
      dispatch(deliveryInt(value));
    },
    setPostage: (value: Shipping) => {
      dispatch(postage(value));
    }
  };
};

const VisibleDelivery = connect(mapStateToProps, mapDispatchToProps)(Delivery);

export default VisibleDelivery;
