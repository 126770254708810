/**
 * AAA IDP licence number reducer
 * @flow
 */
import {DELETE, LICENCE_NUMBER, VALIDATE} from '../../actions/types';
import type { ActionType, Field } from '../../types/Types';
export const initialState = {
  error: false,
  required: true,
  valid:  null,
  value: '',
};
const maxLength = 15;

export default function(state: Field = initialState, action: ActionType) {
  switch (action.type) {
    case DELETE :
      return initialState;

    case LICENCE_NUMBER : {
      const { required } = state;
      const { value } = action;
      const empty = value === '' && required ? true : false;
      const long = value.length > maxLength ? true : false;
      const valid = empty || long ? false : true;
      
      return {
        ...state,
        error: !valid,
        valid,
        value
      };
    }

    case VALIDATE : {
      const { required, value } = state;
      const empty = value === '' && required ? true : false;
      const long = value.length > maxLength ? true : false;
      const valid = empty || long ? false : true;
      
      return {
        ...state,
        error: !valid,
        valid,
      };
    }

    default:
      return state;
  }
}
