/**
 * AAA IDP Progress component
 * @flow
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './Progress.css';

const PROGRESS = [
  { name: 'Details', path: /details/ },
  { name: 'Summary', path: /summary/ },
  { name: 'Payment', path: /payment|completed/ }
  // {name: 'Confirmation', path: /confirmation/}
];

type Props = {
  location: { pathname: string }
};

export class Progress extends Component<Props> {
  static defaultProps = {
    location: {
      pathname: '/'
    }
  };

  render() {
    const { location } = this.props;
    let steps = [];

    for (let i = 0; i < PROGRESS.length; i++) {
      const progress = PROGRESS[i];
      let classes = `step${progress.path.test(location.pathname) ? ' active' : ''}`;

      steps.push(
        <li key={i} className={classes}>
          <div className="number-container">
            <span className="number">{parseInt(i) + 1}</span>
          </div>
          <div className="name">{progress.name}</div>
        </li>
      );
    }

    return (
      <section className="progress">
        <ul className="inner">{steps}</ul>
      </section>
    );
  }
}

export default withRouter(Progress);
