/**
 * AAA IDP Form - ContactNumber component
 * @flow
 */

import * as React from 'react';
import { connect } from 'react-redux';
import Select from '../select/Select';
import TextInput from '../text-input/TextInput';
import ErrorMessage from '../error-message/ErrorMessage';
import { phoneCountryCode, phoneNumber } from '../../actions';
import { PHONE_COUNTRY_CODE } from '../../data/Data';
import type { Contacts } from '../../types/Types';
import './ContactNumber.css';

type Props = {
  contacts: Contacts,
  float: boolean,
  label: string,
  name: string,
  required: boolean,
  section: string,
  setPhoneCountryCode: (value: string) => *,
  setPhoneNumber: (value: string) => *
};

export class ContactNumber extends React.Component<Props> {
  static defaultProps = {
    contacts: {
      phoneCountryCode: {
        error: false,
        required: true,
        valid: null,
        value: '61'
      },
      contactNumber: {
        error: false,
        required: true,
        valid: null,
        value: ''
      },
      phoneNumber: {
        error: false,
        required: true,
        valid: null,
        value: ''
      }
    },
    float: false,
    id: 'select',
    label: 'select',
    name: 'contactNumber',
    required: false,
    section: 'personal-details'
  };

  render() {
    const { contacts, float, name, section, setPhoneCountryCode, setPhoneNumber } = this.props;
    const { error } = contacts.contactNumber ? contacts.contactNumber : { error: false };
    const classes = `form-element-container ${name}${error ? ' error' : ''}${float ? ' left' : ''}`;

    return (
      <div className={classes}>
        <div className="field-set">
          <Select
            autoComplete="tel-area-code"
            label={'Country code *'}
            options={PHONE_COUNTRY_CODE}
            name={'phoneCountryCode'}
            section={section}
            handleChange={setPhoneCountryCode}
            onBlur={setPhoneCountryCode}
            values={contacts}
          />

          <TextInput
            autoComplete="tel"
            label={'Contact number *'}
            name={'phoneNumber'}
            placeholder={'eg 0400123456 / 0299223344'}
            section={section}
            handleChange={setPhoneNumber}
            onBlur={setPhoneNumber}
            values={contacts}
            type="tel"
            inputMode="numeric"
          />
        </div>
        <ErrorMessage message={'You must enter a contact number'} error={error} />
      </div>
    );
  }
}

const mapStateToProps = ({ contacts }) => {
  return { contacts };
};

const mapDispatchToProps = dispatch => {
  return {
    setPhoneCountryCode: (value: string) => {
      dispatch(phoneCountryCode(value));
    },
    setPhoneNumber: (value: string) => {
      dispatch(phoneNumber(value));
    }
  };
};

const VisibleContactNumber = connect(mapStateToProps, mapDispatchToProps)(ContactNumber);

export default VisibleContactNumber;
