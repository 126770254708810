/**
 * AAA IDP Confirmation component
 * @flow
 */
import * as React from 'react';
import AlreadyPaid from '../../components/already-paid/AlreadyPaid';
import ErrorApp from '../../components/error-app/ErrorApp';
import PersonalDetails from './personal-details/PersonalDetails';
import LicenceDetails from './licence-details/LicenceDetails';
import Progress from '../../components/progress/Progress';
import Postage from './postage/Postage';
import CostTable from './cost-table/CostTable';
import Footer from './footer/Footer';
import Contact from '../../components/contact/Contact';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import type { Addresses, Application, Transaction } from '../../types/Types';
import { SITE_CONTENT } from '../../reducers/content/Site.content';
import type { Content } from '../../types/Types';
import type { Bugsnag } from '@bugsnag/js';
import './Summary.css';
type Props = {
  addresses: Addresses,
  application: Application,
  bugsnagClient: Bugsnag,
  jest: boolean,
  transaction: Transaction,
  content: Content
};

type State = {
  canPay: boolean
};

export class Summary extends React.Component<Props, State> {
  static defaultProps = {
    addresses: {
      licenceDelivery: 'Australian'
    },
    jest: false,
    transaction: {
      success: false
    },
    content: { ...SITE_CONTENT }
  };

  constructor(props: Props) {
    super(props);

    const { licenceDelivery } = props.addresses;

    this.state = {
      canPay: licenceDelivery === 'Australian' ? true : false
    };
  }

  setCanPay = (canPay: boolean) => {
    this.setState({ canPay });
  };

  render() {
    const { application, bugsnagClient, transaction, content } = this.props;
    const { canPay } = this.state;
    const { id, submission_token } = application;
    const { contact } = content;

    switch (true) {
      case !id || !submission_token:
        return <ErrorApp bugsnagClient={bugsnagClient} />;

      case transaction.success:
        return (
          <React.Fragment>
            <Progress />
            <section className="form-container">
              <div className="Summary">
                <AlreadyPaid />
              </div>
            </section>
          </React.Fragment>
        );

      default:
        return (
          <React.Fragment>
            <Progress />
            <section className="content-container Summary">
              <div className="page-description">
                Review the information that you've provided to ensure it's all correct. Your International Driving
                Permit will be prepared based on the information displayed on this screen.
              </div>
              <h2>
                Your details
                <Link className="edit" to="/details">
                  <FontAwesomeIcon icon="pencil" /> Edit
                </Link>
              </h2>
              <PersonalDetails />
              <h2>
                Licence details
                <Link className="edit" to="/details#licence-details">
                  <FontAwesomeIcon icon="pencil" /> Edit
                </Link>
              </h2>
              <LicenceDetails />
              <h2>Postage and total cost</h2>
              <p className="no-margin">
                Once your application is reviewed and approved, your permit will be dispatched within 3 business days in
                metropolitan areas. It may take longer for public holidays and regional delivery subject to Australia
                Post delivery times. You will receive a confirmation email with tracking details.
              </p>
              <Postage bugsnagClient={bugsnagClient} setCanPay={this.setCanPay} />
              <CostTable />
              <Footer bugsnagClient={bugsnagClient} canPay={canPay} />
            </section>
            <Contact {...contact} />
          </React.Fragment>
        );
    }
  }
}

const mapStateToProps = ({ addresses, application, transaction }) => {
  return { addresses, application, transaction };
};

const VisibleSummary = connect(mapStateToProps)(Summary);

export default VisibleSummary;
