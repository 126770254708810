/**
 * AAA IDP Radio Button component
 * @flow
 */
import * as React from 'react';
import ErrorMessage from '../error-message/ErrorMessage';
import Info from '../info/Info';
import type { Field, Shipping } from  '../../types/Types';
import './RadioButton.css';

type Props = {
  handleChange: (value: string) => *,
  info: string,
  label: string,
  name: string,
  options: Array<{
    default: boolean,
    title: *,
    value: string,
    icon?: *
  }>,
  onBlur: (value: string) => *,
  required: boolean,
  section: string,
  values: { wearGlasses: Field } | Shipping,
};

type State = {
  focus: string,
};

class RadioButtons extends React.Component<Props, State> {
  static defaultProps = {
    info: '',
    label: 'Do you wear glasses while driving',
    name: 'wearGlasses',
    options: [
      {
        default: true,
        title: 'No',
        value: 'No',
        icon: null,
      },
      {
        default: false,        
        title: 'Yes',
        value: 'Yes',
        icon: null,
      },
     
    ],
    required: false,
    section: 'license-details',
    values: {
      wearGlasses: {
        error: false,
        required: false,
        valid: false,
        value: 'No',
      },
    }
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      focus: ''
    };
  }

  componentDidMount() {
    // $FlowFixMe
    document.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount() {
    // $FlowFixMe
    document.removeEventListener('keydown', this.handleKeydown);
  }

  /**
   * handleKeydown
   */
  handleKeydown = (e: SyntheticKeyboardEvent<>) => {
    // $FlowFixMe
    const { key } = e;
    const { handleChange } = this.props;
    const { focus } = this.state;

    if (focus && key === 'Enter') {
      handleChange(focus);
    }
  }

  /**
   * onFocus
   */
  onFocus = (focus: string) => {
    this.setState({
      focus
    });
  }

  /**
   * onBlur
   */
  onBlur = () => {
    this.setState({
      focus: ''
    });
  }

  renderButtons() {
    const { handleChange, name, options, values } = this.props;
    const { focus } = this.state;
    let buttons = [];

    for (let i=0; i<options.length; i++) {
      const option = options[i];
      const checked = values[name].value === option.value ? true : false ;
      const highlight = checked ? ' highlight' : '';
      const selected = checked ? ' selected ' : '';
      const hasFocus = focus === option.value ? ' focus' : '';
      
      buttons.push(
        <div
          className={`radiobutton-container${selected}${hasFocus}`}
          key={option.value}
          onClick={() => handleChange(option.value)}
          tabIndex="0"
          onFocus={() => this.onFocus(option.value)}
          onBlur={this.onBlur}
        >
          <input
            id={option.value}
            className="radio-button-hidden"
            type="radio"
            checked={checked}
            value={option.value}
            onChange={() => handleChange(option.value)}
          />
          <label htmlFor={option.value}>
            <div className="radio-display">
              <div className={`inner${highlight}`} />
            </div>
            <div className="radio-text">
              {option.icon ? option.icon : null}
              {option.title}
            </div>
          </label>
        </div>
      );
    }

    if (buttons.length > 0) {
      return (
        <div className="radio-buttons">
          {buttons}
        </div>
      );

    }
    return null;
  }

  render() {
    const {label, info, name, values} = this.props;
    const {error, required, valid} = values[name];
    const classes = `form-element-container ${name}${error ? ' error': ''}`;
    const errorMessage = valid === null ? `${label} is required` : valid === false ? `${label} is not valid` : '';

    return (
      <div className={classes}>
        <div className="label">
          {label} 
          {required ? <span className="required">*</span> : ''}
        </div>
        {this.renderButtons()}
        {info.length > 0 ? <Info info={info} /> : null}
        <ErrorMessage message={errorMessage} error={error} />
      </div>
    );
  }
}

export default RadioButtons;
