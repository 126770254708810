/**
 * AAA IDP Error component
 * @flow
 */

import * as React from 'react';
import './ErrorMessage.css';

type Props = {
  message: string,
  error: boolean,
  errorStyle: string
};

class ErrorMessage extends React.Component<Props> {
  static defaultProps = {
    message: '',
    error: false,
    errorStyle: ''
  };

  render() {
    let { error, errorStyle, message } = this.props;

    if (!error || message.length === 0) {
      return null;
    }
    errorStyle = errorStyle.length > 0 ? ` ${errorStyle}` : '';

    return (
      <div className={`error-message${errorStyle}`}>
        <div className="message" dangerouslySetInnerHTML={{ __html: message }} />
      </div>
    );
  }
}

export default ErrorMessage;
