/**
 * AAA IDP locality reducer
 * Set the state of the user from the home page form
 * @flow
 */
import {DELETE, LOCALITY} from '../../actions/types';
import {STATE_CLUB} from '../../data/Data';
import type {ActionType} from '../../types/Types';

export type Locality = {
  club: string,
  error: boolean,
  required: boolean,
  valid: null | boolean,
  value: string,
};

export const initialState = {
  club: '',
  error: false,
  required: true,
  valid:  null ,
  value: '',
};

export default function(state: Locality = initialState, action: ActionType) {
  switch (action.type) {
    case DELETE :
      return initialState;

    case LOCALITY : {
      let {required} = state; 
      const {value} = action;
      const valid = value === '' && required ? false : true;
      const club = value ? STATE_CLUB[value] : '';

      return {
        club,
        error: !valid,
        required,
        valid,
        value
      };
    }

    default:
      return state;
  }
}
