/**
 * AAA IDP date of birth reducer
 * @flow
 */
import { DATE_OF_BIRTH, DELETE, VALIDATE } from '../../actions/types';
import type { ActionType } from '../../types/Types';
import moment from 'moment';

type State = {
  error: boolean,
  errorMessage: string,
  required: boolean,
  valid: boolean | null,
  value: string
};

export const initialState = {
  error: false,
  errorMessage: '',
  required: true,
  valid: null,
  value: ''
};

export default function(state: State = initialState, action: ActionType) {
  switch (action.type) {
    case DATE_OF_BIRTH: {
      const { value } = action;
      return validateDate({ ...state, value });
    }

    case DELETE:
      return initialState;

    case VALIDATE:
      return validateDate(state);

    default:
      return state;
  }
}

/**
 * Validate date
 */
export function validateDate(state: State) {
  const { required, value } = state;
  const date = moment(value, 'YYYY-MM-DD', true);
  const dateValid = date.isValid();
  const minAge = 18;
  const maxAge = 100;
  // Must be over 18 years and under 100 years of age to get an IDP.
  const legalAge = Math.abs(date.diff(moment(), 'years')) >= minAge && Math.abs(date.diff(moment(), 'years')) <= maxAge;

  switch (true) {
    // Empty value
    case value === '' && required:
      return {
        ...state,
        error: true,
        errorMessage: '',
        valid: false
      };

    // Invalid date
    case !dateValid:
      return {
        ...state,
        error: true,
        errorMessage: '',
        valid: false
      };

    // Ensure age is above cut off
    case !legalAge:
      return {
        ...state,
        error: true,
        errorMessage: `You must be between ${minAge} and ${maxAge} years old to apply for an International Driving Permit`,
        valid: false,
        value
      };

    // age is valid
    default:
      return {
        ...state,
        error: false,
        errorMessage: '',
        valid: true
      };
  }
}
