import { combineReducers } from 'redux';
import addresses from './addresses/addressReducer';
import application from './application/applicationReducer';
import club from './auto-club/autoClubReducer';
import contacts from './contacts/contactReducer';
import content from './content/contentReducer';
import cookies from './cookies/cookyReducer';
import creditCard from './credit-card/creditcardReducer';
import dateOfBirth from './date-of-birth/dateOfBirthReducer';
import dateOfTravel from './date-of-travel/dateOfTravelReducer';
import email from './email/emailReducer';
import images from './images/imageReducer';
import licenceClass from './licence-class/licenceClassReducer';
import licenceExpiry from './licence-expiry/licenceExpiryReducer';
import licenceNumber from './licence-number/licenceNumberReducer';
import locality from './locality/localityReducer';
import names from './name/nameReducer';
import placeOfBirth from './place-of-birth/placeOfBirthReducer';
import postage from './postage/postageReducer';
import stateOfIssue from './state-of-issue/stateOfIssueReducer';
import terms from './terms/termsReducer';
import digitalLicenceTerms from './digital-licence-terms/digitalLicenceTermsReducer';
import transaction from './transaction/transactionReducer';
import wearGlasses from './wear-glasses/wearGlassesReducer';

export default combineReducers({
  addresses,
  application,
  club,
  contacts,
  content,
  cookies,
  creditCard,
  dateOfBirth,
  dateOfTravel,
  digitalLicenceTerms,
  email,
  images,
  names,
  licenceClass,
  licenceExpiry,
  licenceNumber,
  locality,
  placeOfBirth,
  postage,
  stateOfIssue,
  terms,
  transaction,
  wearGlasses
});
