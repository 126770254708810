/**
 * Contact number reducer
 * @flow
 */
import { Contacts, PHONE_COUNTRY_CODE, DELETE, PHONE_NUMBER, VALIDATE } from '../../actions/types';
import type { ActionType } from '../../types/Types';

const PHONE_NUMBER_REGEX = new RegExp(/(^[0-9]{4}\s[0-9]{4}\s{0,1}$)|^[0-9]{7,15}\s{0,1}$/);
const PHONE_INPUT_REGEX = new RegExp(/\d+\s{0,1}|\+/g);

export const initContacts = {
  phoneCountryCode: {
    error: false,
    required: true,
    valid: true,
    value: '61'
  },
  contactNumber: {
    error: false,
    required: true,
    valid: null,
    value: ''
  },
  phoneNumber: {
    error: false,
    required: true,
    valid: null,
    value: ''
  }
};

export default function(state: Contacts = initContacts, action: ActionType) {
  switch (action.type) {
    case PHONE_COUNTRY_CODE: {
      let { required } = state.phoneCountryCode;
      const { value } = action;
      const valid = value === '' ? false : true;

      state = {
        ...state,
        phoneCountryCode: {
          error: !valid,
          required,
          valid,
          value
        }
      };

      state = contactNumberValid(state);

      return state;
    }

    case DELETE: {
      const cleanContacts = { ...initContacts };
      return cleanContacts;
    }

    case PHONE_NUMBER: {
      let { phoneCountryCode, phoneNumber } = state;
      const { required } = phoneNumber;
      let { value } = action;
      value = filterInput(value);
      const phoneValid = PHONE_NUMBER_REGEX.test(value.replace(/\s/g, '')) ? true : false;
      const valid = phoneValid ? true : false;
      //we need an area code
      const phoneCountryCodeValid = phoneCountryCode.value === '' ? false : true;

      state = {
        ...state,
        phoneCountryCode: {
          ...phoneCountryCode,
          error: !phoneCountryCodeValid,
          valid: phoneCountryCodeValid
        },
        phoneNumber: {
          error: !valid,
          required,
          valid,
          value
        }
      };

      state = contactNumberValid(state);

      return state;
    }

    case VALIDATE:
      return contactNumberValid(state);

    default:
      return state;
  }
}

function contactNumberValid(state: Contacts) {
  let { phoneCountryCode, contactNumber, phoneNumber } = state;

  switch (true) {
    // All valid
    case phoneCountryCode.valid && phoneNumber.valid:
      return {
        ...state,
        contactNumber: {
          ...contactNumber,
          error: false,
          valid: true
        }
      };

    // valid country code - don't show errors on phoneNumber when not yet touched
    case phoneCountryCode.valid && phoneNumber.valid === null:
      return state;

    // empty country code, valid phoneNumber
    case phoneCountryCode.valid && phoneNumber.value === '':
      return {
        ...state,
        phoneNumber: {
          ...phoneNumber,
          error: true,
          valid: false
        },
        contactNumber: {
          ...contactNumber,
          error: true,
          valid: false
        }
      };

    // empty country code, valid phoneNumber
    case phoneCountryCode.value === '' && phoneNumber.valid:
      return {
        ...state,
        phoneCountryCode: {
          ...phoneCountryCode,
          error: true,
          valid: false
        },
        contactNumber: {
          ...contactNumber,
          error: true,
          valid: false
        }
      };

    // None valid
    default:
      return {
        ...state,
        contactNumber: {
          ...contactNumber,
          error: true,
          valid: false
        }
      };
  }
}

/**
 * only allow numbers
 */
function filterInput(value: string) {
  let matches = [];
  let digits = [];

  while ((matches = PHONE_INPUT_REGEX.exec(value)) !== null) {
    if (matches !== null) {
      digits.push(matches[0]);
    }
  }

  if (digits.length > 0) {
    value = digits.join('');
  } else {
    value = '';
  }

  return value;
}
