/**
 * AAA IDP Routes
 * @flow
 */
import * as React from 'react';
import { /* Redirect, */ Route, Switch } from 'react-router-dom';
import Home from '../pages/home/Home';
import Details from '../pages/details/Details';
import Summary from '../pages/summary/Summary';
import Payment from '../pages/payment/PaymentPage';
import Completed from '../pages/completed/Completed';
import Confirmation from '../pages/confirmation/Confirmation';
import FourOhFour from '../pages/fourohfour/FourOhFour';
import Login from '../pages/login/Login';
import type { Bugsnag } from '@bugsnag/js';

type Props = {
  bugsnagClient: Bugsnag
};

class Routes extends React.Component<Props> {
  render() {
    return (
      <Switch>
        <Route path="/" exact={true} render={router => <Home {...this.props} {...router} />} />
        <Route path="/details" exact={true} render={router => <Details {...this.props} {...router} />} />
        <Route path="/summary" exact={true} render={router => <Summary {...this.props} {...router} />} />
        <Route path="/payment" exact={true} render={router => <Payment {...this.props} {...router} />} />
        <Route path="/completed" exact={true} render={router => <Completed {...this.props} {...router} />} />
        <Route path="/confirmation" exact={true} render={router => <Confirmation {...this.props} {...router} />} />
        <Route path="/member(/login)*(/)*" render={() => <Login />} />
        <Route component={FourOhFour} />
      </Switch>
    );
  }
}

export default Routes;
