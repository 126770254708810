/**
 * AAA IDP Payment
 * @flow
 */
import React from 'react';
import {connect} from 'react-redux';
import {COSTS} from '../../data/Data';
import './PaymentText.css';

type Props = {
  application: {
    club: {
      abbreviation: string,
    }
  },
  postage: {
    description: string,
    cost: number,
    value: string,
  }
};

export class PaymentText extends React.Component<Props> {
  static defaultProps = {
    application: {
      club: {
        abbreviation: 'RAA',
      }
    },
    postage: {
      description: 'Express Post within Australia',
      cost: 11.40,
      value: 'express_post',
    }
  }

  render() {
    const {application, postage} = this.props;
    const price = (postage.cost + COSTS.permit).toFixed(2); 

    return (
      <div className="PaymentText">
        <p>
          Your credit card will be charged <strong>${price}</strong> by {application.club.abbreviation} for the purchase of an International Driver Permit.
        </p>
      </div>
    );
  }
}

const mapStateToProps = ({application, postage}) => {
  return {application, postage};
};

const VisiblePaymentText = connect(mapStateToProps,)(PaymentText);

export default VisiblePaymentText;
