/**
 * AAA IDP transaction reducer
 * Gateway transaction state
 * @flow
 */

import { DELETE, GATEWAY, TRANSACTION } from '../../actions/types';
import type { Transaction } from '../../types/Types';

type ActionType = {
  type: string,
  value: Transaction,
};

export const initialState = {
  apiSuccess: null,
  createdDateTime: null,
  community_code: null,
  cardToken: null,
  fingerprint: null,
  gateway_url: null,
  loaded: null,
  merchant_id: null,
  paymentAmount: null,
  paymentReference: null,
  receiptNumber: null,
  responseCode: null,
  responseDescription: null,
  summaryCode: null,
  success: null,
  timestamp: null,
  token: null,
  tokenSuccess: null,
};

export default function(state: Transaction = initialState, action: ActionType) {
  switch (action.type) {
    case DELETE:
      return { ...initialState };
    
    case GATEWAY:
      return {
        ...state,
        ...action.value
      };
    
    case TRANSACTION:
      return {
        ...state,
        ...action.value
      };

    default:
      return state;
  }
}
