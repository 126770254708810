/**
 * AAA IDP licence number
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import { acceptDigitalLicence } from '../../actions/index';
import ErrorMessage from '../error-message/ErrorMessage';

import { SITE_CONTENT } from '../../reducers/content/Site.content';
import './AcceptDigitalLicence.scss';

type Props = {
  digitalLicenceTerms: {
    error: boolean,
    required: Boolean,
    valid: Boolean,
    value: boolean
  },
  acceptDigitalLicence: (value: boolean) => *,
  errorMessage: string,
  handleChange: (value: string) => void,
  setAcceptDigitalLicence: (value: boolean) => *,
  acceptDigitalLicenceContent: {
    label: string,
    checked: boolean
  }
};

export class AcceptDigitalLicence extends React.Component<Props> {
  static defaultProps = {
    digitalLicenceTerms: false,
    acceptDigitalLicenceContent: {
      ...SITE_CONTENT.acceptDigitalLicenceContent
    }
  };

  accept = (checked: boolean) => {
    this.props.acceptDigitalLicence(checked);
  };

  render() {
    const { acceptDigitalLicenceContent, digitalLicenceTerms } = this.props;
    let errorMessage = digitalLicenceTerms.error ? 'Digital Licence terms need to be agreed to.' : '';

    return (
      <div className="acceptDigitalLicence">
        <div className="checkbox-container">
          <input
            name="digitalLicenceTerms"
            type="checkbox"
            checked={digitalLicenceTerms.value}
            value="1"
            onChange={e => this.accept(e.target.checked)}
          />

          <label dangerouslySetInnerHTML={{ __html: acceptDigitalLicenceContent.label }} />
        </div>
        <ErrorMessage message={errorMessage} error={digitalLicenceTerms.error} />
      </div>
    );
  }
}

const mapDigitalLicenceTermsStateToProps = ({ digitalLicenceTerms }) => {
  return { digitalLicenceTerms };
};

const mapDigitalLicenceTermsDispatchToProps = dispatch => {
  return {
    acceptDigitalLicence: (value: boolean) => {
      dispatch(acceptDigitalLicence(value));
    }
  };
};

const VisibleAcceptDigitalLicence = connect(mapDigitalLicenceTermsStateToProps, mapDigitalLicenceTermsDispatchToProps)(AcceptDigitalLicence);

export default VisibleAcceptDigitalLicence;
