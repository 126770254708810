/**
 * AAA IDP title
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import { licenceExpiry } from '../../actions/index';
import DateInput from '../date-input/DateInput';
import moment from 'moment';
import './LicenceExpiry.css';

type Props = {
  licenceExpiry: {
    value: string
  },
  setLicenceExpiry: (value: string) => *
};

export class LicenceExpiry extends React.Component<Props> {
  static defaultProps = {
    licenceExpiry: {
      value: ''
    }
  };

  render() {
    const { licenceExpiry, setLicenceExpiry } = this.props;

    return (
      <DateInput
        handleChange={setLicenceExpiry}
        label="Licence Expiry"
        maxFuture={moment().add(10, 'years')}
        name="licenceExpiry"
        onBlur={setLicenceExpiry}
        range="future"
        section="licence-details"
        values={{ licenceExpiry }}
      />
    );
  }
}

const mapStateToProps = ({ licenceExpiry }) => {
  return { licenceExpiry };
};

const mapDispatchToProps = dispatch => {
  return {
    setLicenceExpiry: (value: string) => {
      dispatch(licenceExpiry(value));
    }
  };
};

const VisibleLicenceExpiry = connect(mapStateToProps, mapDispatchToProps)(LicenceExpiry);

export default VisibleLicenceExpiry;
