/**
 * AAA IDP content reducer
 * @flow
 */

import { CONTENT } from '../../actions/types';
import { SITE_CONTENT } from './Site.content';
import type { ActionType, Content } from '../../types/Types';

const initialState = SITE_CONTENT;

export default function(state: Content = initialState, action: ActionType) {
  switch (action.type) {
    case CONTENT:
      return action.value;

    default:
      return state;
  }
}
