/**
 * AAA IDP CTA component for home page
 * @flow
 */
import * as React from 'react';
import './WhatIs.css';

type Props = {
  content: string,
  ctaTitle: string,
  ctaContent: string,
  ctaLinkTitle: string,
  ctaLinkUrl: string
};
type State = {};
class WhatIs extends React.Component<Props, State> {
  static defaultProps = {
    content: 'ipso facto',
    ctaTitle: '<p>This is some HTML content</p>',
    ctaContent: '<p>This is some HTML content</p>',
    ctaLinkTitle: '<p>This is some HTML content</p>',
    ctaLinkUrl: '<p>This is some HTML content</p>'
  };
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    const { content, ctaTitle, ctaContent, ctaLinkTitle, ctaLinkUrl } = this.props;

    return (
      <div className="WhatIs">
        <div className="content" dangerouslySetInnerHTML={{ __html: content }}></div>
        <div className="cta">
          <h3 className="title">{ctaTitle}</h3>
          <div dangerouslySetInnerHTML={{ __html: ctaContent }}></div>

          <a className="button reverse" role="button" href={ctaLinkUrl} target="_blank" rel="noopener noreferrer">
            {ctaLinkTitle}
          </a>
        </div>
      </div>
    );
  }
}

export default WhatIs;
