/**
 * Select
 * @flow
 */
import * as React from 'react';
import ErrorMessage from '../error-message/ErrorMessage';
import Info from '../info/Info';
import type { Addresses, AppState, Contacts, Names } from '../../types/Types';
import './Select.css';

type Props = {
  errorMessage: string,
  info: string,
  label: string,
  handleChange: (value: string) => *,
  name: string,
  onBlur: (value: string) => *,
  options: Array<{ title: string, value: string }>,
  required: boolean,
  section: string,
  values: AppState | Addresses | Contacts | Names
};

class Select extends React.Component<Props> {
  static defaultProps = {
    errorMessage: '',
    info: '',
    label: 'select',
    handleChange: () => null,
    name: 'title',
    onBlur: () => null,
    options: [{ title: '--', value: '0' }],
    required: false,
    section: 'personal-details'
  };

  /**
   * Match state from display or value
   */
  matchState = () => {
    const { name, options, values } = this.props;
    let { display, value } = values[name];

    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      switch (true) {
        case display === option.value:
          return display;

        case value === option.value:
          return value;

        default:
        // no op
      }
    }
  };

  /**
   * Render the options
   */
  renderOptions() {
    const { handleChange, name, onBlur, options, section, values } = this.props;

    if (options.length === 0) {
      return null;
    }
    const id = section ? `${section}_${name}` : name;
    const value = values[name] && values[name].value ? values[name].value : '';

    let i = 0;
    return (
      <select
        id={id}
        name={id}
        onBlur={e => onBlur(e.target.value)}
        onChange={e => handleChange(e.target.value)}
        value={value}
      >
        {options.map(item => {
          return (
            <option key={i++} value={item.value}>
              {item.title}
            </option>
          );
        })}
      </select>
    );
  }

  render() {
    let { errorMessage, info, label, name, options, section, values } = this.props;
    const { error, required, valid, value } = values[name]
      ? values[name]
      : { display: '', error: false, required: false, valid: null, value: '' };
    const id = section ? `${section}_${name}` : name;
    errorMessage = errorMessage === '' ? `${label} is required` : errorMessage;

    if (options.length === 0) {
      return null;
    }

    const classes = [
      'form-element-container',
      name,
      value === '' ? 'default' : '',
      error ? 'error' : '',
      valid === true ? 'valid' : ''
    ];

    return (
      <div className={classes.join(' ').trim()}>
        <label htmlFor={id}>
          {label}
          {required && id !== 'personal-details_autoClub' ? <span className="required">*</span> : ''}
        </label>
        {this.renderOptions()}
        <ErrorMessage message={errorMessage} error={error} />
        {info.length > 0 ? <Info info={info} /> : null}
      </div>
    );
  }
}

export default Select;
