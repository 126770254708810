/**
 * AAA-IDP Loading component
 * @flow
 */
import * as React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './Loading.css';

type Props = {
  text: string
};

const Loading = (props: Props) => {
  const { text } = props;
  return (
    <div className="Loading">
      {text ? <p>{text}</p> : null}
      <FontAwesomeIcon icon="spinner" spin={true} />
    </div>
  );
}

Loading.defaultProps = {
  text: ''
};

export default Loading;