/**
 * Error validation
 * @flow
 */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  errors: number
};

class ErrorValidation extends React.Component<Props> {
  static defaultProps = {
    errors: 0
  };

  render() {
    const { errors } = this.props;

    if (errors === 0) return null;

    return (
      <div className="pre-footer error">
        <FontAwesomeIcon icon="triangle-exclamation" inverse={true} />
        We found {errors} {errors === 1 ? 'error' : 'errors'} in the information provided above.
        <br />
        Please fix the highlighted fields and try again.
      </div>
    );
  }
}

export default ErrorValidation;
