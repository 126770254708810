/**
 * AAA IDP anme reducer
 * Set the state of the user from the home page form
 * @flow
 */
import {
  DELETE,
  FIRST_NAME,
  LAST_NAME,
  DELIVERY_ADDRESS_ADDRESS_FULL_NAME,
  MIDDLE_NAME,
  TITLE,
  VALIDATE
} from '../../actions/types';
import type { ActionType, Names } from '../../types/Types';
import { FIELD_LENGTH } from '../../data/Data';


export const initNames = {
  firstName: {
    error: false,
    required: true,
    valid:  null ,
    value: '',
  },
  fullName: {
    error: false,
    required: true,
    valid:  null ,
    value: '',
  },
  lastName: {
    error: false,
    required: true,
    valid:  null ,
    value: '',
  },
  middleName: {
    error: false,
    required: false,
    valid:  null,
    value: '',
  },
  licenceDeliveryAddressFullName: {
    error: false,
    required: false,
    valid:  null ,
    value: '',
  },
  title: {
    error: false,
    required: false,
    valid:  null ,
    value: '',
  }
};

export default function(state: Names = initNames, action: ActionType) {
  switch (action.type) {
    case DELETE :
      return { ...initNames };

    case FIRST_NAME : {
      let { firstName } = state;
      const { required } = firstName; 
      const { value } = action;
      const empty = value === '' && required ? true : false;
      const long = value.length > FIELD_LENGTH ? true : false;
      const valid = empty || long ? false : true;

      firstName = {
        ...firstName,
        error: !valid,
        valid,
        value
      }
      const newState = {
        ...state,
        firstName
      };
      const fullName = setFullName(newState);
     
      return {
        ...newState,
        fullName,
      }
    }

    case LAST_NAME : {
      let { lastName } = state;
      let { required } = lastName;
      const {value} = action;
      const empty = value === '' && required ? true : false;
      const long = value.length > FIELD_LENGTH ? true : false;
      const valid = empty || long ? false : true;

      lastName = {
        ...lastName,
        error: !valid,
        valid,
        value
      }
      state = {
        ...state,
        lastName
      };
      const fullName = setFullName(state);

      return {
        ...state,
        fullName,
      }
    }

    case MIDDLE_NAME : {
      let { middleName } = state;
      let {required} = middleName;
      const {value} = action;
      const empty = value === '' && required ? true : false;
      const long = value.length > FIELD_LENGTH ? true : false;
      const valid = empty || long ? false : true;

      middleName = {
        ...middleName,
        error: !valid,
        valid,
        value
      }

      state = {
        ...state,
        middleName
      };

      const fullName = setFullName(state);

      return {
        ...state,
        fullName,
      }
    }

    case TITLE : {
      let { title } = state;
      const {required} = title;
      const {value} = action;
      const valid = value === '' && required ? false : true;

      title = {
        ...title,
        error: !valid,
        valid,
        value
      }

      state = {
        ...state,
        title
      };

      const fullName = setFullName(state);

      return {
        ...state,
        fullName,
      }
    }

    case DELIVERY_ADDRESS_ADDRESS_FULL_NAME : {
      let { licenceDeliveryAddressFullName } = state;
      const {required} = licenceDeliveryAddressFullName;
      const {value} = action;
      const error = value === '' && required ? true : false;

      licenceDeliveryAddressFullName = {
        ...licenceDeliveryAddressFullName,
        error,
        valid: !error,
        value
      }

      state = {
        ...state,
        licenceDeliveryAddressFullName
      };

      return state;
    }

    case VALIDATE : {
      let validState = { ...state };

      for (let field in validState) {
        const {required, value} = validState[field];
        const valid = value === '' && required ? false : true;
        validState[field] = {
          ...validState[field],
          error: !valid,
          valid,
        };
      }
      return validState;
    }

    default:
      return state;
  }
}

/**
 * Set full name from state
 */
function setFullName(state) {
  const {title, firstName, fullName, lastName, middleName} = state;
  const middleNamePrint = middleName.value ? `${middleName.value} `: '';
  let value = `${title.value} ${firstName.value} ${middleNamePrint}${lastName.value}`;
  value = value.trim();
  const error = value.length > 0 ? false : true;

  return {
    ...fullName,
    error,
    valid: error ? false : true,
    value
  }
}
