/**
 * AAA IDP licence number
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import { licenceClass } from '../../actions/index';
import Select from '../select/Select';
import { LICENCE_CLASS } from '../../data/Data';
import type { Field } from '../../types/Types';
import './LicenceClass.css';

const LICENCE_CLASS_INFO =
  'Select the highest motor vehicle or motorcycle licence class currently held – this will be reflected on your permit. Learner and restricted licence holders cannot apply for a permit. <a href="https://www.aaa.asn.au/IDP-exceptions" target="_blank">View all licence restrictions (PDF, 116 KB).</a>';

type Props = {
  licenceClass: Field,
  setLicenceClass: (value: string) => *
};

export class LicenceClass extends React.Component<Props> {
  static defaultProps = {
    licenceClass: {
      error: false,
      required: true,
      valid: null,
      value: ''
    }
  };

  render() {
    const { licenceClass, setLicenceClass } = this.props;

    return (
      <Select
        label="Licence class"
        options={LICENCE_CLASS}
        name="licenceClass"
        info={LICENCE_CLASS_INFO}
        section="licence-details"
        handleChange={setLicenceClass}
        onBlur={setLicenceClass}
        // $FlowFixMe
        values={{ licenceClass }}
      />
    );
  }
}

const mapStateToProps = ({ licenceClass }) => {
  return { licenceClass };
};

const mapDispatchToProps = dispatch => {
  return {
    setLicenceClass: (value: string) => {
      dispatch(licenceClass(value));
    }
  };
};

const VisibleLicenceClass = connect(mapStateToProps, mapDispatchToProps)(LicenceClass);

export default VisibleLicenceClass;
