/**
 * credit card reducer
 * @flow
 */
import { CARD_HOLDER, CREDIT_CARD, CVV, EXPIRY_MONTH, EXPIRY_YEAR } from '../../actions/types';
import type { ActionType, Field } from '../../types/Types';
import { CC_RE, EXPIRY_RE } from '../../data/Data';
import moment from 'moment';

type InitialState = {
  card_holder: Field,
  credit_card: Field,
  cvv: Field,
  expiry_month: Field,
  expiry_year: Field
}

export const initialState = {
  card_holder: {
    error: false,
    required: true,
    value: '',
    valid: null,
  },
  credit_card: {
    display: '',
    error: false,
    required: true,
    value: '',
    valid: null,
  },
  cvv: {
    error: false,
    required: true,
    value: '',
    valid: null,
  },
  expiry_month: {
    error: false,
    required: true,
    value: '',
    valid: null,
  },
  expiry_year: {
    error: false,
    required: true,
    value: '',
    valid: null,
  }
}

const dateOfMonth = moment().date();

export default function(state: InitialState = initialState, action: ActionType) {
  switch(action.type) {
    case CARD_HOLDER: {
      const field = 'card_holder';
      const { value } = action;
      const { required } = state[field];
      const error = value === '' && required ? true : false;
      const newState = {
        ...state,
        [field]: {
          error,
          required,
          value,
          valid: !error,
        }
      }
      return newState;
    }

    case CREDIT_CARD: {
      const field = 'credit_card';
      let { value } = action;
      // remove non-numerical chars
      value = value.replace(/[^0-9]+/g, '');
      const parts = [
        value.substr(0,4),
        value.substr(4,4),
        value.substr(8,4),
        value.substr(12,4),
      ];
      const display = parts.join(' ').trim();
      const { required } = state[field];
      const error = !CC_RE.test(value);

      return {
        ...state,
        [field]: {
          display,
          error,
          required,
          value,
          valid: !error,
        }
      }
    }

    case CVV: {
      const field = 'cvv';
      let { value } = action;
      value = value.replace(/[^0-9]+/g, '').trim();
      const { required } = state[field];
      let error = true;

      switch (true) {
        case (state.credit_card.value.length === 15):
          error = new RegExp(/^[0-9]{4}$/).test(value) ? false: true;
          break;

        default:
          error = new RegExp(/^[0-9]{3}$/).test(value) ? false: true;
      }

      return {
        ...state,
        [field]: {
          error,
          required,
          value,
          valid: !error,
        }
      }
    }

    case EXPIRY_MONTH: {
      let { value } = action;
      value = value.replace(/[^0-9]+/g, '').trim();
      const { required } = state.expiry_month;
      let year = typeof(state.expiry_year.value) === 'string' ? state.expiry_year.value : '';
      const date = moment(new Date(parseInt(`20${year}`), parseInt(value) - 1, dateOfMonth)).endOf('month').hour(23).minute(59).second(59);
      const startOfMonth = moment().startOf('month').hour(0).minute(0).second(0);
      // console.warn('endOfMonth, date', endOfMonth, date)
      const error = !EXPIRY_RE.test(value) || date.isBefore(startOfMonth) ? true : false;
      const yearError = !EXPIRY_RE.test(year) || date.isBefore(startOfMonth) ? true : false;

      return {
        ...state,
        expiry_month: {
          error,
          required,
          value,
          valid: !error,
        },
        expiry_year: {
          ...state.expiry_year,
          error: yearError,
          valid: !yearError
        }
      }
    }

    case EXPIRY_YEAR: {
      let { value } = action;
      value = value.replace(/[^0-9]+/g, '').trim();
      const { required } = state.expiry_year;
      let month = typeof(state.expiry_month.value) === 'string' ? state.expiry_month.value : '';
      const date = moment(new Date(parseInt(`20${value}`), parseInt(month) - 1, dateOfMonth)).endOf('month').hour(23).minute(59).second(59);
      const startOfMonth = moment().startOf('month').hour(0).minute(0).second(0);
      const error = !EXPIRY_RE.test(value) || date.isBefore(startOfMonth) ? true : false;
      const monthError = !EXPIRY_RE.test(month) || date.isBefore(startOfMonth) ? true : false;
      // console.warn('date, startOfMonth, date.isBefore(startOfMonth)', date, startOfMonth, date.isBefore(startOfMonth))

      return {
        ...state,
        expiry_month: {
          ...state.expiry_month,
          error: monthError,
          valid: !monthError
        },
        expiry_year: {
          error,
          required,
          value,
          valid: !error,
        }
      }
    }

    default:
      return state;
  }
}