/**
 * AAA IDP page footer component
 * @flow
 */
import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { FOOTER } from './Footer.content';
import { connect } from 'react-redux';
import './Footer.css';

type Props = {
  cookies: boolean
};

export class Footer extends React.Component<Props> {
  static defaultProps = {
    cookies: false
  };

  render() {
    const { cookies } = this.props;
    const { copyright, links } = FOOTER;
    const { pathname } = window.location;
    const path = pathname.substr(1);
    const copyright_notice = path === 'confirmation' ? copyright.confirmation : copyright.default;
    
    return (
      <footer className={`${!cookies ? 'has-cookies' : ''} ${path}`}>
        <div className="inner">
          <div className="logo">
            <img src={'/images/logo-aaa2.png'} alt="" />
          </div>
          <div className="copyright">{copyright_notice}</div>
          <ul className="footer-links">
            {links.map((item, index) => {
              const { link, text } = item;

              return (
                <li key={index}>
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    {text}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = cookies => {
  return cookies;
};

const VisibleFooter = connect(mapStateToProps)(Footer);

export default withRouter(VisibleFooter);
