/**
 * AAA IDP Licence Address
 * @flow
 */
import React from 'react';
import AddressFinder from '../address-finder/AddressFinder';
import AddressComponents from '../address-components/AddressComponents';
import ToggleAddressFields from '../toggle-address-fields/ToggleAddressFields';
import './LicenceAddress.css';
type Props = {
  // bugsnagClient: {notify: (Error) => *},
};
const LABEL = 'Address on licence';
const NAME = 'licenceAddress';
const SECTION = 'licence-details';

export class LicenceAddress extends React.Component<Props> {
  render() {
    return (
      <div className="LicenceAddress">
        <AddressFinder {...this.props} label={LABEL} name={NAME} section={SECTION} />
        <AddressComponents {...this.props} name={NAME} section={SECTION} />
        <ToggleAddressFields name={NAME} />
      </div>
    );
  }
}

export default LicenceAddress;
