/**
 * AAA IDP Form - Your Details
 * @flow
 */
import * as React from 'react';
import AutoClub from '../../../components/auto-club/AutoClub';
import Name from '../../../components/name/Name';
import ContactNumber from '../../../components/contact-number/ContactNumber';
import DateOfBirth from '../../../components/date-of-birth/DateOfBirth';
import DateOfTravel from '../../../components/date-of-travel/DateOfTravel';
import Email from '../../../components/email/Email';
import PlaceOfBirth from '../../../components/place-of-birth/PlaceOfBirth';
import PhotoUpload from '../../../components/photo-upload/PhotoUpload';
import PhotoGuidelines from '../../../components/photo-guidelines/PhotoGuidelines';
import Info from '../../../components/info/Info';
import type { Bugsnag } from '@bugsnag/js';
import './PersonalDetails.css';

type Props = {
  bugsnagClient: Bugsnag
};

export class PersonalDetails extends React.Component<Props> {
  render() {
    const { bugsnagClient } = this.props;

    return (
      <section id="personal-details">
        <h2>
          <span>Your details</span>
          <div>* Required</div>
        </h2>

        <DateOfTravel />
        <Name />
        <DateOfBirth />
        <PlaceOfBirth />
        <Email />
        <ContactNumber label="contact number" name="contactNumber" section="personal-details" />
        <AutoClub />

        <h3 className="border">Upload a recent photo</h3>
        <PhotoGuidelines />
        <div className="photo-uploads">
          <PhotoUpload bugsnagClient={bugsnagClient} name={'recentPhoto'} />
          <div className="spacer"></div>
          <div className="spacer"></div>
          <Info
            info="Check that your photo clearly meets all the requirements listed above or your application may be rejected."
            icon="id-card"
          />
        </div>
      </section>
    );
  }
}

export default PersonalDetails;
