/**
 * iFrame payment form
 * @flow
 */
import React from 'react';
import ErrorMessage from '../../../components/error-message/ErrorMessage';
import Loading from '../../../components/loading/Loading';
import { COSTS } from '../../../data/Data';
import type { Application, Gateway, FatZebraResult, Shipping, Transaction } from '../../../types/Types';
import type { Bugsnag } from '@bugsnag/js';

type Props = {
  application: Application,
  bugsnagClient: Bugsnag,
  gateway: Gateway,
  jest: boolean,
  postage: Shipping,
  transaction: Transaction
};

type State = {
  gatewayError: boolean,
  loadCount: number,
  redirect: boolean,
  results: FatZebraResult | null,
  show: boolean,
  status: string
};

class IPSIframe extends React.Component<Props, State> {
  timeoutId: TimeoutID;

  static defaultProps = {
    application: {
      club: {
        abbreviation: 'RACV'
      },
      id: '1500',
      referrer: '',
      submission_token: 'f23re5et'
    },
    bugsnagClient: {
      notify: () => null
    },
    gateway: {
      name: '',
      method: '',
      redirectUrl: ''
    },
    postage: {
      description: 'Express Post within Australia',
      cost: 11.40,
      value: 'express_post'
    },
    transaction: {
      community_code: null,
      gateway_url: null,
      paymentAmount: null,
      paymentReference: null,
      receiptNumber: null,
      responseCode: null,
      responseDescription: null,
      summaryCode: null,
      success: false,
      token: null,
      tokenSuccess: null
    },
    jest: false
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      gatewayError: false,
      loadCount: 0,
      redirect: false,
      results: null,
      show: false,
      status: ''
    };
  }

  componentDidMount() {
    window.addEventListener('message', this.listenPostMessage);
    this.timeoutId = setTimeout(this.showIframe, 2000);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
    window.removeEventListener('message', this.listenPostMessage);
  }

  /**
   * show iFrame
   */
  showIframe = () => {
    this.setState({
      show: true
    });
  }

  /**
   * Capture iFrame onLoad event
   */
  onIpsiIframeLoad = () => {
    const { loadCount } = this.state;

    if (loadCount >= 1) {
      // reload window because IPSI implementation is a steaming pile of dingos kidneys
      window.location.reload();
    }

    this.setState({
      loadCount: loadCount + 1
    });
  };

  /**
   * Payment error on timeout of request to IPSI gateway by iFrame
   */
  paymentTimeout = () => {
    const { bugsnagClient, application } = this.props;
    console.warn('IPSI TIMEOUT payment gateway error props', this.props);
    console.warn('IPSI TIMEOUT payment gateway error state', this.state);
    const err = new Error(`IPSI payment gateway TIMEOUT error for applicationId: ' + ${application.id}`);
    bugsnagClient.notify(err);
    this.setState({
      gatewayError: true
    });
  };

  /**
   * listenPostMessage
   * listen to post messages passed from the embedded iFrame
   */
  listenPostMessage = (e: MessageEvent) => {
    const { bugsnagClient, application } = this.props;
    const { data, origin } = e;

    if (/enterprisesecure\.com\.au/.test(origin) && typeof data === 'string') {
      const messageData = JSON.parse(data);

      // Errors
      if (messageData['data'] !== undefined && messageData.data['errors'] !== undefined) {
        const err = new Error(
          'IPSI payment gateway error for applicationId: ' + application.id + '. Response data: ' + data
        );
        bugsnagClient.notify(err);
        this.setState({
          gatewayError: true
        });
        return null;
      }

      // Parse other messages
      switch (true) {
        case messageData.info && messageData.info === 'iframe content for payment page is loading.':
          this.setState({
            status: 'loading'
          });
          break;

        case messageData.info && messageData.info === 'The payment form has rendered.':
          this.setState({
            status: 'loaded',
            show: true
          });
          break;

        case messageData.info && messageData.info === 'The confirmation form has rendered.':
          this.setState({
            status: 'confirmation'
          });
          break;

        case messageData.info && messageData.info === 'The confirmation form has been submitted.':
          this.setState({
            status: 'submitted'
          });
          this.timeoutId = setTimeout(this.paymentTimeout, 10000);
          break;

        /*
          Successful transaction data structure example:
          {
            "transactionDate": "2023-10-20T05:59:03Z",
            "browser": "chrome",
            "os": "macosx",
            "currency": "$",
            "body": "Approved",
            "os_version": "10.15",
            "ecm": "31",
            "success": "true",
            "requestorIp": "172.16.11.176",
            "info": "Payment outcome for the transaction.",
            "maskedPAN": "424242XXXXXX4242",
            "cardExpiryDate": "01/25",
            "responseCode": "00",
            "name": "test",
            "txnReference": "123506f0e19c909",
            "merchantReference": "10640-1697781500",
            "cardSchema": "VISA",
            "finalAmount": "60.00",
            "cardCategory": "CREDIT",
            "browser_engine": "webkit",
            "settlementDate": "2023-10-20T07:00:00Z",
            "surcharge": "null",
            "header": "Approved",
            "iframeName": "2d8f79819c84da18c51eaa00049014a84619168ee10aa8305415cd0ea0423aeb"
          }
        */
        case messageData.info && messageData.info === 'Payment outcome for the transaction.': {
          clearTimeout(this.timeoutId);
          const { body, finalAmount, merchantReference, responseCode, success, txnReference } = messageData;
          this.setState({
            results: {
              amount: finalAmount,
              message: body,
              responseCode: responseCode,
              receipt: txnReference,
              reference: merchantReference,
              successful: success === 'true' ? true : false,
              status: 'paymentOutcome'
            },
            redirect: true
          });
          break;
        }

        default:
        // no op
      }
    }
  };

  render() {
    const { application, gateway, postage, transaction } = this.props;
    const { gatewayError, redirect, results, show } = this.state;
    const { gateway_url, token } = transaction;
    const { params, redirectUrl } = gateway;

    if (!gateway_url || !params || !token) {
      return null;
    }

    const src = `${gateway_url}?${params}&verifyMessage=${token}`;

    if (!show) {
      return <Loading />;
    }

    if (redirect && results) {
      const { amount, message, reference, responseCode, successful } = results;
      const search = new URLSearchParams();
      search.set('id', application.id);
      search.set('submission_token', application.submission_token);
      search.set(
        'amount',
        amount && typeof amount === 'string' ? amount : `${(postage.cost + COSTS.permit).toFixed(0)}`
      );
      search.set('reference', reference && typeof reference === 'string' ? reference : 'Error');
      search.set('responseCode', responseCode && typeof responseCode === 'string' ? responseCode : '');
      search.set('message', message && typeof message === 'string' ? message : 'Error');
      search.set('receipt', reference && typeof reference === 'string' ? reference : '');
      search.set('successful', successful ? 'true' : 'false');
      search.set('postage', postage.description ? postage.description : 'Express Post within Australia');

      window.location = `${redirectUrl}?${search.toString()}`;
      return null;
    }

    return (
      <>
        <ErrorMessage error={gatewayError} message={'Payment gateway error'} />
        <iframe src={src} title="Payment iFrame" onLoad={this.onIpsiIframeLoad} />
      </>
    );
  }
}

export default IPSIframe;
