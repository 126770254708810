/**
 * AAA IDP Error App component
 * 
 * This component shows on the Details page if there is no Application ID in the store
 * It also shows if the user cancels their application, so we load a spinner in this case.
 * 
 * We use a time to show the component, and dispatch and error to Bugsnag
 *  
 * @flow
 */
import * as React from 'react';
import Loading from '../loading/Loading';
import { Redirect } from 'react-router-dom';
import type { Bugsnag } from '@bugsnag/js';
import './ErrorApp.css';

type Props = {
  bugsnagClient: Bugsnag,
};

type State = {
  show: boolean,
}

export class ErrorApp extends React.Component<Props, State>  {
  timeoutID: TimeoutID;

  constructor(props: Props) {
    super(props);

    this.state = {
      show: false
    }

    this.timeoutID = this.timer();
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID);
  }

  timer = () => setTimeout(this.error, 1000);

  error = () => {
    this.setState({
      show: true
    });
  }

  render() {
    if (!this.state.show) {
      return <Loading />;
    }

    return <Redirect to="/" />
  }
}

export default ErrorApp;
