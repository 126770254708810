/**
 * AAA IDP Form - Info component
 * @flow
 */

import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Info.css';

type Props = {
  info: string,
  icon: string,
  framed: boolean
};

class Info extends React.Component<Props> {
  static defaultProps = {
    info: '',
    icon: '',
    framed: false
  };

  render() {
    if (this.props.info.length === 0) {
      return null;
    }
    const frame_class = this.props.framed ? 'framed' : '';
    return (
      <div className={'info ' + frame_class}>
        {this.props.icon.length > 0 && <FontAwesomeIcon icon={this.props.icon} />}
        <p dangerouslySetInnerHTML={{ __html: this.props.info }} />
      </div>
    );
  }
}

export default Info;
