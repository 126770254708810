/**
 * AAA IDP Modal
 * @flow
 */
import React, { Component } from 'react';
import './Modal.css';

type Props = {
  name: string,
  next: (e: SyntheticEvent<>) => *,
  show: boolean
};

type State = {
  focus: boolean,
  infoWidth: number,
  width: number
};

class Modal extends Component<Props, State> {
  // $FlowFixMe
  okRef: React.RefObject;

  static defaultProps = {
    name: 'RACV',
    next: () => null,
    show: false
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      focus: false,
      infoWidth: 250,
      width: window.innerWidth
    };

    this.okRef = React.createRef();
  }

  componentDidMount() {
    // $FlowFixMe
    document.addEventListener('keydown', this.handleKeydown);
    window.addEventListener('resize', this.handleResize);

    setTimeout(this.focus, 500);
  }

  componentWillUnmount() {
    // $FlowFixMe
    document.removeEventListener('keydown', this.handleKeydown);
    window.removeEventListener('resize', this.handleResize);
  }

  /**
   * handleKeydown
   */
  handleKeydown = (e: SyntheticKeyboardEvent<>) => {
    // $FlowFixMe
    const { key } = e;
    const { next } = this.props;
    const { focus } = this.state;

    if (focus && key === 'Enter') {
      next(e);
    }
  };

  /**
   * onFocus
   */
  onFocus = () => {
    this.setState({
      focus: true
    });
  };

  /**
   * onBlur
   */
  onBlur = () => {
    this.setState({
      focus: false
    });
  };

  focus = () => {
    if (this.okRef && this.okRef.current) {
      console.warn('set focus OK');
      this.okRef.current.focus();
    }
  };

  handleResize = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  render() {
    const { name, next, show } = this.props;
    const { width } = this.state;

    if (!show) {
      return null;
    }

    return (
      <div className="Modal" style={{ width }}>
        <div className="info">
          <div>You will now be transferred to {name} for processing of your IDP on behalf of the AAA</div>
          <div
            ref={this.okRef}
            className="button"
            onClick={next}
            tabIndex="0"
            role="button"
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          >
            OK
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
