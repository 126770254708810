/**
 * Login
 * @flow
 */
import React, { Component } from 'react';
import { apiUrl } from '../../data/Data';
import Loading from '../../components/loading/Loading';

type Props = {};

class Login extends Component<Props> {
  constructor(props: Props) {
    super(props);

    const loginUrl = apiUrl(window.location.hostname, false);
    window.location = `${loginUrl}/login`;
  }

  render() {
    return <Loading />
  }
}

export default Login;