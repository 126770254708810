/**
 * Spinner 
 * @flow
 */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Spinner.css';

type Props = {
  spinner: boolean,
  text: string,
};

const Spinner = (props: Props) => {
  const {spinner, text} = props;

  if (!spinner) return null;

  return (
    <div className="spinner">
      <div className="inner">
        <FontAwesomeIcon icon={'spinner'} inverse={true} className="fa-spin" />
        <span className="text">{text}</span>
      </div>
    </div>
  );
}

Spinner.defaultProps = {
  spinner: false,
  text: 'Loading'
};

export default Spinner;
