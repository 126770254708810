/**
 * AAA IDP LicenceScans component
 * @flow
 */
import React from 'react';
import { IMAGE_PATH_PREFIX } from '../../data/Data';
import './LicenceScans.css';
export default function LicenceScans() {
  return (
    <div className="LicenceScans">
      <p>
        As part of the application we require you to upload a scan or photo of the front and back of your current
        Australian driver licence.
      </p>
      <div className="examples">
        <div>
          <img className="licence-sample" src={`${IMAGE_PATH_PREFIX}licence-front.png`} alt="Sample licence front" />
          <p>
            Licence front<sup>*</sup>
          </p>
        </div>
        <div>
          <img className="licence-sample" src={`${IMAGE_PATH_PREFIX}licence-back.png`} alt="Sample licence front" />
          <p>
            Licence back<sup>*</sup>
          </p>
        </div>
      </div>
      <p className="title">Photo requirements: </p>
      <ul>
        <li>Must be a clear and readable scan of the front and back of the licence.</li>
      </ul>
    </div>
  );
}
