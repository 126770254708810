/**
 * AAA IDP title
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import { placeOfBirth } from '../../actions/index';
import Select from '../select/Select';
import { COUNTRIES } from '../../data/Data';
import type { Field } from '../../types/Types';
import './PlaceOfBirth.css';

type Props = {
  placeOfBirth: Field,
  setPlaceOfBirth: (value: string) => *
};

export class PlaceOfBirth extends React.Component<Props> {
  static defaultProps = {
    placeOfBirth: {
      value: ''
    }
  };

  render() {
    const { placeOfBirth, setPlaceOfBirth } = this.props;

    return (
      <Select
        handleChange={setPlaceOfBirth}
        label="Country of birth"
        options={COUNTRIES}
        onBlur={setPlaceOfBirth}
        name="placeOfBirth"
        section="personal-details"
        // $FlowFixMe
        values={{ placeOfBirth }}
      />
    );
  }
}

const mapStateToProps = ({ placeOfBirth }) => {
  return { placeOfBirth };
};

const mapDispatchToProps = dispatch => {
  return {
    setPlaceOfBirth: (value: string) => {
      dispatch(placeOfBirth(value));
    }
  };
};

const VisiblePlaceOfBirth = connect(mapStateToProps, mapDispatchToProps)(PlaceOfBirth);

export default VisiblePlaceOfBirth;
