/**
 * AAA IDP App
 *
 *@flow
 */
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Cookies from './components/cookies/Cookies';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import ErrorBoundary from './components/error-boundary/ErrorBoundary';
import Routes from './routes/Routes';
import Loading from './components/loading/Loading';
import { Provider } from 'react-redux';
import initStore from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { clubDomain, gtmForClubEnv, GA, IS_PROD } from './data/Data';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import type { Bugsnag } from '@bugsnag/js';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faXmark,
  faCheckSquare,
  faChevronLeft,
  faChevronRight,
  faCropSimple,
  faTriangleExclamation,
  faImage,
  faImages,
  faCircleInfo,
  faPencilAlt,
  faPencil,
  faRedo,
  faSave,
  faSearch,
  faSpinner,
  faEnvelope,
  faGlobe,
  faRotateLeft,
  faPrint,
  faIdCard,
  faCircleQuestion,
  faCircleExclamation
} from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import './App.css';

library.add(
  faCheck,
  faXmark,
  faCheckSquare,
  faChevronLeft,
  faChevronRight,
  faCropSimple,
  faTriangleExclamation,
  faImage,
  faImages,
  faCircleInfo,
  faPencilAlt,
  faPencil,
  faRedo,
  faSave,
  faSearch,
  faSpinner,
  faSquare,
  faEnvelope,
  faGlobe,
  faRotateLeft,
  faPrint,
  faIdCard,
  faCircleQuestion,
  faCircleExclamation
);

const { store, persistor } = initStore();

type Props = {
  bugsnagClient: Bugsnag,
  jest: boolean
};

// Google Tag Manager
const { hostname } = window.location;
const club = clubDomain(hostname);
const tags: Array<string> = gtmForClubEnv(hostname);

for (const gtmId of tags) {
  TagManager.initialize({ gtmId });
}

// Google Analytics
const ga = GA && GA[club] ? GA[club] : null;

if (ga && IS_PROD) {
  ReactGA.initialize(ga);
}

class App extends React.Component<Props> {
  // $FlowFixMe
  app: React.RefObject;

  static defaultProps = {
    bugsnagClient: {
      notify: (): null => null
    },
    jest: false
  };

  constructor(props: Props) {
    super(props);

    this.app = React.createRef();
  }

  render() {
    return (
      <ErrorBoundary>
        <Provider store={store}>
          <PersistGate loading={<Loading />} persistor={persistor}>
            <BrowserRouter>
              <div className="App" ref={this.app}>
                <Header />
                <Routes {...this.props} />
                <Footer />
                <Cookies />
                {/* RACV Adobe analytics */}
                {club === 'RACV' ? <script>_satellite.pageBottom();</script> : null}
              </div>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default App;
