/**
 * AAA IDP application reducer
 * @flow
 */
import { APPLICATION_START, DELETE } from '../../actions/types';
import type { Application } from '../../types/Types';

type ActionType = {
  type: String,
  value: Application
}

export const initialState = {
  club: {
    abbreviation: '',
    id: '',
    name: '',
  },
  created_at: '',
  id: '',
  submission_token: '',
  referrer : '',
};

export default function(state: Application = initialState, action: ActionType) {
  switch (action.type) {
    case APPLICATION_START:
      return {
        ...state,
        ...action.value
      };

    case DELETE: 
      return {...initialState};
    
    default:
      return state;
  }
}
