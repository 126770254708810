import { createStore } from '@reduxjs/toolkit';
import reducer from '../reducers';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export default (initialState = {}) => {
  let config = {
    key: 'root',
    storage,
    serialize: true,
    blacklist: ['creditCard', 'content']
  };
  let configureReducer = persistReducer(config, reducer);
  const store = createStore(configureReducer, initialState);

  let persistor = persistStore(store);
  return { config, store, persistor };
};
