/**
 * AAA IDP ToggleAddressFields
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import { addressFieldsToggle, addressDeliveryFieldsToggle } from '../../actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ToggleAddressFields.css';
type Props = {
  name: string,
  addresses: {
    addressFieldsToggle: boolean,
    addressDeliveryFieldsToggle: boolean,
    licenceDelivery: string
  },
  addressFieldsToggle: (value: boolean) => *,
  addressDeliveryFieldsToggle: (value: boolean) => *
};

type State = {
  focus: boolean
};

export class ToggleAddressFields extends React.Component<Props, State> {
  static defaultProps = {
    name: 'licenceAddress',
    addresses: {
      addressFieldsToggle: false,
      licenceDelivery: 'Australian'
    }
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      focus: false
    };
  }

  componentDidMount() {
    // $FlowFixMe
    document.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount() {
    // $FlowFixMe
    document.removeEventListener('keydown', this.handleKeydown);
  }

  /**
   * handleKeydown
   */
  handleKeydown = (e: SyntheticKeyboardEvent<>) => {
    // $FlowFixMe
    const { key } = e;
    const { focus } = this.state;

    if (focus && key === 'Enter') {
      this.toggle();
    }
  };

  /**
   * Toggle the address components
   */
  toggle = () => {
    const { addresses, name, addressFieldsToggle, addressDeliveryFieldsToggle } = this.props;

    switch (name) {
      case 'licenceDeliveryAddress':
        addressDeliveryFieldsToggle(!addresses.addressDeliveryFieldsToggle);
        break;

      default:
        addressFieldsToggle(!addresses.addressFieldsToggle);
    }
  };

  /**
   * toggle focus
   */
  focus = () => {
    this.setState({
      focus: !this.state.focus
    });
  };

  render() {
    const { addresses, name } = this.props;
    const { addressFieldsToggle, addressDeliveryFieldsToggle, licenceDelivery } = addresses;

    const toggle =
      (name === 'licenceAddress' && addressFieldsToggle) ||
      (name === 'licenceDeliveryAddress' && addressDeliveryFieldsToggle);
    const content = toggle ? (
      <span>
        Try <span className="link">searching for your address</span> instead.
      </span>
    ) : (
      <span>
        If your address does not appear, or is incorrect, <span className="link">you can enter it manually.</span>
      </span>
    );

    return licenceDelivery === 'International' && name === 'licenceDeliveryAddress' ? null : (
      <div
        className="address-toggle"
        onClick={this.toggle}
        tabIndex="0"
        onFocus={this.focus}
        onBlur={this.focus}
        role="button"
      >
        <FontAwesomeIcon icon={toggle ? 'search' : 'pencil'} className="left" />
        {content}
      </div>
    );
  }
}

const mapStateToProps = ({ addresses }) => {
  return { addresses };
};

const mapDispatchToProps = dispatch => {
  return {
    addressFieldsToggle: (value: boolean) => {
      dispatch(addressFieldsToggle(value));
    },
    addressDeliveryFieldsToggle: (value: boolean) => {
      dispatch(addressDeliveryFieldsToggle(value));
    }
  };
};

const VisibleToggleAddressFields = connect(mapStateToProps, mapDispatchToProps)(ToggleAddressFields);

export default VisibleToggleAddressFields;
