/**
 * Autoclub reducer
 * @flow
 */
import { AUTO_CLUB, DELETE, MEMBERSHIP_NUMBER, VALIDATE } from '../../actions/types';
import type { ActionType, AutoClub } from '../../types/Types';
import { FIELD_LENGTH } from '../../data/Data';

export const initAutoClub = {
  autoClub: {
    error: false,
    required: true,
    valid: null,
    value: ''
  },
  membershipNumber: {
    error: false,
    required: false,
    valid: null,
    value: ''
  }
};

export default function(state: AutoClub = initAutoClub, action: ActionType) {
  switch (action.type) {
    case AUTO_CLUB: {
      const { required } = state.autoClub;
      const { value } = action;
      const empty = value === '' && required ? true : false;
      const long = value.length > FIELD_LENGTH ? true : false;
      const valid = empty || long ? false : true;

      return {
        ...state,
        autoClub: {
          error: !valid,
          required,
          valid,
          value
        }
      };
    }

    case DELETE: {
      const cleanState = { ...initAutoClub };
      return cleanState;
    }

    case MEMBERSHIP_NUMBER: {
      const { required } = state.membershipNumber;
      const { value } = action;
      const empty = value === '' && required ? true : false;
      const long = value.length > FIELD_LENGTH ? true : false;
      const valid = empty || long ? false : true;

      return {
        ...state,
        membershipNumber: {
          error: !valid,
          required,
          valid,
          value
        }
      };
    }

    case VALIDATE: {
      const { required, value } = state.autoClub;
      const empty = value === '' && required ? true : false;
      const long = value.length > FIELD_LENGTH ? true : false;
      const valid = empty || long ? false : true;

      return {
        ...state,
        autoClub: {
          error: !valid,
          required,
          valid,
          value
        }
      };
    }

    default:
      return state;
  }
}
