/**
 * AAAA IDP Payment page
 * @flow
 */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import AlreadyPaid from '../../components/already-paid/AlreadyPaid';
import ErrorApp from '../../components/error-app/ErrorApp';
import Progress from '../../components/progress/Progress';
import PaymentText from '../../components/payment-text/PaymentText';
import Footer from './footer/Footer';
import CountdownTimer from './countdown-timer/CountdownTimer';
import Loading from '../../components/loading/Loading';
import { transaction, validate } from '../../actions';
import { countErrors, logPageView } from '../../functions/Functions';
import type { AppState, Application, Transaction } from  '../../types/Types';
import type { Bugsnag } from '@bugsnag/js';
import './PaymentPage.css';

type Props = {
  ...AppState,
  application: Application,
  bugsnagClient: Bugsnag,
  cookies: boolean,
  jest: boolean,
  digitalLicenceTerms: { value: boolean },
  terms: { value: boolean },
  transaction: Transaction,
  setTransaction: (value: Transaction) => *,
  validate: () => *
};

type State = {
  errors: number,
  expired: boolean,
  show: boolean
};

export class PaymentPage extends React.Component<Props, State> {
  timeoutID: TimeoutID;

  static defaultProps = {
    application: {
      club: {
        abbreviation: '',
      },
      id: '',
      submission_token: ''
    },
    jest: false,
    setTransaction: () => null,
    transaction: {
      success: false,
    },
    validate: () => null
  };

  constructor(props: Props) {
    super(props);

    const { jest, setTransaction } = props;

    // reset this in case of retried payment
    setTransaction({ apiSuccess: false });

    this.state = {
      errors: 0,
      expired: false,
      show: false
    };

    // scroll to top
    if (!jest) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  componentDidMount() {
    this.props.validate();
    this.timeoutID = this.timer();
    const { application: { id, club: { abbreviation }}, bugsnagClient } = this.props;
    logPageView(bugsnagClient, id, abbreviation,'Payment');
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutID)
  }

  /**
   * check errors
   * @returns 
   */
  checkErrors = () => {
    const errors = countErrors(this.props);
    const show = errors === 0 ? true : false;

    this.setState({
      errors,
      show
    });
  }

  /**
   * Timer to avoid memory leaks.
   * We delay showing the main payment components until we validate data
   */
  timer = () => {
    return setTimeout(this.checkErrors, 1000);
  }

  /**
   * Set expired page
   */
  setPageExpired = () => {
    this.setState({
      expired: true
    });
  }

  /**
   * Reload the page
   */
   reload = (e?: SyntheticMouseEvent<HTMLDivElement>) => {
    if (e) {
      e.preventDefault();
    }

    window.location.reload();
  }

  render() {
    const { application, bugsnagClient, transaction } = this.props;
    const { errors, expired, show } = this.state;
    const { id, submission_token } = application;
    const { apiSuccess, success } = transaction;

    switch(true) {
      case (!id || !submission_token) && show:
        return <ErrorApp bugsnagClient={bugsnagClient} />;

      case errors > 0:
        return <Redirect to="/details" />;

      case apiSuccess && success:
        return (
          <Fragment>
            <Progress />
            <section className="form-container">
              <div className="PaymentPage">
                <AlreadyPaid />
              </div>
            </section>
          </Fragment>
        );
      
      case show:
        return (
          <Fragment>
            <Progress />
            <section className="form-container">
              <CountdownTimer
                expired={expired}
                setPageExpired={this.setPageExpired}
                reload={this.reload}
              />
              <div className="PaymentPage">
                <PaymentText />
                <Footer {...this.props} />
                {expired ? (
                  <div className="expired" onClick={this.reload}>
                    <div className="text">
                      Click to reload the page to continue your transaction
                    </div>
                  </div>
                )
                : null}
              </div>
            </section>
          </Fragment>
        );

      default:
        return (
          <Fragment>
            <Progress />
            <section className="form-container">
              <div className="PaymentPage">
                <PaymentText />
                <Loading />
              </div>
            </section>
          </Fragment>
        );
    }    
  }
}

const mapStateToProps = ({
  addresses,
  application,
  club,
  contacts,
  cookies,
  dateOfBirth,
  dateOfTravel,
  digitalLicenceTerms,
  email,
  images,
  names,
  licenceClass,
  licenceExpiry,
  licenceNumber,
  placeOfBirth,
  postage,
  stateOfIssue,
  transaction,
  wearGlasses,
  terms
}) => {
  return {
    addresses,
    application,
    club,
    contacts,
    cookies,
    dateOfBirth,
    dateOfTravel,
    digitalLicenceTerms,
    email,
    images,
    names,
    licenceClass,
    licenceExpiry,
    licenceNumber,
    placeOfBirth,
    postage,
    stateOfIssue,
    transaction,
    wearGlasses,
    terms
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setTransaction: (value) => {
      dispatch(transaction(value))
    },
    validate: () => {
      dispatch(validate());
    }
  }
};

const VisiblePaymentPage = connect(mapStateToProps, mapDispatchToProps)(PaymentPage);

export default VisiblePaymentPage;
