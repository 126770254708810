/**
 * AAA IDP  wear glasses
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import { wearGlasses } from '../../actions/index';
import RadioButton from '../radio-button/RadioButton';
import { BINARY } from '../../data/Data';
import type { AppState, Field } from '../../types/Types';
import './WearGlasses.css';

type Props = {
  ...AppState,
  setWearGlasses: (value: string) => *,
  wearGlasses: Field,
};

export class WearGlasses extends React.Component<Props> {
  static defaultProps = {
    wearGlasses: {
      error: false,
      required: true,
      valid: true ,
      value: 'No',
    },
  };

  render() {
    const {wearGlasses, setWearGlasses} = this.props;
    
    return (
      <RadioButton
        handleChange={setWearGlasses}
        label="Do you wear glasses while driving"
        onBlur={setWearGlasses}
        options={BINARY}
        name="wearGlasses"
        section="licence-details"
        // $FlowFixMe
        values={{wearGlasses}}
      />
    );
  }
}

const mapStateToProps = ({wearGlasses}) => {
  return {wearGlasses};
}

const mapDispatchToProps = dispatch => {
  return {
    setWearGlasses: (value: string) => {
      dispatch(wearGlasses(value))
    }
  }
}

const VisibleWearGlasses = connect(mapStateToProps, mapDispatchToProps)(WearGlasses);

export default VisibleWearGlasses;
