/**
 * OnlineDetector
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import ErrorMessage from '../error-message/ErrorMessage';
// $FlowFixMe
import styles from './OnlineDetector.module.scss';

const OnlineDetector = (): Node => {
  const [online, setOnline] = useState<boolean>(window.navigator.onLine);

  useEffect(() => {
    const isOnline = (e: SyntheticEvent<>) => {
      const result = e.type === 'offline' ? false : true;
      setOnline(result)
    }

    window.addEventListener('offline', isOnline);
    window.addEventListener('online', isOnline);

    return (() => {
      window.removeEventListener('offline', isOnline);
      window.removeEventListener('online', isOnline);
    });
  }, [online])

  if (online) {
    return null;
  }
   
  return (
    <div className={styles.OnlineDetector}>
      <ErrorMessage error={true} message="It appears that you are offline, please check your network connection before uploading an image" />
    </div>
  );
}

export default OnlineDetector;