/**
 * AAA IDP Already Paid
 * @flow
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './AlreadyPaid.css';

type Props = {
  application: {
    referrer: string
  }
};

export class AlreadyPaid extends Component<Props> {
  static defaultProps = {
    application: {
      referrer: 'https://www.internationaldrivingpermit.com.au/'
    }
  };

  render() {
    const { referrer } = this.props.application;

    return (
      <div className="AlreadyPaid">
        <h2>Order successful</h2>
        <p>Your IDP order has been completed successfully.</p>
        <div className="footer">
          <div
            className="button restart"
            onClick={() => {
              window.location = referrer;
            }}
            tabIndex="0"
            role="button"
          >
            <FontAwesomeIcon icon="redo" className="left" />
            Apply for another IDP
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ application }) => {
  return { application };
};

const VisibleAlreadyPaid = connect(mapStateToProps)(AlreadyPaid);

export default VisibleAlreadyPaid;
