/**
 * Address Finder
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import {
  addressUseSameToggle,
  clearLicenceAddress,
  clearDeliveryAddress,
  cloneLicenceAddress,
  addressFinder,
  deliveryAddress,
  licenceAddress,
  validateAddress
} from '../../actions/';
import ErrorMessage from '../error-message/ErrorMessage';
import AddressFinderSuggestion from './AddressFinderSuggestion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ADDRESS_FINDER, FIELDS } from '../../data/Data';
import type { Addresses, AddressFinderResults, AddressFinderCanonical } from '../../types/Types';
import './AddressFinder.css';

type Props = {
  application: {
    id: number
  },
  addresses: Addresses,
  bugsnagClient: { notify: Error => * },
  addressUseSameToggle: (value: boolean) => *,
  clearAddress: () => *,
  clearDeliveryAddress: () => *,
  cloneLicenceAddress: () => *,
  addressFinder: (results: AddressFinderCanonical, name: string) => *,
  licenceAddress: (value: string) => *,
  deliveryAddress: (value: string) => *,
  info: string,
  jest: boolean,
  label: string,
  name: string,
  onBlur: (value: string) => *,
  required: boolean,
  section: string,
  validateAddress: (value: string) => *
};

type State = {
  apiError: boolean,
  completions: Array<{
    id: string,
    focus: boolean,
    full_address: string,
    canonical_address_id: string
  }>,
  keepTyping: boolean,
  noResults: boolean,
  // $FlowFixMe
  refs: Array<React.RefObject>,
  searching: boolean
};

const RANDOM = (Math.random() * 1000).toFixed(0);

export class AddressFinder extends React.Component<Props, State> {
  static defaultProps = {
    addresses: FIELDS,
    info: 'Address Finder',
    jest: false,
    label: 'Address on licence',
    name: 'licenceAddress',
    required: false,
    section: 'licence-details'
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      apiError: false,
      completions: [],
      keepTyping: false,
      noResults: false,
      refs: [],
      searching: false,

      value: ''
    };
  }

  componentDidMount() {
    // $FlowFixMe
    document.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount() {
    // $FlowFixMe
    document.removeEventListener('keydown', this.handleKeydown);
  }

  /**
   * handleKeydown
   */
  handleKeydown = (e: SyntheticKeyboardEvent<>) => {
    const { completions } = this.state;
    const length = completions.length;

    if (length === 0) {
      return null;
    }

    const { key } = e;

    // Close on escape key
    if (key === 'Escape') {
      this.close();
      return false;
    }

    if (key === 'ArrowDown') {
      e.preventDefault();
      for (let i = 0; i < length; i++) {
        switch (true) {
          case completions[i].focus && i === length - 1:
            completions[i].focus = false;
            completions[0].focus = true;
            this.setState({
              completions
            });
            return;

          case completions[i].focus && i < length - 1:
            completions[i].focus = false;
            completions[i + 1].focus = true;
            this.setState({
              completions
            });
            return;

          // reached the end, focus on completion 0
          case i === length - 1:
            completions[0].focus = true;
            this.setState({
              completions
            });
            return;

          default:
          // no op
        }
      }
    }

    if (key === 'ArrowUp') {
      e.preventDefault();
      for (let i = 0; i < length; i++) {
        switch (true) {
          case completions[i].focus && i > 0:
            completions[i].focus = false;
            completions[i - 1].focus = true;
            this.setState({
              completions
            });
            return;

          // reached the start, focus on last completion
          case i === 0 && completions[i].focus:
            completions[0].focus = false;
            completions[length - 1].focus = true;
            this.setState({
              completions
            });
            return;

          default:
          // no op
        }
      }
    }
  };

  /**
   * Search Address Finder
   */
  search = (value: string) => {
    const { jest, name } = this.props;

    // Test for string length.
    switch (true) {
      case value.length === 0:
        this.setState({
          completions: [],
          keepTyping: false,
          noResults: false,
          searching: false
        });
        this.clearAddress();
        return null;

      case value.length < 6:
        this.setState({
          completions: [],
          keepTyping: true,
          noResults: false,
          searching: false
        });
        this.setAddress(value);
        return null;

      default:
        this.setState({
          keepTyping: false,
          searching: true
        });
        this.setAddress(value);
    }

    // Don't query API if we are a jest test.
    if (jest) {
      return;
    }

    const query = encodeURIComponent(value);
    const pafOrGnaf = name === 'licenceDeliveryAddress' ? 'paf=1' : 'gnaf=1';
    const url = `${ADDRESS_FINDER.url}/autocomplete?q=${query}&format=json&${pafOrGnaf}&key=${ADDRESS_FINDER.key}`;

    fetch(url)
      .then(response => {
        if (response.status >= 400) {
          const message = `HTTP status code: ${response.status}`;
          const err = new Error(message);
          response.json().then(result => console.warn(result));
          throw err;
        } else {
          return response.json();
        }
      })
      .then(results => this.addressFinderResult(results))
      .catch(error => this.error(error));
  };

  /**
   * onBlur
   */
  onBlur = () => {
    const { name, validateAddress } = this.props;
    validateAddress(name);
    this.setState({
      keepTyping: false
    });
  };

  /**
   * Set address
   */
  setAddress = (value: string) => {
    const { deliveryAddress, licenceAddress, name } = this.props;

    switch (name) {
      case 'licenceDeliveryAddress':
        deliveryAddress(value);
        break;

      default:
        licenceAddress(value);

        break;
    }
  };
  /**
   * use licence address for delivery address
   */
  useSameAddress = (e: { target: { checked: boolean } }) => {
    let isChecked = e.target.checked;

    const { cloneLicenceAddress, addresses, addressUseSameToggle } = this.props;

    addressUseSameToggle(!addresses.addressUseSameToggle);

    if (isChecked) {
      return cloneLicenceAddress();
    }
  };
  /**
   * Clear address
   */
  clearAddress = () => {
    const { clearAddress, clearDeliveryAddress, name } = this.props;

    switch (name) {
      case 'licenceDeliveryAddress':
        clearDeliveryAddress();
        break;

      default:
        clearAddress();
    }
  };

  /**
   * addressFinderResult
   *
   * do the things
   */
  addressFinderResult = (results: AddressFinderResults) => {
    let completions = [];

    switch (true) {
      case results['error_code'] !== undefined: {
        const error = new Error(`AddressFinder error ${results.error_code}: ${results.message}`);
        this.error(error);
        return false;
      }

      case results.completions.length === 0:
        this.setState({
          completions,
          noResults: true,
          searching: false
        });
        return null;

      default: {
        for (let i = 0; i < results.completions.length; i++) {
          if (results.completions[i].id === results.completions[i].canonical_address_id) {
            results.completions[i].focus = false;
            completions.push(results.completions[i]);
          }
        }

        const noResults = completions.length === 0 ? true : false;

        this.setState({
          completions,
          noResults,
          searching: false
        });
        return true;
      }
    }
  };

  /**
   * onSelectAddress
   */
  onSelectAddress = (id: string) => {
    this.setState({
      completions: []
    });
    this.getAddressMetadata(id);
  };

  /**
   * Call AddressFinder metadata API for full address info
   */
  getAddressMetadata = (id: string) => {
    const { addressFinder, jest, name } = this.props;

    // Don't query API if we are a jest test.
    if (jest) return;

    const url = `${ADDRESS_FINDER.url}/info?id=${id}&format=json&key=${ADDRESS_FINDER.key}`;

    fetch(url)
      .then(response => {
        if (response.status >= 400) {
          const message = `HTTP status code: ${response.status}`;
          const err = new Error(message);
          response.json().then(result => console.warn(result));
          throw err;
        } else {
          return response.json();
        }
      })
      .then(results => addressFinder(results, name))
      .catch(error => this.error(error));
  };

  /**
   * error
   */
  error = (err: Error) => {
    const { application, bugsnagClient, jest } = this.props;

    this.setState({
      apiError: true
    });

    if (jest) return null;

    console.warn(err);

    err.message = `${err.message}; Application id: ${application.id}`;

    bugsnagClient.notify(err);
  };

  /**
   * clear
   */
  clear = () => {
    this.close();
    this.clearAddress();
  };

  /**
   * close
   */
  close = () => {
    this.setState({
      completions: [],
      searching: false,
      keepTyping: false,
      noResults: false
    });
  };

  renderSuggestions() {
    const { completions } = this.state;

    if (completions.length === 0) {
      return null;
    }

    let addresses = [];

    for (let i = 0; i < completions.length; i++) {
      const address = completions[i].full_address;
      const id = completions[i].canonical_address_id;
      addresses.push(
        <AddressFinderSuggestion
          key={i}
          address={address}
          id={id}
          completions={completions}
          close={this.close}
          onSelectAddress={this.onSelectAddress}
        />
      );
    }

    return <div className="address-finder-suggestions">{addresses}</div>;
  }

  render() {
    const { addresses, jest, label, name, section } = this.props;
    const { addressFieldsToggle, addressDeliveryFieldsToggle, addressUseSameToggle, licenceDelivery } = addresses;

    // logic for showing component
    if (
      (name === 'licenceAddress' && addressFieldsToggle) ||
      (name === 'licenceDeliveryAddress' && addressDeliveryFieldsToggle) ||
      (name === 'licenceDeliveryAddress' && licenceDelivery === 'International')
    )
      return null;

    const { apiError, keepTyping, noResults, searching } = this.state;
    const { error, placeholder, required, valid, value } = addresses[name]
      ? addresses[name]
      : {
          error: false,
          placeholder: '',
          required: false,
          valid: null,
          value: ''
        };

    const id = `${section}_${name}`;
    const classes = ['form-element-container', 'address-finder', name, error ? 'error' : '', valid ? 'valid' : ''];
    const autoComplete = jest ? 'autocomplete_off_hack' : `autocomplete_off_${RANDOM}`;
    const showError = error;
    const errorMessage = apiError
      ? 'Sorry, there has been an error searching for your address.'
      : valid === null
      ? `${label} is required`
      : valid === false
      ? `${label} is not valid`
      : '';

    return (
      <div className={classes.join(' ').trim()}>
        <label htmlFor={name}>
          {label}
          {required ? <span className="required">*</span> : ''}
        </label>
        {name !== 'licenceAddress' && (
          <div className="UseSameAddress">
            <input
              className="same-address"
              type="checkbox"
              defaultChecked={addressUseSameToggle}
              onChange={e => this.useSameAddress(e)}
            />
            <div className="label">Use same address on licence</div>
          </div>
        )}
        <input
          id={id}
          name={name}
          type="text"
          value={value}
          placeholder={placeholder}
          onChange={e => this.search(e.target.value)}
          onBlur={this.onBlur}
          autoComplete={autoComplete}
        />
        {keepTyping ? (
          <div className="keep-typing">
            Keep typing <FontAwesomeIcon icon="spinner" />
          </div>
        ) : null}
        {searching ? (
          <div className="searching">
            Searching <FontAwesomeIcon icon="spinner" />
          </div>
        ) : null}
        {noResults ? (
          <div className="address-finder-suggestions">
            <div className="address-suggestion no-results" onClick={this.clear} tabIndex="0">
              <FontAwesomeIcon icon="xmark" /> No results
            </div>
          </div>
        ) : null}
        {this.renderSuggestions()}
        <ErrorMessage message={errorMessage} error={showError} />
      </div>
    );
  }
}

const mapStateToProps = ({ addresses, application }) => {
  return { addresses, application };
};

const mapDispatchToProps = dispatch => {
  return {
    addressFinder: (results: AddressFinderCanonical, name: string) => {
      dispatch(addressFinder(results, name));
    },
    clearAddress: () => {
      dispatch(clearLicenceAddress());
    },
    clearDeliveryAddress: () => {
      dispatch(clearDeliveryAddress());
    },
    cloneLicenceAddress: () => {
      dispatch(cloneLicenceAddress());
    },
    deliveryAddress: (value: string) => {
      dispatch(deliveryAddress(value));
    },
    licenceAddress: (value: string) => {
      dispatch(licenceAddress(value));
    },
    validateAddress: (value: string) => {
      dispatch(validateAddress(value));
    },
    addressUseSameToggle: (value: boolean) => {
      dispatch(addressUseSameToggle(value));
    }
  };
};

const VisibleAddressFinder = connect(mapStateToProps, mapDispatchToProps)(AddressFinder);

export default VisibleAddressFinder;
