/**
 * AAA IDP date of travel reducer
 * @flow
 */
import { DELETE, LICENCE_EXPIRY, VALIDATE } from '../../actions/types';
import moment from 'moment';
import type Moment from 'moment';
import type {ActionType} from '../../types/Types';

type State = {
  error: boolean,
  errorMessage: string,
  required: boolean,
  valid: boolean | null,
  value: string,
};

export const initialState = {
  error: false,
  errorMessage: '',
  required: true,
  valid:  null ,
  value: '',
};

export default function(state: State = initialState, action: ActionType) {
  switch (action.type) {
    case DELETE :
      return initialState;

    case LICENCE_EXPIRY : {
      const {value} = action;
      return validateDate({ ...state, value });
    }

    case VALIDATE:
      return validateDate(state);

    default:
      return state;
  }
}

/**
 * Validate date
 */
export function validateDate(state: State, now: Moment = moment()) {
  const {value} = state;
  const date = moment(value, 'YYYY-MM-DD', true);
  const dateValid = date.isValid();

  switch(true) {
    // If this isn't a valid date, update the error state.
    case (!dateValid):
      return {
        ...state,
        error: true,
        errorMessage: '',
        valid: false,
      };

    // Ensure expiry is not in the past
    case(date.diff(now, 'days') < 0):
      return {
        ...state,
        error: true,
        errorMessage: 'Your licence is expired. Please renew your licence before applying for an IDP.',
        valid: false,
      };

    // expiry date is valid
    default:
      return {
        ...state,
        error: false,
        errorMessage: '',
        valid: true,
      };
  }
}