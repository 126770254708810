/**
 * AAA IDP TextInput component
 * @flow
 */

import * as React from 'react';
import ErrorMessage from '../error-message/ErrorMessage';
import Info from '../info/Info';
import { FIELD_LENGTH, FIELDS } from '../../data/Data';
import type { Addresses, AppState, Contacts, CreditCard, Names } from  '../../types/Types';
import './TextInput.css';

type Props = {
  autoComplete?: string,
  handleChange: (value: string) => void,
  info: string,
  jest: boolean,
  label: string,
  name: string,
  onBlur: (value: string) => void,
  placeholder: string,
  section: string,
  submit?: boolean,
  type?: string,
  inputMode?: string,
  values: AppState | Addresses | Contacts | CreditCard | Names ,
};

const RANDOM = (Math.random()*1000).toFixed(0);

class TextInput extends React.Component<Props> {
  static defaultProps = {
    info: '',
    jest: false,
    label: 'First name',
    name: 'firstName',
    placeholder: '',
    section: 'personal-details',
    type: 'text',
    inputMode: 'text',
    values: {...FIELDS}
  };

  render() {
    let {autoComplete, onBlur, handleChange, info, inputMode, jest, label, name, placeholder, section, submit, type, values} = this.props;
    // $FlowFixMe
    let { display, error, required, valid, value } = values[name] ? values[name] : {display: '', error: false, required: false, valid: null, value: ''};
    const id = section ? `${section}_${name}` : name;
    const classes = `form-element-container ${name}${error ? ' error' : ''}${valid ? ' valid' : ''}`;
    let errorMessage = '';
    const toggleValue = [
      'licenceDeliveryAddressCountry',
      'EPS_CARDNUMBER',
      'CardNumber',
      'card_number',
    ];
    const length = name === 'licenceNumber' ? 15 : FIELD_LENGTH;
    if (autoComplete === 'off' && !jest) {
      autoComplete = `autoCompleteOff_${RANDOM}`;
    }

    switch(true) {
      case(valid === null):
        errorMessage = `${label} is required`;
        break;

      case (valid === false && value.length > length):
        errorMessage = `${label} exceeds the limit of ${length} characters`;
        break;

      case (valid === false && value.length === 0):
        errorMessage = `${label} is required`;
        break;
      
      case (valid === false):
        errorMessage = `${label} is not valid`;
        break;

      default:
        // no op
    }

    if (toggleValue.indexOf(name) > -1 && !submit) {
      value = display;
    }

    return (
      <div className={classes}>
        <label htmlFor={id}>
          {label}
          {required ? <span className="required">*</span> : null}
        </label>
        {info.length > 0 ? <Info info={info} /> : null}
        <input
          autoComplete={autoComplete}
          id={id}
          name={name}
          onBlur={(e) => onBlur(e.target.value)}
          onChange={(e) => handleChange(e.target.value)}
          placeholder={placeholder}
          type={type}
          value={value}
          inputMode={inputMode}
        />
        <ErrorMessage message={errorMessage} error={error} />
      </div>
    );
  }
}

export default TextInput;
