/**
 * Credit Card number
 * @flow
 */
import React, { Component } from 'react';
import TextInput from '../../../../components/text-input/TextInput';
import { creditCard } from '../../../../actions/index';
import { connect } from 'react-redux';
import type { CreditCard as CC } from '../../../../types/Types';

type Props = {
  creditCard: CC,
  gateway: {
    name: string
  },
  setCreditCard: (value: string) => *,
  submit: boolean,
}

class CreditCard extends Component<Props> {
  static defaultProps = {
    creditCard: {
      creditCard: {
        display: '',
        error: false,
        required: true,
        value: '',
        valid: null,
      },
    },
    gateway: {
      name: 'windcave',
    },
    setCreditCard: () => null,
    submit: false,
  };

  render() {
    const { creditCard, gateway, setCreditCard, submit } = this.props;
    const name: string = gateway.name === 'windcave' ? 'CardNumber' : gateway.name === 'nab-securepay' ? 'EPS_CARDNUMBER' : 'card_number';
    const values = {
      [name]: {
        ...creditCard.credit_card
      }
    };

    return (
      <TextInput
        label="Credit card number"
        name={name}
        handleChange={setCreditCard}
        onBlur={setCreditCard}
        // $FlowFixMe
        values={values}
        placeholder="1234 1234 1234 1234"
        section=""
        submit={submit}
        type="tel"
        inputMode="numeric"
      />
    );
  }
}

const mapStateToProps = ({ creditCard }) => {
  return { creditCard };
};

const mapDispatchToProps = dispatch => {
  return {
    setCreditCard: (value: string) => {
      dispatch(creditCard(value))
    },
  }
};

const VisibleCreditCard= connect(mapStateToProps, mapDispatchToProps)(CreditCard);

export default VisibleCreditCard;
