/**
 * AAA IDP DL terms reducer
 * Set the acceptance of digital licence terms for the user on the homepage
 * @flow
 */

import {ACCEPT_DL_TERMS, DELETE} from '../../actions/types';
import type {ActionType} from '../../types/Types';

export type DigitalLicenceTerms = {
  error: boolean,
  required: boolean,
  valid: null | boolean,
  value: boolean,
};

const initialState = {
  error: false,
  required: true,
  valid:  null ,
  value: false,
};

export default function(state: DigitalLicenceTerms = initialState, action: ActionType) {
  switch (action.type) {
    case ACCEPT_DL_TERMS: {
      const { required } = state; 
      const {value} = action;
      const valid = !value && required ? false : true;

      const acceptDigitalLicence = {
        error: !valid,
        required,
        valid,
        value
      }

      return acceptDigitalLicence;
    }

    case DELETE:
      return {
        error: false,
        required: true,
        valid:  null ,
        value: false,
      }

    default:
      return state;
  }
}
