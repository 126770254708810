/**
 * AA IDP 404
 */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class FourOhFour extends React.Component<{}> {
  render() {
    return (
      <div className="ErrorBoundary">
        <FontAwesomeIcon icon="triangle-exclamation" />
        <div className="apologies">
          <h2>Error 404 | Page not found</h2>
        </div>
      </div>
    );
  }
}

export default FourOhFour;
