/**
 * AAA IDP Form - Photo picker, cropper and upload component
 *
 * @flow
 */
import * as React from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import ErrorMessage from '../error-message/ErrorMessage';
import OnlineDetector from '../../components/online-detector/OnlineDetector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import Spinner from './spinner/Spinner';
import Button from './button/Button';
import { API, FIELDS, OPTIONS, LANDSCAPE_IMAGE_UPLOAD, PORTRAIT_IMAGE_UPLOAD } from '../../data/Data';
import { image, imageError, imageErrorClear, imageUploaded } from '../../actions';
import { connect } from 'react-redux';
import type { Application, ApiResult, Images } from '../../types/Types';
import './PhotoUpload.css';

type Props = {
  application: Application,
  bugsnagClient: { notify: Error => * },
  image: (name: string, value: string) => *,
  imageError: (name: string, error: boolean, message: string) => *,
  imageErrorClear: (name: string) => *,
  imageUploaded: (name: string, uploaded: boolean) => *,
  images: Images,
  name: string,
  chooserTextDefault: string,
  disableAspectRatio: Boolean
};

type State = {
  aspectRatio: number,
  chooserText: string,
  cropping: boolean,
  croppedWidth: number,
  croppedHeight: number,
  focus: string,
  guidelines: {
    width: {
      max: { errorMessage: string, size: number },
      min: { errorMessage: string, size: number }
    },
    height: {
      max: { errorMessage: string, size: number },
      min: { errorMessage: string, size: number }
    }
  },
  height: number,
  imageLoaded: null | boolean,
  image: string,
  loading: boolean,
  showCropper: boolean,
  innerWidth: number,
  uploading: boolean,
  width: number
};

const IMAGE_TYPE = 'image/jpeg';
const ERROR_UPLOADING_IMAGE = 'Error uploading image';

export class PhotoUpload extends React.Component<Props, State> {
  // $FlowFixMe
  fileRef: React.RefObject;
  // $FlowFixMe
  containerRef: React.RefObject;

  static defaultProps = {
    images: FIELDS.images,
    image: () => null,
    imageError: () => null,
    imageErrorClear: () => null,
    imageUploaded: () => null,
    name: 'recentPhoto',
    chooserTextDefault: 'Upload photo'
  };

  // $FlowFixMe
  cropperRef: React.RefObject;

  constructor(props: Props) {
    super(props);

    this.cropperRef = React.createRef();

    const { name } = props;
  
    let aspectRatio = name === 'recentPhoto' ? 3 / 4 : 8 / 5;
    if (this.props.disableAspectRatio) {
      aspectRatio = null;
    }
   
    const guidelines = name === 'recentPhoto' ? PORTRAIT_IMAGE_UPLOAD : LANDSCAPE_IMAGE_UPLOAD;
    const { innerWidth } = window;

    this.state = {
      aspectRatio,
      chooserText: 'Upload photo',
      cropping: false,
      croppedWidth: 0,
      croppedHeight: 0,
      focus: '',
      guidelines,
      imageLoaded: null,
      height: 0,
      image: '',
      loading: false,
      showCropper: true,
      innerWidth,
      uploading: false,
      width: 0
    };

    /**
     * When reloading the cropper we need to show it, then hide it,
     * otherwise it sizes itself incorrectly
     */
    setTimeout(this.toggleCropper, 500);

    this.fileRef = React.createRef();
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    // $FlowFixMe
    document.addEventListener('keydown', this.handleKeydown);
  }

  componentWillUnmount() {
    // $FlowFixMe
    document.removeEventListener('keydown', this.handleKeydown);
  }

  /**
   * handleKeydown
   */
  handleKeydown = (e: SyntheticKeyboardEvent<>) => {
    const { name } = this.props;
    const { focus } = this.state;
    const { key } = e;

    if (focus && key === 'Enter') {
      e.preventDefault();

      switch (focus) {
        case 'cancel':
        case 'edit':
          this.toggleCropper();
          break;

        case 'confirm':
          this.crop();
          break;

        case 'uploadAgain':
          this.uploadAgain(e);
          break;

        case 'select':
          this.clearError();
          imageUploaded(name, false);
          this.fileRef.current.click();

          break;

        default:
        // no op
      }
    }
  };

  /**
   * onFocus
   */
  onFocus = (focus: string) => {
    this.setState({
      focus
    });
  };

  /**
   * onBlur
   */
  onBlur = () => {
    this.setState({
      focus: ''
    });
  };

  /**
   * Image chosen
   *
   * @param {object} event - the synthetic event
   */
  chooseImage = (e: SyntheticInputEvent<HTMLInputElement>) => {
    if (!e.target.files[0]) {
      return false;
    }

    this.clearError();

    const file = e.target.files[0];

    if (['image/jpeg', 'image/jpg', 'image/png'].indexOf(file.type) === -1) {
      this.props.imageError(this.props.name, true, 'Please select an image using one of the approved types: png, jpg, jpeg');
      return false;
    }

    this.setState({
      loading: true
    });

    let img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      this.setState(
        {
          height: img.naturalHeight,
          width: img.naturalWidth,
          imageLoaded: false
        },
        () => this.resizeImage(file)
      );
    };
  };

  /**
   * Resize the image to within bounds before cropping
   *
   * @param {string} file - base64 image file
   */
  resizeImage = (file: File) => {
    const { imageError, name } = this.props;
    const { guidelines, width, height } = this.state;
    const minWidth = guidelines.width.min.size;
    const minHeight = guidelines.height.min.size;
    let maxWidth = guidelines.width.max.size;
    let maxHeight = guidelines.height.max.size;
    let message = '';
    const uri = URL.createObjectURL(file);

    // Error if one dimension is too small. Set maximums if image is too large
    switch (true) {
      case this.imageInBounds():
        this.imageLoaded(uri);
        return true;

      case width < minWidth:
      case height < minHeight:
        message = `Please select an image at least ${minWidth}px wide and ${minHeight}px high. (Yours is ${width}px wide by ${height}px high)`;
        imageError(name, true, message);
        this.imageLoaded(uri, false);
        return message;

      case width > maxWidth:
        maxHeight = Math.floor(height * (maxWidth / width));
        message = '';
        imageError(name, false, message);
        break;

      case height > maxHeight:
        maxWidth = Math.round(width * (maxHeight / height));
        message = '';
        imageError(name, false, message);
        break;

      default:
      // no op
    }

    this.resize(file, 'load');

    // For tests
    return { maxWidth, maxHeight };
  };

  /**
   * Image in bounds?
   *
   * @returns {boolean}
   */
  imageInBounds = () => {
    const { guidelines, width, height } = this.state;
    const widthOk = width >= guidelines.width.min.size && width <= guidelines.width.max.size ? true : false;
    const heightOk = height >= guidelines.height.min.size && height <= guidelines.height.max.size ? true : false;
    return widthOk && heightOk ? true : false;
  };

  /**
   * imageLoaded: setState
   *
   * @param {string} uri - base64 image
   */
  imageLoaded = (uri: string, showCropper: boolean = true) => {
    this.setState({
      chooserText: 'Choose a different image',
      image: uri,
      imageLoaded: true,
      showCropper,
      loading: false
    });
  };

  /**
   * Set state for cropping
   */
  crop = () => {
    this.setState(
      {
        cropping: true
      },
      () => {
        setTimeout(() => this.cropImage(), 100);
      }
    );
  };

  /**
   * Crop the image
   */
  cropImage = () => {
    const imageElement: any = this.cropperRef.current;
    const cropper: any = imageElement.cropper;
    // Put cropper width and height into state so tests work better
    const { width, height } = cropper.getData(true);
    this.setState(
      {
        croppedWidth: width,
        croppedHeight: height
      },
      () => {
        // Send the blob off to get resized before upload. Cropper JS is **terrible** with file size.
        cropper.getCroppedCanvas().toBlob(this.cropperBlob, IMAGE_TYPE);
      }
    );
  };

  /**
   * minify to file
   *
   * @param {*} blob - This image blob
   */
  cropperBlob = (blob: Blob) => {
    // Retrieve the cropped width and height values.
    const { croppedWidth, croppedHeight, guidelines } = this.state;
    const { name, imageError } = this.props;
    const minWidth = guidelines.width.min.size;
    const minHeight = guidelines.height.min.size;
    let message = '';

    // Error if one dimensions are too small
    if (croppedWidth < minWidth || croppedHeight < minHeight) {
      message = `Please crop your image to at least ${minWidth}px wide and ${minHeight}px high. (Yours is ${croppedWidth}px wide and ${croppedHeight}px high)`;
      imageError(name, true, message);
      this.setState({
        cropping: false
      });
      return message;
    }

    // Resize to a maximum of 800px width or height
    const { width, height } = this.resizeDimensions();

    this.setState(
      {
        width,
        height
      },
      () => this.resize(blob, 'cropped')
    );
  };

  /**
   * Set resize width height
   *
   * @returns {object} {width, height}
   */
  resizeDimensions = () => {
    let width, height;
    const { croppedWidth, croppedHeight } = this.state;

    width = 800;

    if (croppedWidth < width) {
      width = Math.round(croppedWidth);
      height = Math.round(croppedHeight);
    } else {
      height = Math.round(croppedHeight * (width / croppedWidth));
    }

    return { width, height };
  };

  /**
   * Resize the image using HTML5 canvas
   *
   * @param {blob} this image blob to resize
   * @param {string} next - are we loading the image or cropping the image
   *
   * @returns undefined - calls this.imageCropped() or this.imageLoaded()
   */
  resize = (blob: Blob, next: string) => {
    const CROPPED = 'cropped';
    const { width, height } = this.state;

    let reader = new FileReader();
    reader.readAsDataURL(blob);

    reader.onload = () => {
      let image = new Image();
      // $FlowFixMe
      image.src = reader.result;

      image.onload = () => {
        let canvas = document.createElement('canvas');

        canvas.width = width;
        canvas.height = height;

        const quality = next === CROPPED ? 0.9 : 1;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(image, 0, 0, width, height);

        const uri = canvas.toDataURL(IMAGE_TYPE, quality);

        if (next === CROPPED) {
          this.imageCropped(uri);
        } else {
          this.imageLoaded(uri);
        }
      };
    };
  };

  /**
   * Image cropped - convert back to blob
   *
   * @param {string} uri - base64 image
   */
  imageCropped = (uri: string) => {
    const { image, name } = this.props;
    image(name, uri);

    this.setState(
      {
        chooserText: 'Replace image',
        cropping: false,
        showCropper: false
      },
      () => this.imageToBlob(uri)
    );
  };

  /**
   * convert base64 to Blob
   */
  imageToBlob = (uri: string) => {
    const { bugsnagClient, name } = this.props;
    fetch(uri)
      .then(res => res.blob())
      .then(blob => this.uploadImage(blob))
      .catch(err => {
        bugsnagClient.notify(err);
        imageError(name, true, 'Error uploading image. Could not convert to blob');
      });
  };

  /**
   * upload Again
   */
  uploadAgain = (e: SyntheticMouseEvent<HTMLButtonElement> | SyntheticKeyboardEvent<>) => {
    e.preventDefault();
    const { imageErrorClear, images, imageUploaded, name } = this.props;
    e.preventDefault();
    this.onBlur();
    imageUploaded(name, false);
    imageErrorClear(name);
    this.imageToBlob(images[name].value);
  };

  /**
   * Upload image
   */
  uploadImage = (file: Blob) => {
    const { application, bugsnagClient, imageErrorClear, imageUploaded, name } = this.props;
    console.warn('uploadImage application.id', application.id);
    const { submission_token } = application;
    const url = `${API}/${application.id}/images`;
    const type = name === 'recentPhoto' ? 'passport' : name === 'licenceFront' ? 'licence-front' : 'licence-back';
    const formData = new FormData();

    // clear upload status and error
    imageUploaded(name, false);
    imageErrorClear(name);

    formData.append('submission_token', submission_token);
    formData.append('type', type);
    formData.append('file', file, `${name}.jpg`);

    // fetch sets the right headers when passed a FormData object as `body:`
    // eslint-disable-next-line no-unused-vars
    let { headers, ...options } = OPTIONS;

    options = {
      ...options,
      body: formData
    };

    this.setState({
      uploading: true
    });

    fetch(url, options)
      .then(async response => {
        console.warn('response.status', response.status);
        const result = await response.json();

        if (response.status >= 400 || !response.ok) {
          const message = `HTTP status code: ${response.status}. result.message: ${result.message}`;
          const err = new Error(message);
          bugsnagClient.notify(new Error(`Image upload error message: ${message}`));
          throw err;
        } else {
          return result;
        }
      })
      .then(response => this.apiResponse(response))
      .catch(err => this.apiError(err));
  };

  /**
   * API response
   *
   * @param {object} response - the API response
   */
  apiResponse = (response: ApiResult) => {
    const { imageUploaded, name } = this.props;

    switch (true) {
      case response['errors'] !== undefined: {
        // Set a default message.
        let message = 'Sorry, there has been an error uploading your photo';

        // If we have received an error title, use that title for the message.
        if (response['message'] !== undefined) {
          message = response.message;
        }

        const error = new Error(message);

        this.apiError(error, response);
        return false;
      }

      default:
        imageUploaded(name, true);
        this.setState({
          uploading: false
        });
        return true;
    }
  };

  /**
   * API error
   */
  apiError = (error: Error, response: ApiResult | null = null) => {
    const { bugsnagClient, imageError, imageUploaded, name } = this.props;

    this.setState({
      uploading: false
    });

    imageUploaded(name, false);
    imageError(name, true, ERROR_UPLOADING_IMAGE);

    if (response) {
      error.message = `${name} ${error.message}. ${JSON.stringify(response)}`;
    } else {
      error.message = `${name} ${error.message}, browser ${window.navigator.online} ${JSON.stringify(error)}`;
    }

    console.warn('apiError error name', name);
    console.warn('apiError error', error.message);
    console.warn('apiError error', error);

    // Send an error report to BugSnag
    bugsnagClient.notify(error);
  };

  /**
   * toggleCropper
   */
  toggleCropper = () => {
    this.setState({
      showCropper: !this.state.showCropper
    });
  };

  /**
   * Clear image error on click of 'Select Image' button
   */
  clearError = () => {
    const { imageErrorClear, name } = this.props;
    imageErrorClear(name);
  };

  /**
   * Cropper container dimensions
   */
  containerDim = () => {
    if (!this.containerRef || !this.containerRef.current) {
      return window.innerWidth;
    }

    const { width } = this.containerRef.current.getBoundingClientRect();
    return width;
  };

  render() {
    const { name, images, chooserTextDefault } = this.props;
    const {
      aspectRatio,
      cropping,
      chooserText,
      focus,
      image,
      imageLoaded,
      innerWidth,
      loading,
      showCropper,
      uploading
    } = this.state;
    let { error, message, value } = images[name] ? images[name] : { error: false, message: '', valid: null, value: '' };
    const label = name === 'recentPhoto' ? 'A recent photo' : name === 'licenceBack' ? 'Licence back' : 'Licence front';
    const btnTitle = imageLoaded === null ? chooserTextDefault : chooserText;

    const section = name === 'recentPhoto' ? 'personal-details' : 'licence-details';
    let classes = ['form-element-container', 'image-crop', name, error ? 'error' : ''];
    let errorStyle = value ? ' centered' : '';
    message = message === '' ? `${label} is required` : message;
    const id = `${section}_${name}`;
    const showCancel = value && showCropper ? true : false;
    // Classes to show and hide photo editing elements
    const hidden = ' hidden';
    const cropperHidden = !showCropper ? hidden : '';
    const chooseImageHidden = showCropper || uploading ? hidden : '';
    const imageUploading = uploading ? ' fade' : '';
    const uploadClass = showCropper || value ? ' has-image' : ' no-image';
    const btnClass = showCropper || value ? ' reverse' : ' ';
    const croppedImageHidden = value && !showCropper ? '' : hidden;
    const fadeCropper = cropping ? ' fade' : '';
    const imageSelectIcon = imageLoaded === null || imageLoaded === true ? 'images' : 'spinner';
    const imageSelectClass = imageLoaded === null || imageLoaded === true ? '' : 'fa-spin';
    // Toggle cropper settings
    const small = innerWidth > 677 ? true : false;

    // Style
    // const width = (innerWidth < 450) ? 320  : (innerWidth < 999) ? 668 : 768;
    const width = this.containerDim();
    const style = { width: width, height: (3 * width) / 4 };
    const spinner = uploading || cropping || loading ? true : false;
    const spinnerText = uploading ? 'Uploading' : cropping ? 'Cropping' : loading ? 'Loading' : 'Busy';

    return (
      <div ref={this.containerRef} className={classes.join(' ').trim() + uploadClass}>
        <label className="upload-instructions" htmlFor={id}>
          <div className="icon">
            <FontAwesomeIcon icon={faImages} />
          </div>
          <div className="title">Upload a photo</div>
          <div className="content">Max file size 5MB. Supported file types .jpg, .jpeg and .png.</div>
        </label>
        <OnlineDetector />
        {!showCropper ? (
          <img src={value} alt="cropped" className={`cropped${croppedImageHidden}${imageUploading}`} />
        ) : null}
        <div className="idp-cropper">
          {!cropperHidden ? (
            <Cropper
              ref={this.cropperRef}
              src={image}
              className={`cropper${cropperHidden}${fadeCropper}`}
              cropBoxMovable={small}
              cropBoxResizable={small}
              zoomOnWheel={false}
              zoomOnTouch={!small}
              movable={!small}
              resizable={!small}
              dragMode={'move'}
              viewMode={1}
              aspectRatio={aspectRatio}
              autoCropArea={1}
              style={style}
            />
          ) : null}
          <div className={`description${cropperHidden}`}>Drag, pinch & zoom to crop your image</div>
          <div className={`actions${uploadClass}`}>
            <Button
              action={this.toggleCropper}
              className={`edit${croppedImageHidden}${chooseImageHidden} reverse`}
              icon="crop-simple"
              show={image && !error ? true : false}
              text="Re-crop"
              name="edit"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
            />
            <Button
              action={this.toggleCropper}
              className={`cancel${cropperHidden} reverse`}
              icon="xmark"
              show={showCancel}
              text="Cancel"
              name="cancel"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
            />
            <Button
              action={this.crop}
              className={`crop${cropperHidden} reverse`}
              icon="check"
              show={true}
              text="Confirm"
              name="confirm"
              onFocus={this.onFocus}
              onBlur={this.onBlur}
            />

            {/* Select image file input */}
            <label
              htmlFor={id}
              className={`button select-image${
                focus === 'select' ? ' focus' : ''
              }${uploadClass}${btnClass}${chooseImageHidden}`}
              onClick={this.clearError}
            >
              <span className="icon">
                <FontAwesomeIcon icon={imageSelectIcon} className={imageSelectClass} />
              </span>
              <span className="text short">Replace</span>
              <span className="text long">{btnTitle}</span>
            </label>
            <input
              ref={this.fileRef}
              id={id}
              name={id}
              type="file"
              accept="image/*"
              onChange={this.chooseImage}
              onFocus={() => this.onFocus('select')}
              onBlur={this.onBlur}
            />
            {error && message === ERROR_UPLOADING_IMAGE ? (
              <button
                className="button upload-again"
                onClick={this.uploadAgain}
                onFocus={() => this.onFocus('uploadAgain')}
                onBlur={this.onBlur}
              >
                <FontAwesomeIcon icon="redo" />
                <span className="text long">Upload again</span>
              </button>
            ) : null}
          </div>
        </div>
        <Spinner spinner={spinner} text={spinnerText} />
        <ErrorMessage error={error} errorStyle={errorStyle} message={message} />
      </div>
    );
  }
}

const mapStateToProps = ({ application, images }) => {
  return { application, images };
};

const mapDispatchToProps = dispatch => {
  return {
    image: (name: string, value: string) => {
      dispatch(image(name, value));
    },
    imageError: (name: string, error: boolean, message: string) => {
      dispatch(imageError(name, error, message));
    },
    imageErrorClear: (name: string) => {
      dispatch(imageErrorClear(name));
    },
    imageUploaded: (name: string, value: boolean) => {
      dispatch(imageUploaded(name, value));
    }
  };
};

const VisiblePhotoUpload = connect(mapStateToProps, mapDispatchToProps)(PhotoUpload);

export default VisiblePhotoUpload;
