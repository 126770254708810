/**
 * AAA IDP Details Page
 * @flow
 */
import * as React from 'react';
import AlreadyPaid from '../../components/already-paid/AlreadyPaid';
import AutoFill from '../../components/autofill/AutoFill';
import ErrorApp from '../../components/error-app/ErrorApp';
import PersonalDetails from './personal-details/PersonalDetails';
import LicenceDetails from './licence-details/LicenceDetails';
import Progress from '../../components/progress/Progress';
import Footer from './footer/Footer';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { application, acceptCookies, deleteAll, locality } from '../../actions';
import type { Locality } from '../../reducers/locality/localityReducer';
import type { Application, Transaction } from '../../types/Types';
import type { Bugsnag } from '@bugsnag/js';
import moment from 'moment';
import './Details.css';

type Props = {
  bugsnagClient: Bugsnag,
  application: Application,
  cookies: boolean,
  deleteAll: () => *,
  digitalLicenceTerms: { value: boolean },
  jest: boolean,
  locality: Locality,
  location: { pathname: string, search: string },
  persisted: boolean, // has the persists store loaded
  setAcceptCookies: (value: boolean) => *,
  setAcceptDigitalLicence: (value: boolean) => *,
  setAcceptTerms: (value: boolean) => *,
  setApplication: (value: Application) => *,
  setLocality: (value: string) => *,
  terms: { value: boolean },
  transaction: Transaction
};

export class Details extends React.Component<Props> {
  static defaultProps = {
    application: {
      id: '12312',
      submission_token: '234gn82h5'
    },
    cookies: false,
    deleteAll: () => null,
    digitalLicenceTerms: false,
    jest: false,
    persisted: false,
    terms: false,
    locality: {
      club: '',
      value: ''
    },
    setAcceptDigitalLicence: () => null,
    setAcceptTerms: () => null,
    setAcceptCookies: () => null,
    setApplication: () => null,
    setLocality: () => null,
    transaction: {
      success: false
    }
  };

  constructor(props: Props) {
    super(props);

    const { jest } = props;

    // scroll to top
    if (!jest) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }

  componentDidMount() {
    this.loadData();
  }

  /**
   * load App data from URL Vars
   * If the homepage form is on a different domain, we pass the params via URL vars
   * So we need to extract them here.
   *
   * Delete data in store if we are passed vars in the URL
   */
  loadData = () => {
    const { deleteAll, location, setAcceptCookies, setAcceptDigitalLicence, setAcceptTerms, setApplication, setLocality } = this.props;
    const { search } = location;

    if (search === '') {
      return null;
    }

    const params = new URLSearchParams(search);
    const appId = params.get('id');
    const clubId = params.get('club_id');
    const clubName = params.get('club_name');
    const clubAbbr = params.get('club_abbreviation');
    const created_at = params.get('created_at');
    const cookiesValue = params.get('cookies');
    const digitalLicenceTermsValue = params.get('digitalLicenceTerms');
    const localityUrlVar = params.get('home-form_locality');
    const termsValue = params.get('terms');
    const token = params.get('token');
    const referrer = params.get('referrer');
    const app = {
      club: {
        id: clubId ? clubId : '-1',
        name: clubName ? clubName : '',
        abbreviation: clubAbbr ? clubAbbr : ''
      },
      created_at: created_at ? created_at : moment().format('U'),
      id: appId ? appId : '-1',
      submission_token: token ? token : '',
      referrer: referrer ? referrer : ''
    };
    const cookies = cookiesValue === 'true' ? true : false;
    const digitalLicenceTerms = digitalLicenceTermsValue === 'true' ? true : false;
    const locality = localityUrlVar !== null ? localityUrlVar : '';
    const terms = termsValue === 'true' ? true : false;

    // If this is a new application and old data exists, delete old data.
    if (appId) {
      deleteAll();
      setTimeout(() => {
        setApplication(app);
        setAcceptCookies(cookies);
        setAcceptDigitalLicence(digitalLicenceTerms);
        setAcceptTerms(terms);
        setLocality(locality);
        window.history.pushState({}, document.title, location.pathname);
      }, 500);
    }

    return true;
  };

  render() {
    const { application, bugsnagClient, transaction } = this.props;
    const { id, submission_token } = application;

    switch (true) {
      case !id || !submission_token:
        return <ErrorApp bugsnagClient={bugsnagClient} />;

      case transaction.success:
        return (
          <React.Fragment>
            <Progress />
            <section className="content-container">
              <div className="Details">
                <AlreadyPaid />
              </div>
            </section>
          </React.Fragment>
        );

      default:
        return (
          <React.Fragment>
            <Progress />
            <section className="content-container">
              <div className="Details">
                <form>
                  <AutoFill bugsnagClient={bugsnagClient} />
                  <PersonalDetails bugsnagClient={bugsnagClient} />
                  <LicenceDetails bugsnagClient={bugsnagClient} />
                  <Footer bugsnagClient={bugsnagClient} />
                </form>
              </div>
            </section>
          </React.Fragment>
        );
    }
  }
}

const mapStateToProps = ({ application, cookies, digitalLicenceTerms, locality, terms, transaction }) => {
  return { application, cookies, digitalLicenceTerms, locality, terms, transaction };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteAll: () => {
      dispatch(deleteAll());
    },
    setApplication: (value: Application) => {
      dispatch(application(value));
    },
    setAcceptCookies: (value: boolean) => {
      dispatch(acceptCookies(value));
    },
    setLocality: (value: string) => {
      dispatch(locality(value));
    }
  };
};

const VisibleDetails = connect(mapStateToProps, mapDispatchToProps)(Details);

export default withRouter(VisibleDetails);
