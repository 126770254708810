/**
 * AAA IDP Form - Address Components
 * @flow
 */
import * as React from 'react';
import { connect } from 'react-redux';
import {
  licenceAddress,
  licenceAddressLocality,
  licenceAddressPostcode,
  licenceAddressState,
  licenceAddressStreet,
  licenceDeliveryAddressLocality,
  licenceDeliveryAddressPostcode,
  licenceDeliveryAddressState,
  licenceDeliveryAddressStreet,
  licenceDeliveryAddressCountry,
  setI18n
} from '../../actions/';
import Select from '../select/Select';
import TextInput from '../text-input/TextInput';
import { COUNTRIES, FIELDS, I18N, MAYBE_POSTCODE } from '../../data/Data';
import type { AddressData, Addresses } from '../../types/Types';
import './AddressComponents.css';

const SECTION = 'address-components';

type Props = {
  addresses: Addresses,
  bugsnagClient: { notify: Error => * },
  configureDeliveryAddress: ({}) => void,
  licenceAddressLocality: (value: string) => *,
  licenceAddressPostcode: (value: string) => *,
  licenceAddressState: (value: string) => *,
  licenceAddressStreet: (value: string) => *,
  licenceDeliveryAddressLocality: (value: string) => *,
  licenceDeliveryAddressPostcode: (value: string) => *,
  licenceDeliveryAddressState: (value: string) => *,
  licenceDeliveryAddressStreet: (value: string) => *,
  licenceDeliveryAddressCountry: (value: string) => *,
  error: boolean,
  name: string,
  onBlur: (value: string) => void,
  required: boolean,
  section: string,
  setI18n: (data: AddressData) => *
};

export class AddressComponents extends React.Component<Props> {
  static defaultProps = {
    addresses: FIELDS,
    error: false,
    name: 'addressComponents',
    required: false,
    section: 'address-components'
  };

  /**
   * handle country change
   * this is only possible for international addresses
   */
  licenceDeliveryAddressCountry = async (phoneCountryCode: string) => {
    const { setI18n } = this.props;
    const url = `${I18N}${phoneCountryCode}`;
    const data = await fetch(url)
      .then(response => {
        if (response.status >= 400) {
          const message = `HTTP status code: ${response.status}`;
          const err = new Error(message);
          response.json().then(result => console.warn(result));
          throw err;
        } else {
          return response.json();
        }
      })
      .then(result => {
        return result;
      })
      .catch(error => console.warn(error));

    if (data) {
      setI18n(data);
    }
  };

  /**
   * handle street address change
   */
  handleStreetAddress = (value: string) => {
    const { name, licenceDeliveryAddressStreet, licenceAddressStreet } = this.props;

    switch (true) {
      case name === 'licenceDeliveryAddress':
        licenceDeliveryAddressStreet(value);
        break;

      default:
        licenceAddressStreet(value);
    }
  };

  /**
   * handle locality change
   */
  handleLocality = (value: string) => {
    const { name, licenceDeliveryAddressLocality, licenceAddressLocality } = this.props;

    switch (true) {
      case name === 'licenceDeliveryAddress':
        licenceDeliveryAddressLocality(value);
        break;

      default:
        licenceAddressLocality(value);
    }
  };

  /**
   * handle state address change
   */
  handleState = (value: string) => {
    const { name, licenceDeliveryAddressState, licenceAddressState } = this.props;

    switch (true) {
      case name === 'licenceDeliveryAddress':
        licenceDeliveryAddressState(value);
        break;

      default:
        licenceAddressState(value);
    }
  };

  /**
   * handle state address change
   */
  handlePostcode = (value: string) => {
    const { name, licenceDeliveryAddressPostcode, licenceAddressPostcode } = this.props;

    switch (true) {
      case name === 'licenceDeliveryAddress':
        licenceDeliveryAddressPostcode(value);
        break;

      default:
        licenceAddressPostcode(value);
    }
  };

  render() {
    const { addresses, name } = this.props;
    let error = false;
    let stateLabel = 'State';
    let localityLabel = 'Suburb/town';
    let postcodeLabel = 'Postcode';
    let showState = true;
    let showPostcode = true;
    let country = '';
    let countries = [...COUNTRIES];
    let index = -1;

    for (let i = 0; i < countries.length; i++) {
      if (countries[i].value === 'AU') {
        index = i;
        break;
      }
      // no point going past Austria
      if (countries[i].value === 'AT') {
        break;
      }
    }

    if (index > -1) {
      countries.splice(index, 1);
    }

    switch (name) {
      case 'licenceAddress':
        if (!addresses.addressFieldsToggle) return null;
        error = addresses.licenceAddress?.error;
        break;

      case 'licenceDeliveryAddress':
        if (!addresses.addressDeliveryFieldsToggle) return null;
        stateLabel = addresses.licenceDeliveryAddressState?.label;
        localityLabel = addresses.licenceDeliveryAddressLocality?.label;
        postcodeLabel = addresses.licenceDeliveryAddressPostcode?.label;
        country = addresses.licenceDeliveryAddressCountry?.value;

        if (!addresses.licenceDeliveryAddressState?.required) {
          showState = false;
        }

        if (!addresses.licenceDeliveryAddressPostcode?.required && MAYBE_POSTCODE.indexOf(country) === -1) {
          showPostcode = false;
        }
        break;

      default:
      // no op
    }

    return (
      <div className={`form-element-container address-components${error ? ' error' : ''}`}>
        <div className="manual-address">
          {name === 'licenceDeliveryAddress' && addresses.licenceDelivery === 'International' ? (
            <Select
              autoComplete="country"
              handleChange={this.licenceDeliveryAddressCountry}
              onBlur={() => null}
              label={'Country'}
              name={`${name}Country`}
              options={countries}
              section={SECTION}
              values={addresses}
            />
          ) : null}
          <TextInput
            autoComplete="street-address"
            handleChange={this.handleStreetAddress}
            onBlur={this.handleStreetAddress}
            label={'Street address'}
            name={`${name}Street`}
            section={SECTION}
            values={addresses}
          />

          <TextInput
            handleChange={this.handleLocality}
            onBlur={this.handleLocality}
            label={localityLabel}
            name={`${name}Locality`}
            section={SECTION}
            values={addresses}
          />
          <div className="state-postcode">
            {showState ? (
              <Select
                handleChange={this.handleState}
                onBlur={() => null}
                label={stateLabel}
                name={`${name}State`}
                options={addresses[`${name}State`].states}
                section={SECTION}
                values={addresses}
              />
            ) : null}

            {showPostcode ? (
              <TextInput
                handleChange={this.handlePostcode}
                onBlur={this.handlePostcode}
                label={postcodeLabel}
                name={`${name}Postcode`}
                section={SECTION}
                values={addresses}
              />
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ addresses }) => {
  return { addresses };
};

const mapDispatchToProps = dispatch => {
  return {
    licenceAddress: (value: string) => {
      dispatch(licenceAddress(value));
    },
    licenceAddressLocality: (value: string) => {
      dispatch(licenceAddressLocality(value));
    },
    licenceAddressPostcode: (value: string) => {
      dispatch(licenceAddressPostcode(value));
    },
    licenceAddressStreet: (value: string) => {
      dispatch(licenceAddressStreet(value));
    },
    licenceAddressState: (value: string) => {
      dispatch(licenceAddressState(value));
    },
    licenceDeliveryAddressLocality: (value: string) => {
      dispatch(licenceDeliveryAddressLocality(value));
    },
    licenceDeliveryAddressPostcode: (value: string) => {
      dispatch(licenceDeliveryAddressPostcode(value));
    },
    licenceDeliveryAddressState: (value: string) => {
      dispatch(licenceDeliveryAddressState(value));
    },
    licenceDeliveryAddressStreet: (value: string) => {
      dispatch(licenceDeliveryAddressStreet(value));
    },
    licenceDeliveryAddressCountry: (value: string) => {
      console.log(value);
      dispatch(licenceDeliveryAddressCountry(value));
    },
    setI18n: (data: AddressData) => {
      dispatch(setI18n(data));
    }
  };
};

const VisibleAddressComponents = connect(mapStateToProps, mapDispatchToProps)(AddressComponents);

export default VisibleAddressComponents;
