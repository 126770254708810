/**
 * Credit Card expiry
 * @flow
 */
import React, { Component } from 'react';
import Select from '../../../../components/select/Select';
import ErrorMessage from '../../../../components/error-message/ErrorMessage';
import { expiryMonth, expiryYear } from '../../../../actions/index';
import { connect } from 'react-redux';
import moment from 'moment';
import type { CreditCard } from '../../../../types/Types';

type Props = {
  expiryMonth: (value: string) => *,
  expiryYear: (value: string) => *,
  creditCard: CreditCard,
  gateway: {
    name: string
  },
}

const MONTH_OPTIONS = [
  {title: '--', value: ''},
  {title: '01', value: '01'},
  {title: '02', value: '02'},
  {title: '03', value: '03'},
  {title: '04', value: '04'},
  {title: '05', value: '05'},
  {title: '06', value: '06'},
  {title: '07', value: '07'},
  {title: '08', value: '08'},
  {title: '09', value: '09'},
  {title: '10', value: '10'},
  {title: '11', value: '11'},
  {title: '12', value: '12'},
];

const YEAR_OPTIONS = [{title: '----', value: ''}];

for (let i=0; i<9; i++) {
  const year = moment();
  year.add(i, 'years');

  YEAR_OPTIONS.push({
    title: year.format('YYYY'),
    value: year.format('YY')
  })
}


class Expiry extends Component<Props> {
  static defaultProps = {
    expiryMonth: () => null,
    expiryYear: () => null,
    creditCard: {
      expiryMonth: {
        error: false,
        required: true,
        value: '',
        valid: null,
      },
      expiryYear: {
        error: false,
        required: true,
        value: '',
        valid: null,
      },
    },
    gateway: {
      name: 'windcave',
    },
  };

  render() {
    const { expiryMonth, expiryYear, creditCard, gateway } = this.props;
    const { expiry_month, expiry_year } = creditCard;

    const error = expiry_month?.error || expiry_year?.error ? true : false;
    let year = expiry_year && expiry_year.value && typeof(expiry_year.value) === 'string' ? expiry_year.value : '';
    let month = expiry_month && expiry_month.value && typeof(expiry_month?.value) === 'string' ? expiry_month.value : '';
    const date = moment(new Date(parseInt(`20${year}`), parseInt(month) - 1));
    const pastExpiry = moment().isAfter(date);
    const message = pastExpiry ? 'Your credit card has expired.' : error ? 'Expiry month and year are required': '';
    const monthName: string = gateway.name === 'windcave' ? 'ExpiryMonth' : gateway.name === 'nab-securepay' ? 'EPS_EXPIRYMONTH' : 'expiry_month';
    const yearName: string = gateway.name === 'windcave' ? 'ExpiryYear' : gateway.name === 'nab-securepay' ? 'EPS_EXPIRYYEAR' : 'expiry_year';
    const monthValue = {
      [monthName]: {
        ...creditCard.expiry_month
      }
    };
    const yearValue = {
      [yearName]: {
        ...creditCard.expiry_year
      }
    };

    return (
      <div className="form-element-container expiry-date">
        <span className="label">Credit card expiry</span>
        <Select
          label="Expiry month"
          name={monthName}
          handleChange={expiryMonth}
          onBlur={expiryMonth}
          // $FlowFixMe
          values={monthValue}
          section=""
          options={MONTH_OPTIONS}
        /> 
        <div className="slash">/</div>
        <Select
          label="Expiry year"
          name={yearName}
          handleChange={expiryYear}
          onBlur={expiryYear}
          // $FlowFixMe
          values={yearValue}
          section=""
          options={YEAR_OPTIONS}
        />
        <ErrorMessage error={error} message={message} />
      </div>
    );
  }
}

const mapStateToProps = ({ creditCard }) => {
  return { creditCard };
};

const mapDispatchToProps = dispatch => {
  return {
    expiryMonth: (value: string) => {
      dispatch(expiryMonth(value))
    },
    expiryYear: (value: string) => {
      dispatch(expiryYear(value))
    },
  }
};

const VisibleExpiry= connect(mapStateToProps, mapDispatchToProps)(Expiry);

export default VisibleExpiry;
