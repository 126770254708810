/**
 * AAA IDP Boc component for home page
 * @flow
 */
import * as React from 'react';
import './HowTo.css';

type Props = {
  items: Array<{ title: string, content: string }>
};

class HowTo extends React.Component<Props> {
  static defaultProps = { content: [] };

  render() {
    const { items } = this.props;

    return (
      <div className="HowTo">
        <div className="path path1"></div>
        <div className="path path2"></div>
        {items.map((item, index) => {
          return (
            <div key={index} className="box">
              <div className="head">
                <div className="index">{index + 1}</div>
                <h4 className="title">{item.title}</h4>
              </div>
              <div className="content">
                <div className="vpath-container">
                  <div className="vpath"></div>
                </div>
                <div className="copy" dangerouslySetInnerHTML={{ __html: item.content }} />
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

export default HowTo;
