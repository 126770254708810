/**
 * AAA IDP title
 * @flow
 */
import React from 'react';
import { connect } from 'react-redux';
import { dateOfBirth } from '../../actions/index';
import DateInput from '../date-input/DateInput';
import './DateOfBirth.css';
import moment from 'moment';

type Props = {
  dateOfBirth: {
    value: string
  },
  setDateOfBirth: (value: string) => *
};

export class DateOfBirth extends React.Component<Props> {
  static defaultProps = {
    dateOfBirth: {
      value: ''
    }
  };

  render() {
    const { dateOfBirth, setDateOfBirth } = this.props;

    return (
      <div>
        <DateInput
          handleChange={setDateOfBirth}
          label="Date of birth"
          maxFuture={moment().add(5, 'years')}
          name="dateOfBirth"
          onBlur={setDateOfBirth}
          range="past"
          section="personal-details"
          values={{ dateOfBirth }}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ dateOfBirth }) => {
  return { dateOfBirth };
};

const mapDispatchToProps = dispatch => {
  return {
    setDateOfBirth: (value: string) => {
      dispatch(dateOfBirth(value));
    }
  };
};

const VisibleDateOfBirth = connect(mapStateToProps, mapDispatchToProps)(DateOfBirth);

export default VisibleDateOfBirth;
