/**
 * Countdown timer
 *
 * Times out session on Payment page
 * @flow
 */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IS_PROD } from '../../../data/Data';
import './CountdownTimer.css';

type Props = {
  expired: boolean,
  reload: (e: SyntheticMouseEvent<HTMLDivElement>) => *,
  setPageExpired: () => *
};

const CountdownTimer = (props: Props) => {
  const { expired, reload, setPageExpired } = props;
  const search = new URLSearchParams(window.location.search);
  const searchMax = parseInt(search.get('max'));
  const max = !IS_PROD && searchMax && searchMax >= 1 && searchMax < 5400 ? searchMax : 90 * 60; // 90 mins timeout
  const [time, setTime] = useState(max); // max time is measured in seconds
  const [display, setDisplay] = useState(null);

  useEffect(() => {
    let timeoutId;
  
    if (time <= -1) {
      setPageExpired();
      setTime(0);
      return () => {
        clearTimeout(timeoutId);
      };
    }

    timeoutId = setTimeout(() => {
      const newTime = time - 1;
      const hours = parseInt(time / 3600);
      let minutes = parseInt(time / 60) - hours * 60;
      let seconds = time - (hours * 60 + minutes) * 60;
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      if (seconds < 10) {
        seconds = `0${seconds}`;
      }
      setTime(newTime);
      setDisplay(`${hours}:${minutes}:${seconds}`);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };

  }, [setPageExpired, setTime, setDisplay, time]);

  return expired ? (
    <div className="CountdownTimer" onClick={reload}>
      <FontAwesomeIcon icon={'triangle-exclamation'} inverse={true} />
      <div className="top-text">
        {expired ? 'Your session has expired' : 'You have'}
        <div className="click">Click to reload the page to continue your transaction</div>
      </div>
    </div>
  ) : (
    <div className="CountdownTimer hidden">{display}</div>
  );
};

export default CountdownTimer;
