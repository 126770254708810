import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import bugsnag from '@bugsnag/js';
import bugsnagReact from '@bugsnag/plugin-react';
import { BUGSNAG } from './data/Data';
import { version } from '../package.json';
const { hostname } = window.location;
const stage = hostname.match(/dev|local/) ? 'development' : hostname.match(/stage/) ? 'staging' : 'production';
const bugsnagClient = bugsnag({
  apiKey: BUGSNAG.token,
  appVersion: version,
  beforeSend: function(report) {
    report.app.releaseStage = stage;
  }
});
bugsnagClient.use(bugsnagReact, React);
console.info(`AAA IDP version ${version} ${stage}`);

// wrap your entire app tree in the ErrorBoundary for bug reporting
const ErrorBoundary = bugsnagClient.getPlugin('react');

ReactDOM.render(
  <ErrorBoundary>
    <App bugsnagClient={bugsnagClient} />
  </ErrorBoundary>,
  document.getElementById('root')
);
