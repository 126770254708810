/**
 * AAA IDP Licence address reducer
 * @flow
 */
import PostalAddress from 'i18n-postal-address';
import { COUNTRIES, FIELD_LENGTH, MAYBE_POSTCODE, NUMBER_AFTER, STATES, ZIP } from '../../data/Data';
import {
  ADDRESS_FINDER,
  ADDRESS_FIELDS_TOGGLE,
  ADDRESS_USE_SAME_TOGGLE,
  CLEAR_DELIVERY_ADDRESS,
  CLEAR_LICENCE_ADDRESS,
  CLONE_LICENCE_ADDRESS,
  DELETE,
  DELIVERY_ADDRESS,
  DELIVERY_ADDRESS_FIELDS_TOGGLE,
  DELIVERY_ADDRESS_COUNTRY,
  DELIVERY_ADDRESS_COUNTRY_ERROR,
  DELIVERY_ADDRESS_LOCALITY,
  DELIVERY_ADDRESS_POSTCODE,
  DELIVERY_ADDRESS_STATE,
  DELIVERY_ADDRESS_STREET,
  DELIVERY_INT,
  LICENCE_ADDRESS,
  LICENCE_ADDRESS_LOCALITY,
  LICENCE_ADDRESS_POSTCODE,
  LICENCE_ADDRESS_STATE,
  LICENCE_ADDRESS_STREET,
  SET_I18N,
  VALIDATE,
  VALIDATE_ADDRESS
} from '../../actions/types';
import type { AddressData, Addresses, AddressFinderCanonical, Field } from '../../types/Types';

type ActionType = {
  data?: AddressData,
  name?: string,
  results?: AddressFinderCanonical,
  search?: string,
  type: string,
  value?: string
};

export const initAddresses = {
  addressFieldsToggle: false, // toggle between search and manual input
  addressDeliveryFieldsToggle: false, // toggle between search and manual input
  addressUseSameToggle: false, // toggle copy of address for delivery
  licenceAddress: {
    error: false,
    placeholder: 'Search address',
    required: true,
    valid: false,
    value: ''
  },
  licenceAddressStreet: {
    error: false,
    required: true,
    valid: false,
    value: ''
  },
  licenceAddressLocality: {
    error: false,
    required: true,
    valid: false,
    value: ''
  },
  licenceAddressState: {
    error: false,
    required: true,
    states: STATES,
    valid: false,
    value: ''
  },
  licenceAddressCountry: {
    error: false,
    display: '',
    required: true,
    valid: true,
    value: 'AU'
  },
  licenceAddressPostcode: {
    error: false,
    label: 'Postcode',
    regex: '\\d{4}',
    required: true,
    valid: false,
    value: ''
  },
  licenceDelivery: 'Australian',
  licenceDeliveryAddress: {
    error: false,
    format: '%O%n%N%n%A%n%C %S %Z',
    placeholder: 'Search address',
    required: true,
    valid: false,
    value: ''
  },
  licenceDeliveryAddressFullName: {
    error: false,
    overridden: false,
    placeholder: '',
    required: true,
    valid: false,
    value: ''
  },
  licenceDeliveryAddressStreet: {
    error: false,
    required: true,
    valid: false,
    value: ''
  },
  licenceDeliveryAddressLocality: {
    error: false,
    label: 'Town/Suburb',
    required: true,
    valid: false,
    value: ''
  },
  licenceDeliveryAddressState: {
    display: '',
    error: false,
    label: 'State',
    required: true,
    states: STATES,
    valid: false,
    value: ''
  },
  licenceDeliveryAddressCountry: {
    display: 'Australia',
    error: false,
    required: true,
    valid: false,
    value: 'AU'
  },
  licenceDeliveryAddressPostcode: {
    error: false,
    label: 'Postcode',
    regex: '\\d{4}',
    required: true,
    valid: false,
    value: ''
  }
};

export default function(state: Addresses = initAddresses, action: ActionType) {
  switch (action.type) {
    case ADDRESS_FIELDS_TOGGLE: {
      const { value } = action;

      return {
        ...state,
        addressFieldsToggle: value
      };
    }

    case DELIVERY_ADDRESS_FIELDS_TOGGLE: {
      const { value } = action;

      return {
        ...state,
        addressDeliveryFieldsToggle: value
      };
    }

    case ADDRESS_USE_SAME_TOGGLE: {
      const { value } = action;

      return {
        ...state,
        addressUseSameToggle: value
      };
    }

    case CLEAR_DELIVERY_ADDRESS: {
      return clearDeliveryAddress(state);
    }

    case CLEAR_LICENCE_ADDRESS: {
      return clearAddress(state);
    }

    case CLONE_LICENCE_ADDRESS: {
      return cloneLicenceAddress(state);
    }

    case DELETE:
      return initAddresses;

    case ADDRESS_FINDER: {
      const { results, name } = action;

      if (!results) return state;

      let {
        addressUseSameToggle,
        licenceAddress,
        licenceAddressStreet,
        licenceAddressPostcode,
        licenceAddressLocality,
        licenceAddressState,
        licenceDelivery,
        licenceDeliveryAddress,
        licenceDeliveryAddressStreet,
        licenceDeliveryAddressLocality,
        licenceDeliveryAddressPostcode,
        licenceDeliveryAddressState,
        licenceDeliveryAddressCountry
      } = state;

      const address = results.canonical_address;
      const components = address.split(',');
      const last = components.length - 1;
      const addressStreet = address.substr(0, address.length - components[last].length - 1);
      const addressPostcode = results.postcode;
      const addressState = results.state_territory;
      const addressLocality = results.locality_name;

      if (name === 'licenceAddress') {
        licenceAddress = {
          ...licenceAddress,
          error: false,
          valid: true,
          value: address
        };

        licenceAddressStreet = {
          ...licenceAddressStreet,
          error: false,
          valid: true,
          value: addressStreet
        };

        licenceAddressPostcode = {
          ...licenceAddressPostcode,
          error: false,
          valid: true,
          value: addressPostcode
        };

        licenceAddressLocality = {
          ...licenceAddressLocality,
          error: false,
          valid: true,
          value: addressLocality
        };

        licenceAddressState = {
          ...licenceAddressState,
          error: false,
          valid: true,
          value: addressState
        };

        if (addressUseSameToggle && licenceDeliveryAddress && licenceDelivery === 'Australian') {
          licenceDeliveryAddress = {
            ...licenceDeliveryAddress,
            error: false,
            valid: true,
            value: address
          };

          licenceDeliveryAddressStreet = {
            ...licenceDeliveryAddressStreet,
            error: false,
            valid: true,
            value: addressStreet
          };

          licenceDeliveryAddressPostcode = {
            ...licenceDeliveryAddressPostcode,
            error: false,
            valid: true,
            value: addressPostcode
          };

          licenceDeliveryAddressLocality = {
            ...licenceDeliveryAddressLocality,
            error: false,
            valid: true,
            value: addressLocality
          };

          licenceDeliveryAddressState = {
            ...licenceDeliveryAddressState,
            error: false,
            valid: true,
            value: addressState
          };

          licenceDeliveryAddressCountry = {
            ...licenceDeliveryAddressCountry,
            error: false,
            valid: true,
            value: 'AU'
          };
        }

        return {
          ...state,
          licenceAddress,
          licenceAddressStreet,
          licenceAddressPostcode,
          licenceAddressLocality,
          licenceAddressState,
          licenceDeliveryAddress,
          licenceDeliveryAddressStreet,
          licenceDeliveryAddressLocality,
          licenceDeliveryAddressPostcode,
          licenceDeliveryAddressState,
          licenceDeliveryAddressCountry
        };
      }

      // Licence Delivery Address
      if (licenceDelivery === 'Australian') {
        licenceDeliveryAddress = {
          ...licenceDeliveryAddress,
          error: false,
          valid: true,
          value: address
        };

        licenceDeliveryAddressStreet = {
          ...licenceDeliveryAddressStreet,
          error: false,
          valid: true,
          value: addressStreet
        };

        licenceDeliveryAddressPostcode = {
          ...licenceDeliveryAddressPostcode,
          error: false,
          valid: true,
          value: addressPostcode
        };

        licenceDeliveryAddressLocality = {
          ...licenceDeliveryAddressLocality,
          error: false,
          valid: true,
          value: addressLocality
        };

        licenceDeliveryAddressState = {
          ...licenceDeliveryAddressState,
          error: false,
          valid: true,
          value: addressState
        };

        licenceDeliveryAddressCountry = {
          ...licenceDeliveryAddressCountry,
          error: false,
          valid: true,
          value: 'AU'
        };
      }

      return {
        ...state,
        licenceDeliveryAddress,
        licenceDeliveryAddressStreet,
        licenceDeliveryAddressLocality,
        licenceDeliveryAddressPostcode,
        licenceDeliveryAddressState,
        licenceDeliveryAddressCountry
      };
    }

    case SET_I18N: {
      const { data } = action;

      if (!data || !data.key) {
        new Error('Error no data returned from Google i18n service');
        return state;
      }

      let newState = { ...state };

      let {
        licenceDeliveryAddressCountry,
        licenceDelivery,
        licenceDeliveryAddressLocality,
        licenceDeliveryAddressState,
        licenceDeliveryAddressPostcode
      } = newState;

      let hasStates = false;
      let states = [{ title: 'Select', value: '' }];
      let statesLabel = 'State';
      let hasPostcode = false;
      let postcodeLabel = 'Postcode';
      let postcodeRegex = '';
      let postcodeRequired = false;
      let subLocalityLabel = 'Town/Suburb';

      if (data['sub_keys'] !== undefined || data['sub_names'] !== undefined || data['sub_lnames'] !== undefined) {
        let values = [];
        let titles = [];
        hasStates = true;

        switch (data.key) {
          case 'CO':
          case 'CV':
          case 'CU':
          case 'KY':
          case 'HK':
          case 'ID':
          case 'IE':
          case 'IN':
          case 'JM':
          case 'MY':
          case 'MZ':
          case 'NR':
          case 'NI':
          case 'NG':
          case 'PE':
          case 'PH':
          case 'KN':
          case 'SR':
          case 'TR':
          case 'TV':
          case 'UY':
          case 'VE':
            values = data.sub_keys.split('~');
            titles = [...values];
            break;

          case 'AM':
          case 'EG':
          case 'CN':
          case 'IR':
          case 'JP':
          case 'KP':
          case 'KR':
          case 'RU':
          case 'TW':
          case 'TH':
          case 'UA':
          case 'AE':
          case 'VN':
            values = data.sub_lnames.split('~');
            titles = [...values];
            break;

          default:
            values = data.sub_keys.split('~');
            titles = data.sub_names.split('~');
        }

        for (let i = 0; i < values.length; i++) {
          states.push({ title: titles[i], value: values[i] });
        }

        if (data['state_name_type'] !== undefined) {
          statesLabel = `${data.state_name_type.charAt(0).toUpperCase()}${data.state_name_type.slice(1)}`;
        }
      }

      // Does country have postcode/zip?
      if (data['zip'] !== undefined) {
        hasPostcode = true;
        postcodeRegex = data.zip;
        postcodeRequired = hasPostcode && MAYBE_POSTCODE.indexOf(data.key) === -1 ? true : false;

        if (ZIP.includes(data.key)) {
          postcodeLabel = 'Zip code';
        }
      }

      // Name for sub locality
      if (data['sublocality_name_type'] !== undefined) {
        subLocalityLabel = `${data.sublocality_name_type.charAt(0).toUpperCase()}${data.sublocality_name_type.slice(
          1
        )}`;
      }

      licenceDeliveryAddressCountry = {
        ...licenceDeliveryAddressCountry,
        display: data.name,
        error: false,
        valid: true,
        value: data.key
      };

      // $FlowFixMe
      licenceDeliveryAddressCountry = validateTextField(licenceDeliveryAddressCountry);

      licenceDeliveryAddressLocality = {
        ...licenceDeliveryAddressLocality,
        label: subLocalityLabel
      };

      licenceDelivery = data.key === 'AU' ? 'Australian' : 'International';

      // $FlowFixMe
      licenceDeliveryAddressLocality = validateTextField(licenceDeliveryAddressLocality);

      licenceDeliveryAddressState = {
        ...licenceDeliveryAddressState,
        label: statesLabel,
        required: hasStates,
        states: states
      };

      // $FlowFixMe
      licenceDeliveryAddressState = validateTextField(licenceDeliveryAddressState);

      licenceDeliveryAddressPostcode = {
        ...licenceDeliveryAddressPostcode,
        label: postcodeLabel,
        regex: postcodeRegex,
        required: postcodeRequired
      };

      // $FlowFixMe
      licenceDeliveryAddressPostcode = validatePostcode(licenceDeliveryAddressPostcode);

      return {
        ...newState,
        licenceDelivery,
        licenceDeliveryAddressCountry,
        licenceDeliveryAddressLocality,
        licenceDeliveryAddressState,
        licenceDeliveryAddressPostcode
      };
    }

    case DELIVERY_ADDRESS: {
      let newState = reduceTextField(state, 'licenceDeliveryAddress', action);
      return newState;
    }

    case DELIVERY_ADDRESS_COUNTRY: {
      let newState = reduceCountry(state, 'licenceDeliveryAddressCountry', action);
      newState = setLicenceDeliveryAddress(newState);
      return newState;
    }

    case DELIVERY_ADDRESS_COUNTRY_ERROR: {
      console.log(state.licenceDeliveryAddressCountry);
      state.licenceDeliveryAddressCountry.error = true;
      state.licenceDeliveryAddressCountry.valid = false;
      return state;
    }

  

    case DELIVERY_ADDRESS_LOCALITY: {
      let newState = reduceTextField(state, 'licenceDeliveryAddressLocality', action);
      newState = setLicenceDeliveryAddress(newState);
      return newState;
    }

    case DELIVERY_ADDRESS_STATE: {
      let newState = reduceTextField(state, 'licenceDeliveryAddressState', action);
      newState = setLicenceDeliveryAddress(newState);
      return newState;
    }

    case DELIVERY_ADDRESS_STREET: {
      let newState = reduceTextField(state, 'licenceDeliveryAddressStreet', action);
      newState = setLicenceDeliveryAddress(newState);
      return newState;
    }

    case DELIVERY_ADDRESS_POSTCODE: {
      let newState = reducePostcode(state, 'licenceDeliveryAddressPostcode', action);
      newState = setLicenceDeliveryAddress(newState);
      return newState;
    }

    case LICENCE_ADDRESS_LOCALITY: {
      let newState = reduceTextField(state, 'licenceAddressLocality', action);
      newState = setLicenceAddress(newState);
      return newState;
    }

    case LICENCE_ADDRESS_STATE: {
      let newState = reduceTextField(state, 'licenceAddressState', action);
      newState = setLicenceAddress(newState);
      return newState;
    }

    case LICENCE_ADDRESS_STREET: {
      let newState = reduceTextField(state, 'licenceAddressStreet', action);
      newState = setLicenceAddress(newState);
      return newState;
    }

    case LICENCE_ADDRESS_POSTCODE: {
      let newState = reducePostcode(state, 'licenceAddressPostcode', action);
      newState = setLicenceAddress(newState);
      return newState;
    }

    case LICENCE_ADDRESS: {
      const { licenceAddress } = state;
      const { value } = action;

      return {
        ...state,
        licenceAddress: {
          ...licenceAddress,
          value
        }
      };
    }

    case DELIVERY_INT: {
      let { licenceDeliveryAddressCountry, addressDeliveryFieldsToggle } = state;
      const { value } = action;

      if (value === 'Australian') {
        addressDeliveryFieldsToggle = false;
        licenceDeliveryAddressCountry = {
          ...licenceDeliveryAddressCountry,
          display: 'Australia',
          value: 'AU'
        };
      } else {
        addressDeliveryFieldsToggle = true;
      }

      return {
        ...clearDeliveryAddress(state),
        addressDeliveryFieldsToggle,
        licenceDelivery: value,
        licenceDeliveryAddressCountry
      };
    }

    case VALIDATE_ADDRESS: {
      const value = action.value ? action.value : '';
      return validateAddress(state, value);
    }

    case VALIDATE: {
      let newState = { ...state };
      newState = validateAddress(newState, 'licenceAddress');
      newState = validateAddress(newState, 'licenceDeliveryAddress');
      return newState;
    }

    default:
      return state;
  }
}

/**
 * Clear address
 */
function clearAddress(state: Addresses) {
  const {
    licenceAddress,
    licenceAddressStreet,
    licenceAddressLocality,
    licenceAddressState,
    licenceAddressCountry,
    licenceAddressPostcode
  } = initAddresses;

  return {
    ...state,
    licenceAddress,
    licenceAddressStreet,
    licenceAddressLocality,
    licenceAddressState,
    licenceAddressCountry,
    licenceAddressPostcode
  };
}

/**
 * Clear delivery address
 * Leaves delivery country intact
 */
export function clearDeliveryAddress(state: Addresses) {
  const newState = { ...state };
  const clearState = { ...initAddresses };
  const { licenceDelivery, licenceDeliveryAddressCountry } = newState;

  const {
    licenceDeliveryAddress,
    licenceDeliveryAddressStreet,
    licenceDeliveryAddressLocality,
    licenceDeliveryAddressState,
    licenceDeliveryAddressPostcode
  } = clearState;

  return {
    ...newState,
    licenceDelivery,
    licenceDeliveryAddress,
    licenceDeliveryAddressCountry,
    licenceDeliveryAddressStreet,
    licenceDeliveryAddressLocality,
    licenceDeliveryAddressState,
    licenceDeliveryAddressPostcode
  };
}

/**
 * reduce a text field
 */
export function reduceTextField(state: Addresses, field: string, action: ActionType) {
  return {
    ...state,
    [field]: {
      ...validateTextField({ ...state[field], value: action.value })
    }
  };
}

/**
 * reduce a country
 */
export function reduceCountry(state: Addresses, field: string, action: ActionType) {
  let display = '';

  for (let i = 0; i < COUNTRIES.length; i++) {
    const { title, value } = COUNTRIES[i];
    if (value === action.value) {
      display = title;
    }
  }

  return {
    ...state,
    [field]: {
      ...validateTextField({ ...state[field], value: action.value }),
      display
    }
  };
}


/**
 * validate text field
 */
export function validateTextField(field: Field) {
  const { required, value } = field;
  const empty = value === '' && required ? true : false;
  const long = value.length > FIELD_LENGTH ? true : false;
  const valid = empty || long ? false : true;

  return {
    ...field,
    error: !valid,
    valid,
    value
  };
}

/**
 * reduce a postcode
 */
function reducePostcode(state: Addresses, field: string, action: ActionType) {
  return {
    ...state,
    [field]: {
      ...validatePostcode({ ...state[field], value: action.value })
    }
  };
}

/**
 * validate postcode
 */
export function validatePostcode(field: Field) {
  const { regex, required, value } = field;
  const test = regex ? new RegExp(regex) : null;
  const error = value && required && test && typeof value === 'string' ? !test.test(value) : !required ? false : true;

  return {
    ...field,
    error,
    valid: !error,
    value
  };
}

/**
 * validate addresses
 */
function validateAddress(state: Addresses, name: string) {
  const fields = [name, `${name}Street`, `${name}Locality`, `${name}State`, `${name}Country`];

  for (let i = 0; i < fields.length; i++) {
    const field = fields[i];
    const action = {
      type: VALIDATE,
      value: state[field].value
    };

    state = reduceTextField(state, field, action);

    if (state[field].error) {
      state[name].error = true;
    }
  }

  const field = `${name}Postcode`;
  const action = {
    type: VALIDATE,
    value: state[field].value
  };

  state = reducePostcode(state, field, action);

  if (state[field].error) {
    state[name].error = true;
  }

  return state;
}

export function formatStreet(
  number: string = '',
  street: string = '',
  sublocality: string = '',
  country: string = 'AU',
  licenceDeliveryAddressStreet?: Field
) {
  const noSub = ['CO', 'CZ', 'DE', 'DK'];
  const numberAfter = NUMBER_AFTER.indexOf(country) > -1 ? true : false;
  const subLSep = country === 'BR' ? ' - ' : country === 'CO' ? ', ' : ' ';
  const numbSep = country === 'BR' ? ', ' : country === 'CO' ? ' #' : ' ';
  const hasNum = number === '' ? false : true;
  const hasSt = street === '' ? false : true;
  const hasSub = sublocality === '' || noSub.indexOf(country) > -1 ? false : true;
  let value = '';

  switch (true) {
    case hasNum && hasSt && hasSub && numberAfter:
      value = `${street}${numbSep}${number}${subLSep}${sublocality}`;
      break;

    case hasNum && hasSt && hasSub && !numberAfter:
      value = `${number} ${street}, ${sublocality}`;
      break;

    case hasNum && hasSt && !hasSub && numberAfter:
      value = `${street}${numbSep}${number}`;
      break;

    case !hasNum && hasSt && hasSub:
      value = `${street}, ${sublocality}`;
      break;

    case !hasNum && hasSt && !hasSub:
      value = street;
      break;

    default:
      value = numberAfter ? `${street} ${number}` : `${number} ${street}`;
      break;
  }

  // $FlowFixMe
  return validateTextField({
    ...licenceDeliveryAddressStreet,
    value: value.trim()
  });
}

/**
 * Set Licence Address manually
 */
function setLicenceAddress(state: Addresses) {
  let {
    addressUseSameToggle,
    licenceAddress,
    licenceAddressLocality,
    licenceAddressState,
    licenceAddressStreet,
    licenceAddressPostcode
  } = state;
  let address = new PostalAddress();
  address
    .setAddress1(licenceAddressStreet?.valid ? licenceAddressStreet?.value : '')
    .setCity(licenceAddressLocality?.valid ? licenceAddressLocality?.value : '')
    .setCountry('AU')
    .setPostalCode(licenceAddressPostcode?.valid ? licenceAddressPostcode?.value : '')
    .setState(licenceAddressState?.valid ? licenceAddressState?.value : '')
    .setFormat({
      country: 'AU',
      type: 'personal'
    });
  const output = address.output();
  const value = output && output.length > 1 ? `${output[0][0]}, ${output[1].join(' ')}` : '';
  const { required } = licenceAddress ? licenceAddress : { required: true };
  const error = required && !value ? true : false;

  licenceAddress = {
    ...licenceAddress,
    error,
    valid: !error,
    value
  };

  //clone the updates to the delivery address
  if (addressUseSameToggle) {
    cloneLicenceAddress(state);
  }

  return {
    ...state,
    licenceAddress
  };
}

/**
 * Set Delivery Address manually
 */
function setLicenceDeliveryAddress(state: Addresses) {
  let {
    licenceDeliveryAddress,
    licenceDeliveryAddressStreet,
    licenceDeliveryAddressLocality,
    licenceDeliveryAddressPostcode,
    licenceDeliveryAddressState,
    licenceDeliveryAddressCountry
  } = state;

  // if we don't have a street address, don't go any further
  if (!licenceDeliveryAddressStreet?.value) {
    return state;
  }

  const country = licenceDeliveryAddressCountry?.value ? licenceDeliveryAddressCountry?.value : 'AU';
  let address = new PostalAddress();

  address
    .setAddress1(licenceDeliveryAddressStreet?.valid ? licenceDeliveryAddressStreet?.value : '')
    .setCity(licenceDeliveryAddressLocality?.valid ? licenceDeliveryAddressLocality?.value : '')
    .setCountry(licenceDeliveryAddressCountry?.valid ? licenceDeliveryAddressCountry?.display : '')
    .setPostalCode(licenceDeliveryAddressPostcode?.valid ? licenceDeliveryAddressPostcode?.value : '')
    .setState(licenceDeliveryAddressState?.valid ? licenceDeliveryAddressState?.value : '')
    .setFormat({
      country: licenceDeliveryAddressCountry?.valid ? licenceDeliveryAddressCountry?.value : '',
      type: 'personal'
    });

  const output = address.output();
  const value =
    output && output.length > 1
      ? `${output[0][0]}, ${output[1].join(' ')}${
          country !== 'AU' && output[2] && output[2].length > 0 ? ` ${output[2][0]}` : ''
        }`
      : '';
  const { required } = licenceDeliveryAddress ? licenceDeliveryAddress : { required: true };
  const error = required && !value ? true : false;

  licenceDeliveryAddress = {
    ...licenceDeliveryAddress,
    error,
    valid: !error,
    value
  };

  return {
    ...state,
    licenceDeliveryAddress
  };
}

/**
 * Clone Delivery Address from the Licence Address
 */
function cloneLicenceAddress(state: Addresses) {
  const newState = { ...state };
  let {
    licenceAddress,
    licenceAddressLocality,
    licenceAddressState,
    licenceAddressStreet,
    licenceAddressPostcode,
    licenceAddressCountry,
    licenceDeliveryAddress,
    licenceDeliveryAddressStreet,
    licenceDeliveryAddressPostcode,
    licenceDeliveryAddressLocality,
    licenceDeliveryAddressState,
    licenceDeliveryAddressCountry
  } = state;

  licenceDeliveryAddress = {
    ...licenceAddress
  };

  licenceDeliveryAddressStreet = {
    ...licenceAddressStreet
  };

  licenceDeliveryAddressPostcode = {
    ...licenceAddressPostcode
  };

  licenceDeliveryAddressLocality = {
    ...licenceAddressLocality
  };

  licenceDeliveryAddressState = {
    ...licenceAddressState
  };

  licenceDeliveryAddressCountry = {
    ...licenceAddressCountry
  };
  console.log({
    ...newState,
    licenceDeliveryAddress,
    licenceDeliveryAddressStreet,
    licenceDeliveryAddressLocality,
    licenceDeliveryAddressPostcode,
    licenceDeliveryAddressState,
    licenceDeliveryAddressCountry
  });
  return {
    ...newState,
    licenceDeliveryAddress,
    licenceDeliveryAddressStreet,
    licenceDeliveryAddressLocality,
    licenceDeliveryAddressPostcode,
    licenceDeliveryAddressState,
    licenceDeliveryAddressCountry
  };
}
