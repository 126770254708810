/**
 * AAA IDP homepage
 * @flow
 */
import React, { useRef, type Node } from 'react';
import Banner from '../../components/banner/Banner';
import WhatIs from '../../components/what-is/WhatIs';
import HowTo from '../../components/how-to/HowTo';
import Need from '../../components/need/Need';
import Faq from '../../components/faq/Faq';
import Cta from '../../components/cta/Cta';
import Contact from '../../components/contact/Contact';
import HomeForm from './home-form/HomeForm';
import { SITE_CONTENT } from '../../reducers/content/Site.content';
import type { Content } from '../../types/Types';
import type { Bugsnag } from '@bugsnag/js';
import './Home.css';

type Props = {
  bugsnagClient: Bugsnag,
  content: Content,
  jest: boolean,
  setContent: (content: Content) => *
};

const Home = (props: Props): Node => {
  const { bugsnagClient, content } = props;
  const { banner, form, contact, need, info, howTo, what, faq, cta } = content;
  const formRef = useRef();
  const pageRef = useRef();

  /**
   * Scroll the start application form into view
   */
  const startApp = (e: SyntheticMouseEvent<HTMLAnchorElement>, origin: 'banner' | 'cta' = 'banner') => {
    e.preventDefault();

    if (!pageRef || !pageRef.current || !formRef || !formRef.current) {
      return;
    }

    const page = pageRef.current.getBoundingClientRect();
    const dim = formRef.current.getBoundingClientRect();
    const top = origin === 'cta' ? Math.abs(Math.floor(page.top - dim.top)) + 96 : dim.top;

    window.scrollTo({
      top,
      behavior: 'smooth'
    });
  }

  return (
    <div className="Home" ref={pageRef}>
      <Banner {...banner} startApp={startApp} />
      <section className="content-container" role="main">
        <h2 className="what">{what.title}</h2>
        <WhatIs {...what} />
        <h2>{howTo.title}</h2>
        <HowTo {...howTo} />
        <HomeForm {...form} bugsnagClient={bugsnagClient} formRef={formRef} />
        <h2 className="things-you-need">{need.title}</h2>
        <Need {...need} />
        <h2 className="faq">{faq.mainTitle}</h2>
        <div className="faq-container">
          <div className="faqs">
            {faq.items.map((item, index) => {
              return <Faq {...item} key={index} />;
            })}
          </div>
          <div className="faq-post-copy" dangerouslySetInnerHTML={{ __html: faq.postCopy }} />
          <Cta {...cta} startApp={startApp} />
        </div>
      </section>
      <div className="Empowered">
        <div className="inner" dangerouslySetInnerHTML={{ __html: info }} />
      </div>
      <Contact {...contact} />
    </div>
  );
};

Home.defaultProps = {
  content: { ...SITE_CONTENT },
  jest: false
};

export default Home;
