/**
 * AAA IDP terms reducer
 * Set the acceptance of terms for the user on the homepage
 * @flow
 */

import { ACCEPT_TERMS, DELETE } from '../../actions/types';
import type {ActionType} from '../../types/Types';

export type Terms = {
  error: boolean,
  required: boolean,
  valid: null | boolean,
  value: boolean,
};

const initialState = {
  error: false,
  required: true,
  valid:  null ,
  value: false,
};

export default function(state: Terms = initialState, action: ActionType) {
  switch (action.type) {
    case ACCEPT_TERMS: {
      const { required } = state; 
      const {value} = action;
      const valid = !value && required ? false : true;

      const acceptTerms = {
        error: !valid,
        required,
        valid,
        value
      }

      return acceptTerms;
    }

    case DELETE:
      return {
        error: false,
        required: true,
        valid:  null ,
        value: false,
      }

    default:
      return state;
  }
}
